import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { SnackBarService } from "../services/snack-bar.service";
import Cookie from 'js-cookie';

export default () : boolean =>{
    const snackBar = inject(SnackBarService);
    // const router = inject(Router);
    const adminIdCookie = Cookie.get("is_admin");
    if (adminIdCookie) {
      // An admin is connect on the same session
      // router.navigate(["/"]); // redirect to home page
      snackBar.open("You don't have access to this section !");
      return false;
    } else {
      // An admin is not connected on the same session
      return true
    }
}