import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core'
import { DoctorService } from 'src/app/services/doctor.service'
import { Doctor } from 'src/app/models/doctor'
import { SnackBarService } from 'src/app/services/snack-bar.service'
import { ErrorsHandler } from 'src/app/services/error-handler.service'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { BookingSlotAvailability } from 'src/app/models/booking-slot-availabilities'
import { GlobalService } from 'src/app/services/global.service'
import { DepartmentsService } from 'src/app/services/departments.service'
import { Department } from 'src/app/models/department'
import { ClinicResource } from 'src/app/models/clinic_resource'
import { ClinicResourceService } from 'src/app/services/clinic-resources.service'

@Component({
  templateUrl: './availabilities-edit.component.html',
  styleUrls: ['./availabilities-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvailabilitiesEditComponent implements OnInit {
  spinners = false
  activePlanner: boolean = true
  currentDoctor: Doctor
  booking_slot_availabilities: BookingSlotAvailability[] = []
  weekTimeSlots: Array<string> = []
  days = ['monday', 'tuesday', 'wendnesday', 'thursday', 'friday', 'saturday', 'sunday']
  dayVideoEnabled = [false, false, false, false, false, false, false] // array to store toggle state of each day
  currentSelectedClinicRessourceId: null | string = null
  displayClinicResource: null | ClinicResource = null

  checked: number = 0;
  isValid: boolean = false;
  validTimeSlots: Array<string> = [];
  objectIndex: number = 0;
  isPreviousSlot: boolean;
  availabilitiesMax: number = 3;
  constructor(
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private router: Router,
    private errorHandler: ErrorsHandler,
    private globalService: GlobalService,
    private doctorService: DoctorService,
    private departmentsService: DepartmentsService,
    private clinicResourcesService: ClinicResourceService,
  ) {}

  toggleSpinners() {
    this.spinners = !this.spinners
  }

  ngOnInit() {
    this.getDoctorInfo()
  }

  onClinicResourceChange() {
    this.loadSelectedClinicResource()
  }

  loadSelectedClinicResource() {
    this.clinicResourcesService.getClinicResource(this.currentSelectedClinicRessourceId)
      .subscribe((clinic_resource: ClinicResource) => {
        this.displayClinicResource = clinic_resource
        this.loadAvailabilitiesCalendar()
      })
  }

  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      return false
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }
    return true
  }

  validateInputs(index, event, inputType, day) {
    let isValidTime = true
    const weekDay = index + 1
    let isValidInput = false
    let isLastSlot = false
    let previousSlotStartsTime, previousSlotEndsTime
    previousSlotStartsTime = previousSlotEndsTime = ''
    var availabilities = this.booking_slot_availabilities.filter(function (availability) {
      return availability.week_day == day
    })

    availabilities.forEach((patientLabel, i) => {
      if (this.shallowEqual(patientLabel, this.booking_slot_availabilities[index])) {
        this.objectIndex = i
      }
    })
    if (this.booking_slot_availabilities[index + 1] == undefined) {
      isLastSlot = true
    } else {
      isLastSlot = true
      if (availabilities[this.objectIndex + 1] && !availabilities[this.objectIndex + 1]._destroy) {
        isLastSlot = false
      }
    }

    availabilities.forEach((patientLabel, i) => {
      if (this.shallowEqual(patientLabel, this.booking_slot_availabilities[index])) {
        this.objectIndex = i
      }
    })
    if (this.weekTimeSlots[index + '_starts_at_hours_minutes']) {
      previousSlotStartsTime =
        this.weekTimeSlots[index + '_starts_at_hours_minutes'].hour +
        ':' +
        this.weekTimeSlots[index + '_starts_at_hours_minutes'].minute
      previousSlotEndsTime =
        this.weekTimeSlots[index + '_ends_at_hours_minutes'].hour +
        ':' +
        this.weekTimeSlots[index + '_ends_at_hours_minutes'].minute
    }
    const currentSlotStartsTime =
      this.weekTimeSlots[weekDay + '_starts_at_hours_minutes'].hour +
      ':' +
      this.weekTimeSlots[weekDay + '_starts_at_hours_minutes'].minute
    const currentSlotEndsTime =
      this.weekTimeSlots[weekDay + '_ends_at_hours_minutes'].hour +
      ':' +
      this.weekTimeSlots[weekDay + '_ends_at_hours_minutes'].minute
    this.booking_slot_availabilities[index].starts_at_hours_minutes = currentSlotStartsTime
    this.booking_slot_availabilities[index].ends_at_hours_minutes = currentSlotEndsTime
    let count = 0
    availabilities.forEach((patientLabel, i) => {
      if (patientLabel.starts_at_hours_minutes == currentSlotStartsTime) {
        count++
      }
    })
    if (isLastSlot) {
      isValidInput =
        event === '0' ||
        event === '' ||
        !(event.length == 2) ||
        currentSlotStartsTime.split(':')[1].length > 2
      this.isPreviousSlot = false
      if (!/^[a-zA-Z]+$/.test(event)) {
        if (isValidInput) this.validTimeSlots[index + '_' + inputType] = true
        else {
          this.validTimeSlots[index + '_' + inputType] = event != '00'
          if (
            this.setHours(currentSlotStartsTime, currentSlotEndsTime) ||
            currentSlotEndsTime <= currentSlotStartsTime ||
            count > 1
          ) {
            this.validTimeSlots[index + '_' + inputType] = true
            isValidTime = false
          } else {
            this.validTimeSlots[index + '_' + inputType] = false
            isValidTime = true
          }
          if (isValidTime) this.checkHoursMinutes(index, inputType, event)
        }
      }
    } else {
      const nextSlotStartsTime = availabilities[this.objectIndex + 1].starts_at_hours_minutes
      isValidInput = event === '0' || event === '' || !(event.length == 2)
      if (!/^[a-zA-Z]+$/.test(event)) {
        if (isValidInput) this.validTimeSlots[index + '_' + inputType] = true
        else {
          // Check start and end time
          if (this.objectIndex == 0) {
            if (event == '00') {
              this.validTimeSlots[index + '_' + inputType] = false
              isValidTime = true
            }
            if (currentSlotEndsTime <= currentSlotStartsTime || count > 1) {
              this.validTimeSlots[index + '_' + inputType] = true
              isValidTime = false
            }
          } else {
            if (
              previousSlotStartsTime >= currentSlotStartsTime ||
              previousSlotEndsTime > currentSlotStartsTime ||
              currentSlotEndsTime <= currentSlotStartsTime
            ) {
              this.validTimeSlots[index + '_' + inputType] = true
              isValidTime = false
            }
          }
          if (nextSlotStartsTime < currentSlotEndsTime) {
            this.validTimeSlots[index + '_' + inputType] = true
            isValidTime = false
          }
          // Check hours and minutes
          if (isValidTime) this.checkHoursMinutes(index, inputType, event)
        }
      }
    }

    if (
      !isValidInput &&
      !this.validTimeSlots[index + '_' + inputType] &&
      !this.setHours(currentSlotStartsTime, currentSlotEndsTime)
    ) {
      this.validTimeSlots[index + '_hours'] = false
      this.validTimeSlots[index + '_minutes'] = false
    }
    this.checkTimeSlots(inputType)
  }

  setHours(currentSlotStartsTime, currentSlotEndsTime) {
    // set current slot starts hours
    let startsHoursDate = new Date()
    let startsHour = parseInt(currentSlotStartsTime.split(':')[0])
    let startsMinute = parseInt(currentSlotStartsTime.split(':')[1])
    let startsTime = startsHoursDate.setHours(startsHour, startsMinute, 0)

    // set current slot ends hours
    let endsHoursDate = new Date()
    let endsHour = parseInt(currentSlotEndsTime.split(':')[0])
    let endsMinute = parseInt(currentSlotEndsTime.split(':')[1])
    let endsTime = endsHoursDate.setHours(endsHour, endsMinute, 0)
    return startsTime >= endsTime
  }

  checkHoursMinutes(index, inputType, event) {
    if (inputType == 'hours' && event <= 23) {
      this.validTimeSlots[index + '_' + inputType] = false
    } else if (inputType == 'hours' && event > 23) {
      this.validTimeSlots[index + '_' + inputType] = true
    }
    if (inputType == 'minutes' && event <= 59) {
      this.validTimeSlots[index + '_' + inputType] = false
    } else if (inputType == 'minutes' && event > 59) {
      this.validTimeSlots[index + '_' + inputType] = true
    }
  }

  checkTimeSlots(inputType) {
    this.weekTimeSlots.every((week_day, index) => {
      this.isValid = this.validTimeSlots[index + '_' + inputType]
      return !this.isValid
    })
  }

  activateVideo(dayIndex) {
    this.booking_slot_availabilities.forEach((patientLabel, index) => {
      if (patientLabel.week_day === dayIndex + 1) {
        patientLabel.type = this.dayVideoEnabled[dayIndex] ? 1 : 0
      }
    })

    this.booking_slot_availabilities.forEach((patientLabel, index) => {
      if (patientLabel.week_day === dayIndex + 1) {
        patientLabel.type = this.dayVideoEnabled[dayIndex] ? 1 : 0
      }
    })
  }

  initForm() {
    this.booking_slot_availabilities.forEach((patientLabel, index) => {
      let dayIndex = index + 1
      if (this.weekTimeSlots[dayIndex + '_show'] != 'false') {
        this.weekTimeSlots[dayIndex + '_show'] = 'true'
      }
      this.weekTimeSlots.push(dayIndex + '_starts_at_hours_minutes')
      this.weekTimeSlots.push(dayIndex + '_ends_at_hours_minutes')
      var startHour = patientLabel.starts_at_hours_minutes.split(':')[0]
      var startMinute = patientLabel.starts_at_hours_minutes.split(':')[1]
      var endHour = patientLabel.ends_at_hours_minutes.split(':')[0]
      var endMinute = patientLabel.ends_at_hours_minutes.split(':')[1]
      this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'] = {
        hour: startHour,
        minute: startMinute,
      }
      this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'] = {
        hour: endHour,
        minute: endMinute,
      }
    })

    this.dayVideoEnabled = new Array(7).fill(false)
    for (let i = 0; i < 7; i++) {
      let videoEnabled = true
      this.booking_slot_availabilities.forEach((slot) => {
        if (Number(slot.week_day) === i + 1 && slot.type === 0) {
          videoEnabled = false
        }
      })
      if (videoEnabled) {
        this.dayVideoEnabled[i] = true
      }
    }
  }

  checkValue(event: any, index) {
    if (event.currentTarget.checked) {
      this.booking_slot_availabilities[index].type = 1
    } else {
      this.booking_slot_availabilities[index].type = 0
    }
  }

  deleteSlotAvailability(index_number) {
    if (this.booking_slot_availabilities[index_number])
      this.booking_slot_availabilities[index_number]._destroy = true
    this.weekTimeSlots[index_number + 1 + '_show'] = 'false'
    this.validTimeSlots[index_number + '_hours'] = false
    this.validTimeSlots[index_number + '_minutes'] = false
    this.checkTimeSlots('hours')
    this.checkTimeSlots('minutes')
  }

  addSlotAvailability(week_day) {
    if(!this.currentSelectedClinicRessourceId) return;
    let lastSlot = this.booking_slot_availabilities.filter((slot) => slot.week_day === week_day).pop()
    let startsAt = lastSlot ? lastSlot.ends_at_hours_minutes : '00:00'
    let endsAt = this.addMinutes(startsAt, this.currentDoctor.average_booking_duration)
    let booking_hash = {
      week_day: week_day,
      starts_at_hours_minutes: startsAt,
      ends_at_hours_minutes: endsAt,
      _destroy: false,
      type: 1,
      clinic_resource_id: this.currentSelectedClinicRessourceId
    }
    this.booking_slot_availabilities.push(booking_hash)

    this.checkTimeSlots('hours')
    this.checkTimeSlots('minutes')
    this.initForm()
  }

  addMinutes(time, minutes) {
    let parts = time.split(':')
    let hours = +parts[0]
    let mins = +parts[1] + minutes
    if (mins >= 60) {
      hours++
      mins -= 60
    }
    return `${hours < 10 ? '0' + hours : hours}:${mins < 10 ? '0' + mins : mins}`
  }

  loadDepartments() {
    this.departmentsService.getDepartments().subscribe((departments: Department[]) => {
      this.currentDoctor.departments = departments
      this.clinicResourcesService.getClinicResources().subscribe((clinic_resources: ClinicResource[]) => {
        this.currentDoctor.departments.forEach((department: Department) => {
          clinic_resources.forEach(clinic_resource => {
            if(department.clinic_resource_ids.includes(clinic_resource._id)){
              if(!department.clinic_resources) {department.clinic_resources = []}
              department.clinic_resources.push(clinic_resource)
            }
          })
        })
      })
    })
  }

  loadAvailabilitiesCalendar(){
    if(!this.currentDoctor) return;

    let booking_slot_availabilities_payload = null

    // if Clinic load resource calendar else load doctor calendar
    if (this.currentDoctor.clinic) {
      if (this.displayClinicResource) {
        booking_slot_availabilities_payload = this.displayClinicResource.booking_slot_availabilities
      }
    } else {
      booking_slot_availabilities_payload = this.currentDoctor.booking_slot_availabilities
    }

    if(booking_slot_availabilities_payload){
      // Sort Dates
      let start_dates = booking_slot_availabilities_payload.sort((a, b) =>
        a.starts_at_hours_minutes.localeCompare(b.starts_at_hours_minutes)
      )
      booking_slot_availabilities_payload = start_dates.sort((a, b) =>
        a.week_day < b.week_day ? -1 : a.week_day > b.week_day ? 1 : 0
      )
  
      // Clear Slot Availabilities and then fill it
      this.booking_slot_availabilities = []
      booking_slot_availabilities_payload.forEach((availabilitySlot, index) => {
        availabilitySlot.clinic_resource_id = this.currentSelectedClinicRessourceId
        availabilitySlot._destroy = false;
        this.booking_slot_availabilities.push(availabilitySlot);
      })
  
      this.initForm()
    }
  }

  getDoctorInfo() {
    this.globalService.showSpinner()
    this.booking_slot_availabilities = []
    this.doctorService.get().subscribe((currentDoctor) => {
      if (!currentDoctor) return

      this.currentDoctor = currentDoctor

      if(this.currentDoctor.clinic){
        this.loadDepartments()
      }

      this.loadAvailabilitiesCalendar();

      this.globalService.hideSpinner()
    })
  }

  saveBookingSlotAvailabilities() {
    let numberRegex = /^\d+$/
    this.booking_slot_availabilities.every((patientLabel, index) => {
      let dayIndex = index + 1
      if (
        numberRegex.test(this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].hour) &&
        numberRegex.test(this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].minute) &&
        numberRegex.test(this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].hour) &&
        numberRegex.test(this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].minute)
      ) {
        this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].hour =
          this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].hour
        this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].minute =
          this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].minute
        this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].hour =
          this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].hour
        this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].minute =
          this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].minute
        this.booking_slot_availabilities[index].starts_at_hours_minutes =
          this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].hour +
          ':' +
          this.weekTimeSlots[dayIndex + '_starts_at_hours_minutes'].minute
        this.booking_slot_availabilities[index].ends_at_hours_minutes =
          this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].hour +
          ':' +
          this.weekTimeSlots[dayIndex + '_ends_at_hours_minutes'].minute
        return true
      }
    }, this)
    if (!this.isValid) {
      this.doctorService
        .update_slot_availabilities(
          this.booking_slot_availabilities,
          this.currentSelectedClinicRessourceId
        )
        .subscribe(
          (data) => {
            this.getDoctorInfo()
            this.snackBar.open(
              this.translate.instant('connect.booking_slot_availabilities.booking_slot_added')
            )
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant('connect.globals.error_occured_message')
            )
        )
    } else {
      this.errorHandler.handleError(
        this.translate.instant('connect.booking_slot_availabilities.fix_invalid_slot_fields')
      )
    }
  }
  navigatePlanner() {
    this.activePlanner = !this.activePlanner
    return this.router.navigate(['/booking-availabilities/new-planner'])
  }

  navigateAgenda() {
    return this.router.navigate(['/calendar'])
  }
}
