<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.sms.sms_sent" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <div class="row mt-4">
          <div class="col-md-12">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="mat-elevation-z1"
              class="sms-table responsive-table"
            >
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span>{{ "connect.sms.date" | translate }}</span>
                </th>
                <td mat-cell *matCellDef="let sms">
                  <span class="date-span">{{
                    sms.created_at | date : "dd-MM-yyyy"
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="sms_contents">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span>{{ "connect.sms.sms_contents" | translate }}</span>
                </th>
                <td mat-cell *matCellDef="let sms">
                  <span>{{ sms.message }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="phone_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span>{{ "connect.sms.phone_number" | translate }}</span>
                </th>
                <td mat-cell *matCellDef="let sms">
                  <span>{{ sms.phone_number }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="user">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="text-align-center"
                >
                  <span>{{ "connect.sms.user" | translate }}</span>
                </th>
                <td mat-cell *matCellDef="let sms">
                  <span *ngIf="sms.user">
                    <p>
                      <span
                        >{{ sms.user.first_name }}
                        {{ sms.user.last_name }}</span
                      >
                      <span class="clickable-text">{{ sms.user.email }}</span>
                    </p>
                  </span>
                  <span *ngIf="!sms.user">
                    <p *ngIf="sms.booking_slot">
                      <span
                        >{{ sms.booking_slot.user_first_name }}
                        {{ sms.booking_slot.user_last_name }}</span
                      >
                    </p>
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <h2
              class="no-filter-data"
              *ngIf="dataSource && dataSource.data.length === 0"
            >
              {{ "connect.sms.no_result" | translate }}
            </h2>
            <div class="text-right mt-3">
              <button
                mat-raised-button
                color="primary"
                class="ml-auto mr-1"
                [disabled]="previousDisable"
                (click)="paginatorSmsLogs('previous')"
              >
                {{ "connect.globals.previous_button_text" | translate }}
              </button>
              <span class="pagination-number">{{ listParams.pageNumber }}</span>
              <button
                mat-raised-button
                color="primary"
                class="ml-auto"
                [disabled]="nextDisable === true"
                (click)="paginatorSmsLogs('next')"
              >
                {{ "connect.globals.next_button_text" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
