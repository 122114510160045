<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template patient-labels-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="d-flex flex-row justify-content-between align-items-center w-100">
          <h3 class="kt-portlet__head-title card-title">
            {{ 'connect.patient_labels.patient_categories' | translate }}
          </h3>
          <button mat-flat-button color="primary" (click)="addPatientLabel()">{{ 'connect.patient_labels.add_patient_label' | translate }}</button>
        </div>
      </div>
      <div class="kt-portlet__body p-0">
        <table mat-table [dataSource]="patientLabels" class="appointment-table">
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>{{ 'connect.patient_labels.color' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="color-preview"
                [style.background]="element.color"
              ></div>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'connect.patient_labels.patient_label' | translate }}</th>
            <td class="center color-name" mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="cell-end">{{ 'connect.patient_labels.action_label' | translate }}  </th>
            <td mat-cell *matCellDef="let element" class="cell-end">
              <div class="action-buttons-row mx-4">
                <button class="edit-button" mat-flat-button (click)="editPatientLabel(element)">
                  <mat-icon class="edit-icon" >edit</mat-icon> 
                  {{ 'connect.patient_labels.edit_patient_label' | translate }}
                </button>
                <button class="delete-button" mat-flat-button (click)="deletePatientLabel(element)">
                  <mat-icon class="delete-icon" >delete</mat-icon>
                  {{ 'connect.patient_labels.delete_patient_label' | translate }}
                </button>
            </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['color','name', 'action']"></tr>
          <tr mat-row class="hover-row" *matRowDef="let row; columns: ['color','name', 'action'];"></tr>
        </table>
      </div>
    </div>
  </div>
</div>