import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Doctor } from "src/app/models/doctor";
import { DoctorService } from "src/app/services/doctor.service";
import { PlanType } from "src/app/enums/planType.enum";

@Component({
  selector: "app-aside",
  templateUrl: "./aside.component.html",
  styleUrls: ["./aside.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AsideComponent implements OnInit {
  currentDoctor: Doctor | undefined = undefined;
  isActive: boolean = false;
  currentIndex: any = "";
  event: boolean = false;
  toggleData: boolean = false;
  isMobileResolution: boolean;
  HaveAccess: boolean;
  hasConnectPlusAccess: boolean = false;

  constructor(private router: Router, private doctorService: DoctorService) {}

  toggleMenu(index) {
    this.currentIndex = index;
    if (this.currentIndex === index) {
      this.isActive = !this.isActive;
    }
  }

  connectPlusAsideMenuItems = {
    Items: [{ name: "templates" }],
  };

  medicalRecordMenuItem = {
    name: "patient_record",
    icon: "fa fa-list",
    subMenusItems: [
      { name: "my_files", link: "medical-records" },
      { name: "ordannnaces", link: "prescriptions" },
    ],
  };

  asideMenuItems: any[] = [
    {
      name: "planning",
      icon: "fas fa-tags",
      subMenusItems: [
        { name: "agenda", link: "calendar" },
        { name: "list_of_appointments", link: "appointments-list" },
        { name: "statistics", link: "statistics" },
        { name: "assistants", link: "assistants" },
        { name: "video_invoices", link: "invoices/video" },
      ],
    },
    {
      name: "messaging",
      icon: "fas fa-file-medical",
      subMenusItems: [
        { name: "inbox", link: "messages" },
        { name: "comments", link: "comments" },
        { name: "sms", link: "sms" },
        { name: "mass_sms", link: "mass-sms" },
        { name: "e-mail", link: "email" },
      ],
    },
    {
      name: "parameters",
      icon: "fa fa-cog",
      subMenusItems: [
        { name: "settings", link: "settings" },
        { name: "care_and_acts", link: "acts" },
        { name: "labels", link: "patient-labels" },
        { name: "templates", link: "templates" },
      ],
    },
  ];

  ngOnInit() {
    this.isMobileResolution = window.innerWidth < 768;
    this.doctorService.currentDoctor$.subscribe((doctor) => {
      this.currentDoctor = doctor;
      if (this.currentDoctor) {
        // Add the medicalRecord menu items only if the doctor has connect plus subscription
        if (this.currentDoctor.plan_type === PlanType.CONNECT_PLUS) {
          this.asideMenuItems.splice(1, 0, this.medicalRecordMenuItem);
        }
        if (this.currentDoctor) {
          if (this.currentDoctor.plan_type == PlanType.CONNECT_PLUS) {
            this.hasConnectPlusAccess = true;
          }
        }
        this.updateMenuForPaidCountries();
      }
    });
  }
  updateMenuForPaidCountries() {
    const parametersMenu = this.asideMenuItems.find(menu => menu.name === 'parameters');    
    if (parametersMenu && this.currentDoctor.in_paid_countries) {
      const invoiceMenuItem = { name: 'invoices', link: 'invoices' };
      parametersMenu.subMenusItems.push(invoiceMenuItem);
    }
  }
  navigateToLink(url: string) {
    this.isMobileResolution
      ? (window.location.href = "/" + url)
      : this.router.navigate(["/" + url]);
  }

  changeSidebarClass(event) {
    this.toggleData = event;
  }

  changeClass($event) {
    this.event = $event.type !== "mouseover";
  }

  showSection(submenu) {
    if(submenu.hideOnClinic && this.currentDoctor && this.currentDoctor.clinic){
      return false
    }
    return true
  }
}
