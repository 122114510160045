import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../components/dialog/dialog.component";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { PlanType } from "src/app/enums/planType.enum";

@Component({
  templateUrl: "./medical-records.component.html",
})
export class MedicalRecordsComponent {
  name: string;
  loadRecord: any;
  currentDoctor: Doctor;

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.loadRecord = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.name = params["name"];
    });
    this.getDoctorInfo();
  }

  openDialog() {
    // if (this.currentDoctor.plan_type == PlanType.CONNECT) {
    //   let dialogRef = this.dialog.open(DialogComponent, {})
    //   return dialogRef.afterClosed()
    // } else {
    //   Swal.fire(
    //     this.translate.instant('connect.medical_record.only_for_connect')
    //   )
    // }
    let dialogRef = this.dialog.open(DialogComponent, {});
    return dialogRef.afterClosed();
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      this.currentDoctor = currentDoctor;
    });
  }

  ngOnDestroy() {
    if (this.loadRecord) {
      this.loadRecord.unsubscribe();
    }
  }
}
