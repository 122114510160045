import { Injectable } from '@angular/core'
import { HttpWrapper } from './http-wrapper'

@Injectable({
  providedIn: 'root'
})

export class PartnerService {
  private PartnersUrl: string = '/partners';

  constructor(private http: HttpWrapper) {}

  partners()
  {
    return this.http.get(this.PartnersUrl);
  }

  updateDoctorPartners(partner_ids: string[], conditions: string)
  {
    return this.http.put(this.PartnersUrl + "/update", { partner_ids, conditions });
  }
}
