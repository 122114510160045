import {AbstractControl, FormControl} from "@angular/forms";

export function startsAtValidator(control: FormControl): { [key: string]: any } | null {

	const startsAt = control.value; // The value of the form control
	if (startsAt) {
		const today = new Date();
		if (startsAt.getDate() === today.getDate() && startsAt.getTime() <= today.getTime()) {
		return { startsAtInvalid: true }; // Validation failed
	  }
	}
	return null; // Validation passed
  }