<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.doctor_comments.comments" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <div class="card m-3" *ngIf="doctorComments.length > 0">
          <h2
            class="no-filter-data"
            *ngIf="doctorComments && doctorComments.length === 0"
          >
            {{ "connect.appointment_table.no_result" | translate }}
          </h2>
          <div *ngFor="let review of doctorComments" class="reviews-box">
            <div class="row comment-wrap">
              <app-doctor-details-comments
                [comments]="true"
                [currentDoctor]="currentDoctor"
                [review]="review"
                class="review-container"
              ></app-doctor-details-comments>
              <div
                class="col-11 mt-4"
                *ngIf="currentDoctor$ | async as currentDoctor"
              >
                <span *ngIf="review.doctor_comment">
                  <img
                    src="{{ currentDoctor.photo }}"
                    class="comment-avtar"
                  /><span class="comment"
                    ><span class="textbold"
                      >{{
                        "connect.doctor_comments.comments_from" | translate
                      }}
                      {{ currentDoctor.last_name }}
                      {{ currentDoctor.first_name }}</span
                    >
                  </span>
                  <div class="reply-commnet">{{ review.doctor_comment }}</div>
                </span>
              </div>
            </div>
          </div>
          <div class="text-right mt-2">
            <button
              mat-raised-button
              color="primary"
              class="ml-auto mr-1"
              [disabled]="previousDisable"
              (click)="paginatorCommentRecords('previous')"
            >
              {{ "connect.globals.previous_button_text" | translate }}
            </button>
            <span class="pagination-number">{{ listParams.pageNumber }}</span>
            <button
              mat-raised-button
              color="primary"
              class="ml-auto"
              [disabled]="nextDisable"
              (click)="paginatorCommentRecords('next')"
            >
              {{ "connect.globals.next_button_text" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
