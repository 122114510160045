import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { environment as appConfig } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  AUTH_URL = appConfig.auth_url;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      window.location.href = this.AUTH_URL;
      return false;
    }
    return true;
  }
}
