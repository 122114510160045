import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class AssistantsService {
  private AssistantsUrl: string = "/assistants";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.AssistantsUrl);
  }

  getEmail(email: string) {
    return this.http.get(`${this.AssistantsUrl}/${email}`);
  }

  create(assistantRecord: any) {
    return this.http.post(this.AssistantsUrl, assistantRecord);
  }

  delete(doctorAssistantId: any) {
    return this.http.delete(`${this.AssistantsUrl}/${doctorAssistantId}`);
  }
}
