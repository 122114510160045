import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "customTime",
})
export class CustomTimePipe extends DatePipe implements PipeTransform {
  transform(date: any): any {
    return super.transform(date, "HH:mm");
  }
}
