import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SnackBarService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  public open(msg: string): void {
    this.snackBar.open(msg, this.translate.instant("connect.globals.close"), {
      duration: 3000,
    });
  }
}
