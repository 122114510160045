import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import {
  trigger,
  transition,
  style,
  sequence,
  animate,
  state,
} from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { FormControl, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AttachmentSharingsService } from "src/app/services/attachment-sharings.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-attachment-sharings",
  templateUrl: "./attachment-sharings-list.component.html",
  styleUrls: ["./attachment-sharings-list.component.scss"],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("rowsAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(600)]),
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class AttachmentSharingsListComponent implements OnInit {
  displayedColumns: string[] = ["patient", "document", "sender", "date"];
  dataSourceSender;
  dataSourceReciepent;
  currentDoctor: Doctor;
  isInboxTab: boolean = true;
  isActive: boolean = false;
  nextSentDisable: boolean = false;
  previousSentDisable: boolean = false;
  listSentParams = {
    pageNumber: 1,
  };
  nextInboxDisable: boolean = false;
  previousInboxDisable: boolean = false;
  listInboxParams = {
    pageNumber: 1,
  };

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private attachmentSharingsService: AttachmentSharingsService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.showSpinner();
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
      }
    });
    this.getSentAttachmentRecords((this.listSentParams.pageNumber = 1));
    this.getRecievedAttachmentRecords((this.listInboxParams.pageNumber = 1));
  }

  paginatorSentAttachmentRecords(key: string) {
    key == "previous"
      ? this.listSentParams.pageNumber--
      : this.listSentParams.pageNumber++;
    this.getSentAttachmentRecords(this.listSentParams.pageNumber);
  }

  paginatorInboxAttachmentRecords(key: string) {
    key == "previous"
      ? this.listInboxParams.pageNumber--
      : this.listInboxParams.pageNumber++;
    this.getRecievedAttachmentRecords(this.listInboxParams.pageNumber);
  }

  changeTabs() {
    this.isInboxTab = !this.isInboxTab;
    this.isActive = !this.isActive;
  }

  getSentAttachmentRecords(page_number) {
    this.listSentParams.pageNumber == 1
      ? (this.previousSentDisable = true)
      : (this.previousSentDisable = false);
    const filter = "page=" + page_number + "&type=sent";
    this.attachmentSharingsService.all(filter).subscribe(
      (data: any) => {
        data.length < 10
          ? (this.nextSentDisable = true)
          : (this.nextSentDisable = false);
        this.globalService.hideSpinner();
        this.dataSourceSender = new MatTableDataSource(data);
        this.dataSourceSender.sort = this.sort;
        this.dataSourceSender.paginator = this.paginator;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  getRecievedAttachmentRecords(page_number) {
    this.listInboxParams.pageNumber == 1
      ? (this.previousInboxDisable = true)
      : (this.previousInboxDisable = false);
    const filter = "page=" + page_number + "&type=recieved";
    this.attachmentSharingsService.all(filter).subscribe(
      (data: any) => {
        data.length < 10
          ? (this.nextInboxDisable = true)
          : (this.nextInboxDisable = false);
        this.dataSourceReciepent = new MatTableDataSource(data);
        this.dataSourceReciepent.sort = this.sort;
        this.dataSourceReciepent.paginator = this.paginator;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}
