import { Injectable } from "@angular/core";
import { AttachmentEvent } from "src/app/models/attachment_event";

@Injectable()
export class PreviewImageHelper {
  static getImagePreview(event: AttachmentEvent): string {
    const extention = event.extension.toLowerCase();
    if (["doc", "odt", "txt", "docx"].includes(extention)) {
      return "../../assets/images/doc_format.png";
    } else if (["ppt", "pptx"].includes(extention)) {
      return "../../assets/images/ppt_format.png";
    } else if (["tiff", "tif"].includes(extention)) {
      return "../../assets/images/tif_format.png";
    } else if (
      ["jpg", "jpeg", "gif", "png", "webp", "psd", "raw", "svg"].includes(
        extention
      )
    ) {
      return event.key ? event.key : event.url;
    } else {
      return "../../assets/images/pdf_format.png";
    }
  }
}
