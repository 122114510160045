import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class InvoicesService {
  private InvoiceRecordsUrl: string = "/invoices";

  constructor(private http: HttpWrapper) {}

  all(filters?: string) {
    return this.http.get(this.InvoiceRecordsUrl + "?" + filters);
  }

  getInvoiceDetails(invoiceId: string) {
    return this.http.get(`${this.InvoiceRecordsUrl}/${invoiceId}`);
  }

  getVideoInvoices(filters?: string) {
    return this.http.get(this.InvoiceRecordsUrl + "/video?" + filters);
  }
}
