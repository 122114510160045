<div class="container mt-3">
  <h2 class="mb-3">{{ "connect.partners.partners_list" | translate }}</h2>
  <h5>{{ "connect.partners.select_your_partners" | translate }}</h5>
  <form [formGroup]="partnerForm" (ngSubmit)="savePartners()">
    <div *ngIf="partners.length; else noPartners">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="font-weight-bold">{{ 'connect.partners.name' | translate}}</th>
            <th scope="col" class="font-weight-bold">{{ 'connect.partners.country' | translate}}</th>
            <th scope="col" class="font-weight-bold">{{ 'connect.partners.description' | translate}}</th>
            <th scope="col" class="font-weight-bold custom-width-20">{{ 'connect.partners.enabled' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let partner of partners">
            <td>{{ partner.name }}</td>
            <td>{{ partner.country }}</td>
            <td>{{ partner.conditions }}</td>
            <td class="float-left"><input type="checkbox" [value]="partner._id" [formControlName]="partner._id" (change)="togglePartnerSelection($event, partner._id)"></td>
          </tr>
        </tbody>
      </table>
      <h4 class="pt-4 pb-1">{{ 'connect.partners.conditions' | translate}}</h4>
      <textarea class="form-control mb-3" formControlName="conditions" (input)="updateConditions($event)"></textarea>
    </div>
    <ng-template #noPartners>
      <div class="alert alert-info" role="alert">
        {{ 'connect.partners.no_partners' | translate}} No partners available.
      </div>
    </ng-template>
    <h5 class="mb-3">{{ 'connect.partners.partners_terms' | translate }}</h5>
    <button [disabled]="saving" type="submit" class="btn btn-primary">{{ 'connect.partners.save' | translate }}</button>
  </form>
</div>