import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-doctor-details-comments",
  templateUrl: "./common-comments-details.component.html",
  styleUrls: ["./common-comments-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonCommentsDetailsComponent {
  @Input() comments;
  @Input() review;
  @Input() currentDoctor;

  constructor(private router: Router) {}

  showCommentDetails(CommentID: string) {
    return this.router.navigate(["/comments", CommentID]);
  }
}
