import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { TemplatesService } from "src/app/services/templates.service";
import { Template } from "src/app/models/doctorTemplate.model";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Patient } from "src/app/models/medicalRecordPatient.model";

@Component({
  selector: "app-note",
  templateUrl: "./note.component.html",
  styleUrls: ["./note.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NoteComponent implements OnInit {
  @Input() noteContent: string;
  @Input() noteId: number;
  @Input() patientDetails: Patient;
  editorContent: string = "";
  isEditorBtnCLicked: boolean = false;
  template: boolean = false;
  isCancel: boolean = false;

  @Output() editorContentData: EventEmitter<string> = new EventEmitter();

  constructor(
    private snackBar: SnackBarService,
    private tref: ElementRef,
    private templatesService: TemplatesService,
    private translate: TranslateService,
    private errorHandler: ErrorsHandler,
    private errorHandlingService: ErrorsHandler
  ) {}

  ngOnInit() {
    if (this.noteId) {
      this.editorContent = this.noteContent;
      this.isCancel = true;
    } else {
      this.template = true;
      this.editorContent = "";
    }
  }

  resetForm() {
    if (this.editorContentData) this.editorContentData.emit(this.noteContent);
    this.editorContent = "";
  }

  saveContent() {
    if (
      this.editorContent === "" ||
      this.editorContent === null ||
      this.editorContent.length === 0
    ) {
      this.snackBar.open(this.translate.instant("connect.editor.can_not_add"));
    } else {
      if (this.editorContentData)
        this.editorContentData.emit(this.editorContent);
      this.editorContent = "";
    }
  }

  enableButton() {
    this.isEditorBtnCLicked = false;
  }

  getEditorContent(evt) {
    this.editorContent = evt;
  }
}
