<div class="form-group search-container">
  <input
    type="text"
    class="form-control"
    (keydown.enter)="$event.preventDefault()"
    placeholder="{{ 'connect.profile.search_nearest' | translate }}"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    type="text"
    #search
  />
</div>
<google-map
  height="250px"
  width="100%"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
  (mapClick)="click($event)"
  latitude="lat"
  longitude="long"
>
  <map-marker
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
  ></map-marker>
</google-map>
