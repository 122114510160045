import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
@Injectable()
export class AuthService {
  private logOut: string = "/logout";
  constructor(private http: HttpWrapper) {}

  public isAuthenticated(): boolean {
    // For static check using appconfig
    const token = localStorage.getItem("doctor_token");
    return token !== "undefined" ? true : false;
  }

  logout() {
    return this.http.get(this.logOut);
  }
}
