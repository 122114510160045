import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class GlobalService {
  private headerShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  private spinnerShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  get isHeaderShow() {
    return this.headerShow.asObservable();
  }

  get isSpinnerShow() {
    return this.spinnerShow.asObservable();
  }

  hideHeader() {
    this.headerShow.next(false);
  }

  showHeader() {
    this.headerShow.next(true);
  }

  hideSpinner() {
    this.spinnerShow.next(false);
  }

  showSpinner() {
    this.spinnerShow.next(true);
  }
}
