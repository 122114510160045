<ul class="nav nav-tabs nav-tabs-line nav-tabs-line-success" role="tablist">
  <li class="nav-item">
    <a
      (click)="switchTabs()"
      [ngClass]="{ active: isToggled === true }"
      class="nav-link cursor-pointer"
      data-toggle="tab"
      role="tab"
    >
      <i class="fas fa-plus"></i>
      Ajouter une note</a
    >
  </li>
  <li class="nav-item">
    <a
      (click)="switchTabs()"
      [ngClass]="{ active: isToggled === false }"
      class="nav-link cursor-pointer"
      data-toggle="tab"
      role="tab"
      ><i class="fa fa-edit"></i> Rédiger une ordonnance</a
    >
  </li>
</ul>
