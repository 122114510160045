import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";
import { AttachmentSharing } from "../models/attachment-sharings";

@Injectable({
  providedIn: "root",
})
export class AttachmentSharingsService {
  private AttachmentSharingsUrl: string = "/attachment_sharings";

  constructor(private http: HttpWrapper) {}

  all(filters?: string) {
    return this.http.get(this.AttachmentSharingsUrl + "?" + filters);
  }

  create(attachments: AttachmentSharing) {
    return this.http.post(this.AttachmentSharingsUrl, attachments);
  }
}
