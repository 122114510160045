import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: "./inbox.component.html",
})
export class InboxComponent implements OnInit {
  chats = [
    {
      name: "Mat peter",
      desc: "Head of development",
      image: "../../assets/images/avatar.png",
      nmbrOfMsg: 7,
    },
    {
      name: "Mat peter",
      desc: "Head of development",
      image: "../../assets/images/avatar.png",
      nmbrOfMsg: 2,
    },
    {
      name: "Mat peter",
      desc: "Head of development",
      image: "../../assets/images/avatar.png",
      nmbrOfMsg: 4,
    },
  ];

  ngOnInit() {}
}
