<div class="card m-3">
  <div class="reviews-box">
    <div class="row comment-wrap">
      <app-doctor-details-comments
        [comments]="false"
        [currentDoctor]="currentDoctor"
        [review]="review"
        class="review-container"
      ></app-doctor-details-comments>
    </div>
    <div class="row comment-wrap justify-content-end">
      <div class="col-11">
        <div class="comment-box">
          <span class="comment-section"
            ><img
              src="https://www.dabadoc.com/defaults/doctor.jpg"
              class="comment-avtar"
            />
            {{ "connect.doctor_comments.your_comment" | translate }} :</span
          >
          <textarea
            [(ngModel)]="doctorComment.doctor_comment"
            class="form-control"
            col="5"
            rows="10"
          ></textarea>
          <div class="mt-4">
            <label class="kt-checkbox note-check">
              <input
                type="checkbox"
                [(ngModel)]="doctorComment.doctor_comment_public"
              />
              {{ "connect.doctor_comments.comment_public" | translate }}
              <span></span>
            </label>
          </div>
          <button
            (click)="updateComment()"
            class="btn btn-brand btn-elevate btn-pill btn-elevate-air"
          >
            {{ "connect.doctor_comments.to_send" | translate }}
          </button>
          <p class="mt-4">
            {{ "connect.doctor_comments.sent_to_patient" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
