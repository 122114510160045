import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { PreviewImageHelper } from "src/app/helpers/preview_image_helper";

@Component({
  selector: "app-attachment-files",
  templateUrl: "./attachment-files.component.html",
  styleUrls: ["./attachment-files.component.scss"],
})
export class AttachmentFilesComponent implements OnInit {
  @Input() event: any;
  constructor() {}

  ngOnInit() {}

  previewImg(event): string {
    return PreviewImageHelper.getImagePreview(event);
  }
}
