import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  private statisticsUrl: string = "/stats";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.statisticsUrl);
  }
}
