import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { AttachmentSharingsService } from "src/app/services/attachment-sharings.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { AttachmentSharing } from "src/app/models/attachment-sharings";
import { ReplaySubject, Subject } from "rxjs";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil } from "rxjs/operators";
import { SnackBarService } from "src/app/services/snack-bar.service";
import Swal from "sweetalert2";
import { GlobalService } from "src/app/services/global.service";
import { Doctor } from "src/app/models/doctor";

@Component({
  selector: "attachment-sharings-dialog",
  templateUrl: "./attachment-sharings-dialog.component.html",
  styleUrls: ["./attachment-sharings-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AttachmentSharingsDialogComponent implements OnInit {
  doctorAttachmentSharings = {} as AttachmentSharing;
  patient: any;
  doctorLists: any;
  events: any;
  isDabadocUser: boolean = true;
  isActive: boolean = true;
  doctorNameValue: Boolean = true;
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  doctor_records: Doctor[];
  protected _onDestroy = new Subject<void>();
  attachementForm: UntypedFormGroup;
  last_name: string = "";
  selected_values: any;
  isInvalidEmail: boolean = false;
  isRequired: boolean = false;
  isEmailMatched: boolean = false;
  isOtherUser: boolean = false;
  isDoctorSelected: boolean = false;

  public filteredDoctors: ReplaySubject<Doctor[]> = new ReplaySubject<[]>(1);
  public doctorFilterCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(
    private doctorService: DoctorService,
    private attachmentSharingsService: AttachmentSharingsService,
    private errorHandler: ErrorsHandler,
    private formBuilder: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private cdref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AttachmentSharingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.filteredDoctors.next(this.doctor_records);
    this.doctorFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMedicalRecords();
      });
    this.initializeAttachmentForm();
  }

  initializeAttachmentForm() {
    this.patient = this.data.patient;
    if (this.data.event.length) {
      this.events = this.data.event;
    } else {
      let arr = [];
      arr.push(this.data.event);
      this.events = arr;
    }
    this.doctorAttachmentSharings.patient_first_name = this.patient.first_name;
    this.doctorAttachmentSharings.patient_last_name = this.patient.last_name;
    this.doctorAttachmentSharings.patient_email = this.patient.email;
    this.doctorAttachmentSharings.patient_phone_number =
      this.patient.phone_number;
    this.doctorAttachmentSharings.patient_date_of_birth =
      this.patient.date_of_birth;
    this.doctorAttachmentSharings.attachments = this.events;
    this.attachementForm = this.formBuilder.group({
      recipient_first_name: [],
      recipient_last_name: [],
      recipient_email: [],
      recipient_confirm_email: [],
      recipient_phone_number: [],
      doctor_name: [{ value: "" }],
    });
  }

  handleSelectChange(event) {
    this.selected_values = event.split("/");
    this.doctorAttachmentSharings.recipient_doctor_id = this.selected_values[0];
    this.isDoctorSelected = false;
  }

  protected filterMedicalRecords() {
    this.globalService.showSpinner();
    this.attachementForm.controls["doctor_name"].setValue(
      this.doctorFilterCtrl.value
    );
    let search = this.doctorFilterCtrl.value;
    if (!search) {
      this.globalService.hideSpinner();
      if (this.doctor_records) {
        this.filteredDoctors.next(this.doctor_records.slice());
      }
      return;
    } else {
      this.last_name = search;
      this.doctorNameValue = true;
      search = search.toLowerCase();
    }
    const filter = "search[query]=" + search;
    this.doctorService.filterDoctors(filter).subscribe((data: []) => {
      this.globalService.hideSpinner();
      this.doctor_records = data;
      this.filteredDoctors.next(
        this.doctor_records.filter(
          (doctor_record) =>
            doctor_record.name.toLowerCase().indexOf(search) > -1
        )
      );
    });
  }

  changeTabs() {
    this.isDabadocUser = !this.isDabadocUser;
    this.isActive = !this.isActive;
    this.isOtherUser = !this.isOtherUser;

    if (this.isOtherUser) {
      this.attachementForm.controls["recipient_first_name"].setValidators([
        Validators.required,
      ]);
      this.attachementForm.controls["recipient_last_name"].setValidators([
        Validators.required,
      ]);
      this.attachementForm.controls["recipient_email"].setValidators([
        Validators.required,
        Validators.email,
      ]);
    } else {
      this.attachementForm.get("recipient_first_name").clearValidators();
      this.attachementForm.get("recipient_last_name").clearValidators();
      this.attachementForm.get("recipient_email").clearValidators();
      this.attachementForm.get("recipient_first_name").updateValueAndValidity();
      this.attachementForm.get("recipient_last_name").updateValueAndValidity();
      this.attachementForm.get("recipient_email").updateValueAndValidity();
    }
  }

  shareAttachment() {
    this.doctorAttachmentSharings.recipient_first_name =
      this.attachementForm.value.recipient_first_name;
    this.doctorAttachmentSharings.recipient_last_name =
      this.attachementForm.value.recipient_last_name;
    this.doctorAttachmentSharings.recipient_email =
      this.attachementForm.value.recipient_email;
    this.doctorAttachmentSharings.recipient_phone_number =
      this.attachementForm.value.recipient_phone_number;

    if (this.isOtherUser) {
      this.isInvalidEmail = true;
      if (
        this.attachementForm.value.recipient_email ==
        this.attachementForm.value.recipient_confirm_email
      ) {
        this.checkEmailAvailable(this.doctorAttachmentSharings.recipient_email);
        this.isInvalidEmail = false;
      }
    } else {
      this.doctorAttachmentSharings.recipient_doctor_id
        ? this.sendAttactments()
        : (this.isDoctorSelected = true);
    }
  }

  checkEmailAvailable(email) {
    const filter = "email=" + email;
    this.doctorService
      .checkEmailAvailable(filter)
      .subscribe((data: boolean) => {
        if (!data) this.sendAttactments();
        this.isEmailMatched = data;
      });
  }

  sendAttactments() {
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      icon: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant("connect.globals.confirm"),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        this.globalService.showSpinner();
        this.attachmentSharingsService
          .create(this.doctorAttachmentSharings)
          .subscribe(
            (data) => {
              this.globalService.hideSpinner();
              this.snackBar.open(
                this.translate.instant(
                  "connect.sharing_dialog.attachment_shared"
                )
              );
              this.dialogRef.close();
            },
            (error) =>
              this.errorHandler.handleError(
                this.translate.instant("connect.globals.error_occured_message")
              )
          );
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
