<div class="container-fluid">
  <section class="content-wrapper appointments-container">
    <div class="">
      <div
        class="appointment-table table mat-elevation-z0 confirm-appointments-table"
      >
        <div *ngIf="dataSource && dataSource.data.length === 0">
          <h2 class="no-filter-data">
            {{ "connect.bookings-table.no_result" | translate }}
          </h2>
        </div>
        <mat-table
          #table
          [dataSource]="dataSource"
          matSort
          class="table-cell-block flex-mat-table"
          *ngIf="dataSource && dataSource.data.length > 0"
        >
          <ng-container matColumnDef="schedule">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <h6 class="table-heading">
                {{ "connect.bookings-table.schedule" | translate }}
              </h6>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let bookingSlot"
              class="cursor-pointer mat-block"
            >
              <span class="mr-10"
                ><i
                  [className]="
                    isIconToggled === true && rowId === bookingSlot.id
                      ? 'fa fa-caret-down'
                      : 'fa fa-caret-right'
                  "
                  style="
                    margin-right: 5px;
                    color: #22b9ff;
                    font-size: 32px;
                    vertical-align: middle;
                  "
                ></i>
                {{ bookingSlot.starts_at | ToTimeFormat }} -
                {{ bookingSlot.ends_at | ToTimeFormat }}</span
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <h6 class="table-heading">
                {{ "connect.bookings-table.patient" | translate }}
              </h6>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let bookingSlot"
              class="mat-block mat-border-right"
            >
              <span class="mr-10"> {{ bookingSlot.title }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <h6 class="table-heading">
                {{ "connect.bookings-table.pattern" | translate }}
              </h6>
            </mat-header-cell>

            <mat-cell *matCellDef="let bookingSlot" class="mat-block">
              <div *ngIf="bookingSlot.slot_type === appointmentTypes.MANUAL">
                <div
                  class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill type-icons"
                  *ngIf="bookingSlot.type !== bookingTypes.VIDEO"
                >
                  <span>{{
                    "connect.appointment_table." + bookingTypes.OFFICE
                      | translate
                  }}</span>
                  <i class="fa fa-building"></i>
                </div>
                <div
                  class="kt-badge kt-badge--danger kt-badge--inline user-kt-badge--pill type-icons"
                  *ngIf="
                    bookingSlot.type === bookingTypes.VIDEO &&
                    bookingSlot.state === appointmentStates.AWAITING_PAYMENT
                  "
                >
                  <span class="mr-2">{{
                    "connect.appointment_table." + bookingTypes.VIDEO
                      | translate
                  }}</span>
                  <i class="fa fa-exclamation-circle mr-2"></i>
                  <i class="fa fa-video"></i>
                </div>
              </div>
              <div
                class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill type-icons"
                *ngIf="
                  bookingSlot.type === bookingTypes.OFFICE &&
                  bookingSlot.slot_type === appointmentTypes.ONLINE
                "
              >
                <span>{{
                  "connect.appointment_table." + bookingTypes.OFFICE | translate
                }}</span>
                <i class="fa fa-building"></i>
              </div>
              <div
                class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill type-icons"
                *ngIf="
                  bookingSlot.state !== appointmentStates.AWAITING_PAYMENT &&
                  bookingSlot.type === bookingTypes.VIDEO
                "
              >
                <span>{{
                  "connect.appointment_table." + bookingTypes.VIDEO | translate
                }}</span>
                <i class="fa fa-video-camera"></i>
              </div>
              <div
                class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill refunded-icon"
                *ngIf="
                  bookingSlot.type === bookingTypes.VIDEO &&
                  bookingSlot.refunded &&
                  bookingSlot.slot_type !== appointmentTypes.MANUAL
                "
              >
                <span>{{
                  "connect.appointment_table." + appointmentTypes.REFUNDED
                    | translate
                }}</span>
                <i class="fas fa-undo"></i>
              </div>
              <div
                class="kt-badge domicile-primary kt-badge--inline kt-badge--pill type-icons"
                *ngIf="bookingSlot.type === bookingTypes.HOME"
              >
                <span>{{
                  "connect.appointment_table." + bookingTypes.HOME | translate
                }}</span>
                <i class="fa fa-home"></i>
              </div>
              <div
                class="kt-badge kt-badge--success kt-badge--inline user-kt-badge--pill type-icons"
                *ngIf="bookingSlot.slot_type === appointmentTypes.ONLINE"
              >
                <i class="fa fa-user"></i>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-btns">
              <h6 class="table-heading text-center">
                {{ "connect.bookings-table.actions" | translate }}
              </h6>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let bookingSlot"
              class="actions-btns mat-block centralized-btns"
            >
              <div class="action-buttons">
                <span *ngIf="!bookingSlot.patient_didnt_showup">
                  <button
                    (click)="confirmBookingSlot(bookingSlot)"
                    class="btn confirm-new-btn btn-icon pointer"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                  <button
                    (click)="openBookingModal(bookingSlot)"
                    class="btn edit-btn btn-icon m-2 p-3 cursor-pointer"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    class="btn delete-btn btn-icon pointer"
                    (click)="cancelBookingSlot(bookingSlot)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupHeader">
            <mat-cell class="mat-group-cell" *matCellDef="let group">
              <b>{{ group.starts_at | customDate }}</b>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="toggleIcon(row)"
            matRipple
            [cdkDetailRow]="row"
            [cdkDetailRowTpl]="tpl"
          >
          </mat-row>
          <mat-row
            class="mat-group-row"
            *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
          >
          </mat-row>
        </mat-table>

        <ng-template #tpl let-bookingDetails>
          <div
            class="mat-row detail-row"
            [@detailExpand]
            style="overflow: hidden"
          >
            {{ bookingDetails.user_phone_number }} /
            {{ bookingDetails.user_email }}
          </div>
        </ng-template>
      </div>
    </div>
  </section>
</div>
