<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template medical-record mass-sms">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title"></h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <button class="create-btn" (click)="paymentSms()" mat-button>
            <span>{{ "connect.mass_sms.tariff_plans" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="kt-portlet__body" *ngIf="currentDoctor">
        <div class="row justify-content-center">
          <div class="col-md-8 col-md-offset-2">
            <div class="panel panel-info">
              <div class="panel-body hometable">
                <div class="panel-heading">
                  <h3 class="panel-title">
                    {{ "connect.mass_sms.send_mass_message" | translate }}
                  </h3>
                </div>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-sm-6 statscontainer">
                      <div class="stat-widget stat-metric sms-balance">
                        <span class="stat-metric-value">{{
                          currentDoctor.sms_balance
                        }}</span>
                        <span class="stat-metric-title">{{
                          "connect.mass_sms.sms_balance" | translate
                        }}</span>
                      </div>
                    </div>
                    <div class="col-sm-6 statscontainer">
                      <div class="stat-widget stat-metric" id="patients-count">
                        <span class="stat-metric-value">{{
                          unique_moroccan_contacts
                        }}</span>
                        <span class="stat-metric-title">{{
                          "connect.mass_sms.patients_on_dabaDoc" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row mt-5"
                    *ngIf="currentDoctor.sms_balance > unique_moroccan_contacts"
                  >
                    <div class="col-sm-12">
                      <textarea
                        [(ngModel)]="content"
                        (focus)="removePlacehoder()"
                        class="message form-control ng-pristine ng-valid ng-valid-required ng-valid-maxlength ng-touched"
                        rows="5"
                        maxlength="160"
                      ></textarea>
                      <span class="pull-left ng-binding">
                        {{ 160 - content.length }} characters left
                      </span>
                      <br />
                      <button
                        mat-raised-button
                        color="primary"
                        class="ml-auto pull-right"
                        (click)="sendSms()"
                      >
                        {{ "connect.mass_sms.to_send" | translate }}
                      </button>
                    </div>
                  </div>
                  <div
                    class="message-block col-md-12"
                    *ngIf="currentDoctor.sms_balance < unique_moroccan_contacts"
                  >
                    <h3>
                      {{ "connect.mass_sms.need_to_buy_more" | translate }}
                      {{ unique_moroccan_contacts }}
                      {{ "connect.mass_sms.current_patient_count" | translate }}
                    </h3>
                    <button
                      mat-raised-button
                      color="primary"
                      class="ml-auto"
                      (click)="paymentSms()"
                      mat-button
                    >
                      <span>{{
                        "connect.mass_sms.tariff_plans" | translate
                      }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
