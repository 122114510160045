import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TemplatesService } from "src/app/services/templates.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-doctor-template-dialog",
  templateUrl: "./doctor-template-dialog.component.html",
})
export class DoctorTemplateDialogComponent implements OnInit {
  templateEditable: any = {};

  constructor(
    private templatesService: TemplatesService,
    public dialogRef: MatDialogRef<DoctorTemplateDialogComponent>,
    private snackBar: SnackBarService,
    private translate: TranslateService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  reloadAfterSuccess() {
    return window.location.reload();
  }

  createTemplate() {
    if (this.templateEditable.name && this.templateEditable.content) {
      if (
        this.templateEditable.content === "" ||
        this.templateEditable.name === "" ||
        this.templateEditable.content === null ||
        this.templateEditable.name === null ||
        this.templateEditable.content.length === 0 ||
        this.templateEditable.name.length === 0
      ) {
        this.snackBar.open(
          this.translate.instant("connect.templates.can_not_add")
        );
      } else {
        this.templatesService
          .create(this.templateEditable)
          .subscribe((data) => {
            this.snackBar.open(
              this.translate.instant("connect.templates.template_added")
            );
            this.onNoClick();
            this.reloadAfterSuccess();
            (error) => {
              this.snackBar.open(
                this.translate.instant("connect.globals.error_occured_message")
              );
            };
          });
      }
    } else {
      this.snackBar.open(
        this.translate.instant("connect.templates.can_not_add")
      );
    }
  }

  ngOnInit() {
    this.templateEditable.content = "";
  }

  getEditorContent(evt) {
    this.templateEditable.content = evt;
  }
}
