import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavbarHelper {
  menuItems = [
    { name: "agenda", icon: "calendar_today", link: "calendar" },
    { name: "folder", icon: "folder", link: "medical-records" },
    { name: "messaging", icon: "message", link: "messages" },
    { name: "inbox", icon: "attach_file", link: "docshare" },
    { name: "settings", icon: "settings", link: "settings" },
  ];

  constructor(private router: Router) {}

  goToURl(url) {
    url == "messages"
      ? (window.location.href = "/messages")
      : this.router.navigate(["/" + url]);
  }
}
