import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  private templatesUrl: string = "/templates";

  constructor(private http: HttpWrapper) {}

  all() {
    return this.http.get(this.templatesUrl);
  }

  create(template: any) {
    return this.http.post(this.templatesUrl, template);
  }

  delete(templateID: any) {
    return this.http.delete(`${this.templatesUrl}/${templateID}`);
  }

  update(templateID: any, content: any) {
    return this.http.put(`${this.templatesUrl}/${templateID}`, content);
  }
}
