<div class="app-header kt-header" (window:resize)="onResize()">
  <kt-brand></kt-brand>
  <div class="kt-header-menu-wraper">
    <div
      class="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
      [ngClass]="{ 'kt-header-menu-wrapper--on': this.addHeaderToggleClass }"
    >
      <button
        class="kt-header-menu-wrapper-close"
        id="kt_header_menu_mobile_close_btn"
        (click)="changeHeader()"
      >
        <i class="fa fa-times"></i>
      </button>
      <div
        id="kt_header_menu"
        class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default"
      >
        <ul *ngIf="!isMobile" class="kt-menu__nav sidenavbar-hide-mob">
          <li class="kt-menu__item" *ngFor="let item of menuItems">
            <ng-container
              *ngIf="
                item.name == 'folder' && hasConnectPlusAccess;
                then hasFolderAccess;
                else hasNotFolderAccess
              "
            ></ng-container>
            <ng-template #hasFolderAccess>
              <a
                *ngIf="item.name == 'folder'"
                [routerLink]="[item.link]"
                routerLinkActive="active"
                class="kt-menu__link"
              >
                <span class="kt-menu__link-text">
                  {{ "connect.header." + item.name | translate }}
                </span>
              </a>
            </ng-template>
            <ng-template #hasNotFolderAccess>
              <a
                *ngIf="item.name == 'folder'"
                (click)="showWarningMessage()"
                class="kt-menu__link"
              >
                <span class="kt-menu__link-text">
                  {{ "connect.header." + item.name | translate }}
                </span>
              </a>
            </ng-template>

            <a
              *ngIf="item.name == 'messaging'"
              [href]="item.link"
              routerLinkActive="active"
              class="kt-menu__link"
            >
              <span class="kt-menu__link-text">
                {{ "connect.header." + item.name | translate }}
              </span>
            </a>
            <a
              *ngIf="item.name == 'agenda'"
              [routerLink]="[item.link]"
              routerLinkActive="active"
              class="kt-menu__link"
            >
              <span class="kt-menu__link-text">
                {{ "connect.header." + item.name | translate }}
              </span>
            </a>
            <ng-container
              *ngIf="
                item.name == 'inbox' && hasConnectPlusAccess;
                then hasInboxAccess;
                else hasNotInboxAccess
              "
            ></ng-container>
            <ng-template #hasInboxAccess>
              <a
                *ngIf="item.name == 'inbox'"
                [routerLink]="[item.link]"
                routerLinkActive="active"
                class="kt-menu__link"
              >
                <span class="kt-menu__link-text">
                  {{ "connect.header." + item.name | translate }}
                </span>
              </a>
            </ng-template>
            <ng-template #hasNotInboxAccess>
              <a
                *ngIf="item.name == 'inbox'"
                (click)="showWarningMessage()"
                class="kt-menu__link"
              >
                <span class="kt-menu__link-text">
                  {{ "connect.header." + item.name | translate }}
                </span>
              </a>
            </ng-template>
          </li>
        </ul>
        <ul *ngIf="isMobile" class="kt-menu__nav right-nav">
          <h2>
            {{ "connect.header.profile" | translate }}
          </h2>
          <li class="kt-menu__item">
            <a [href]="currentDoctor?.profile_url" class="kt-menu__link">
              <mat-icon class="kt-menu__link-icon">person</mat-icon>
              <span>{{ "connect.header.profile_dabadoc" | translate }}</span>
            </a>
          </li>
          <li class="kt-menu__item">
            <a [routerLink]="['profile/edit']" class="kt-menu__link">
              <mat-icon class="kt-menu__link-icon">edit</mat-icon>
              <span>{{ "connect.header.modify_profile" | translate }}</span>
            </a>
          </li>
          <li class="kt-menu__item">
            <a [routerLink]="['settings']" class="kt-menu__link">
              <mat-icon class="kt-menu__link-icon"
                >settings_applications</mat-icon
              >
              <span>{{ "connect.header.parametres" | translate }}</span>
            </a>
          </li>
          <li class="kt-menu__item">
            <a [href]="disconnectLink" class="kt-menu__link">
              <mat-icon class="kt-menu__link-icon">power_settings_new</mat-icon>
              <span>{{ "connect.header.deconnection" | translate }}</span>
            </a>
          </li>
          <li class="kt-menu__item kt-menu__item--submenu kt-menu__item--rel">
            <a
              *ngIf="isPwa"
              (click)="addToHomeScreen()"
              class="kt-menu__link-text install-link pointer"
            >
              <span class="btn btn-elevate btn-pill install-span">
                <span>{{ "connect.header.install" | translate }}</span>
              </span>
            </a>
            <a [href]="helpVideosLink" class="kt-menu__link help-link pointer">
              <span class="kt-menu__link-text">
                <span
                  class="btn help-button sidebar-help-button"
                  style="color: #fff"
                  >{{ "connect.header.need_help" | translate }}</span
                >
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      *ngIf="isMobile"
      id="kt_header_mobile"
      class="kt-header-mobile kt-header-mobile--fixed"
    >
      <div class="kt-header-mobile__toolbar">
        <div
          class="kt-header-mobile__toolbar-toggler"
          id="kt_header_mobile_toggler"
          (click)="changeHeader()"
        >
          <span></span>
        </div>
      </div>
      <div class="kt-header-mobile__logo">
        <a href="#">
          <img alt="Logo" src="../../assets/images/dabadoc-logo-blue.png" />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <div
          class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler"
          (click)="changeSidebar()"
        >
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <header-search-field (toogleData)="(isToggled)"></header-search-field>
  <ul *ngIf="!isMobile" class="kt-menu__nav right-nav">
    <li class="kt-menu__item">
      <a [matMenuTriggerFor]="profileMenu" class="kt-menu__link profile-button">
        <mat-icon class="kt-menu__link-icon">person</mat-icon>
        <span class="kt-menu__link-text dropdown-toggle">{{
          "connect.header.profile" | translate
        }}</span>
      </a>
      <mat-menu #profileMenu="matMenu">
        <a [href]="currentDoctor?.profile_url">
          <button mat-menu-item>
            <mat-icon>person</mat-icon>
            {{ "connect.header.profile_dabadoc" | translate }}
          </button>
        </a>
        <a [routerLink]="['profile/edit']">
          <button mat-menu-item>
            <mat-icon>edit</mat-icon>
            {{ "connect.header.modify_profile" | translate }}
          </button>
        </a>
        <button [routerLink]="['settings']" mat-menu-item>
          <mat-icon>settings_applications</mat-icon>
          {{ "connect.header.parametres" | translate }}
        </button>
        <form [action]="disconnectLink" method="get" #logoutForm>
          <button type="submit" form="logoutForm" (click)="logoutForm.submit()" mat-menu-item>
            <mat-icon>power_settings_new</mat-icon>
            {{ "connect.header.deconnection" | translate }}
          </button>
        </form>
      </mat-menu>
    </li>
    
    <li class="kt-menu__item kt-menu__item--submenu kt-menu__item--rel">
      <a
        *ngIf="isPwa"
        (click)="addToHomeScreen()"
        class="kt-menu__link-text install-link pointer"
      >
        <span class="btn btn-elevate btn-pill install-span">
          {{ "connect.header.install" | translate }}
        </span>
      </a>
    </li>
    <li>
      <a [href]="helpVideosLink" class="kt-menu__link help-link pointer">
        <span class="kt-menu__link-text">
          <span class="btn help-button btn-pill">{{
            "connect.header.need_help" | translate
          }}</span>
        </span>
      </a>
    </li>
  </ul>
</div>

<app-aside (toogleData)="(isToggled)"></app-aside>
