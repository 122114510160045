import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { InvoicesService } from "src/app/services/invoices.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Invoice } from "src/app/models/invoices";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Moment } from "moment";
import { GlobalService } from "src/app/services/global.service";

export const MONTH_MODE_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-invoice-video",
  templateUrl: "./invoice-video.component.html",
  styleUrls: ["./invoice-video.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS },
  ],
})
export class InvoiceVideoComponent implements OnInit {
  currentDoctor: Doctor;
  invoiceDetail: string;
  amount_net: number = 0;
  commission_net: number = 0;
  startDate = new Date();
  monthYear: string;

  constructor(
    private invoiceService: InvoicesService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private globalService: GlobalService,
    private errorHandler: ErrorsHandler
  ) {}

  ngOnInit() {
    this.monthYear = this.getMonthYear(new Date());
    this.getVideoInvoices(this.monthYear);
  }

  monthSelectedHandler(chosenMonthDate: Moment, picker: MatDatepicker<Moment>) {
    picker.close();
    this.startDate = chosenMonthDate.toDate();
    this.monthYear = this.getMonthYear(this.startDate);
  }

  getVideoInvoices(params) {
    this.globalService.showSpinner();
    const filter = "month=" + params;
    this.invoiceService.getVideoInvoices(filter).subscribe(
      (data: any) => {
        this.amount_net = 0;
        this.commission_net = 0;
        this.invoiceDetail = data["video_invoices"];
        data["video_invoices"].filter((element) => {
          this.amount_net = this.amount_net + element.total_amount;
          this.commission_net = this.commission_net + element.commission;
        });
        this.globalService.hideSpinner();
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  getInvoices() {
    this.getVideoInvoices(this.monthYear);
  }

  getMonthYear(date_param): string {
    if (date_param) {
      const year = date_param.getFullYear();
      const month = date_param.getMonth() + 1;
      if (month < 10) {
        return year + "-" + "0" + month;
      } else {
        return year + "-" + month;
      }
    }
  }
}
