<div class="col-12">
  <div
    class="kt-portlet setting-data dr-prescriptions kt-portlet--height-fluid"
  >
    <div class="kt-portlet__body">
      <div class="row">
        <div class="col-md-6 col-sm-5 col-12">
          <div class="order-box">
            <h1>{{ "connect.prescriptions.ordonnance" | translate }}</h1>
          </div>
        </div>
        <div class="col-md-6 col-sm-7 col-12">
          <!-- <div class="doctor-info" *ngIf="currentDoctor">
            <app-editor [content]="currentDoctor.prescription_title" [disabled]="disabled"></app-editor>
          </div> -->
        </div>
      </div>
      <div class="seprator-line top-zero"></div>
      <div class="patient_info_box" *ngIf="prescriptionDetail">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-12">
            <div class="order-box">
              <h2>{{ prescriptionDetail.patient_name }}</h2>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 col-12">
            <div class="order-box">
              <h2>{{ prescriptionDetail.created_at | date : "dd/MM/yyyy" }}</h2>
            </div>
          </div>
        </div>
        <div class="seprator-line"></div>
        <div *ngIf="prescriptionDetail">
          <div *ngFor="let posologie of prescriptionDetail.posologies">
            <div class="row mb-4">
              <div class="col-md-12 col-sm-12 col-12">
                <div class="order-box">
                  <h1>{{ posologie.medicine_name }}</h1>
                  <h3>{{ posologie.medicine_dosage }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="seprator-line"></div>
      <div class="row mb-5 mt-5" *ngIf="prescriptionDetail">
        <div
          class="col-md-12 col-sm-12 col-12"
          *ngIf="prescriptionDetail.posologie"
        >
          <div class="order-box">
            <h1>
              {{ "connect.prescriptions.other_instructions" | translate }}
            </h1>
            <h3>{{ prescriptionDetail.posologie }}</h3>
          </div>
        </div>
      </div>
      <div class="seprator-line"></div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-12">
          <div class="qrcode-box">
            <img src="../../../assets/images/qrcode.png" width="150" />
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-12">
          <div class="signature-box">
            <div class="signature-box"></div>
            <span *ngIf="currentDoctor"
              >{{ currentDoctor.title | titlecase }}
              {{ currentDoctor.first_name }} {{ currentDoctor.last_name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
