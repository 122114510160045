import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PatientLabel } from "src/app/models/patient-label";
import { Doctor } from "src/app/models/doctor";
import { MatDialog } from "@angular/material/dialog";
import { PatientLabelFormDialogComponent } from "../patient-label-form-dialog/patient-label-form-dialog.component";

@Component({
  selector: "app-patient-label",
  templateUrl: "./patient-label.component.html",
  styleUrls: ["./patient-label.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PatientLabelComponent implements OnInit {
  patientLabels: PatientLabel[] = []
  currentDoctor: Doctor

  constructor(
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private errorHandler: ErrorsHandler,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getDoctorInfo() 
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if(!currentDoctor) return;
      this.currentDoctor = currentDoctor
      this.patientLabels = this.currentDoctor.patient_labels
    })
  }

  editPatientLabel(element){
    let dialogRef = this.dialog.open(PatientLabelFormDialogComponent, {
      data:{
        actionType: 'edit',
        element: element
      },
      width: '560px',
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((result : any)  => {
      if(!result) return;
      let payload = [result];
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.patient_labels.patient_label_updated")
        );
      });
    })
  }

  addPatientLabel(){
    let dialogRef = this.dialog.open(PatientLabelFormDialogComponent, {
      data:{
        actionType: 'create',
        element: null
      },
      width: '560px',
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((result : any)  => {
      if(!result) return;
      delete result.id
      let payload = [result]
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.patient_labels.patient_label_created")
        );
      });
    })
  }

  deletePatientLabel(element) {
    if (!window.confirm(this.translate.instant("connect.patient_labels.are_you_sure"))) return;
    if(element){
      let payload = [{...element, _destroy: true}]
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.patient_labels.patient_label_deleted")
        );
      });
    }
  }

  applyUpdatesToEntities(entities){
    return new Promise((resolve, reject) => {
      this.doctorService.updatePatientLabels(entities).subscribe(
        (data: any) => {
          if(data.patient_labels){
            this.patientLabels = data.patient_labels
          }
          resolve(data)
        },
        (error) =>{
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
            )
          reject(error)
        }
      );
    })
  }

}
