import { Injectable } from "@angular/core";
import { Doctor } from "src/app/models/doctor";

var curValue = require("multilingual-number-to-words");
const french = {
  single_digits: [
    "zéro",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
  ],
  teens: [
    "dix",
    "onze",
    "douze",
    "treize",
    "quatorze",
    "quinze",
    "seize",
    "dix-sept",
    "dix-huit",
    "dix-neuf",
  ],
  double_digits: [
    "zéro",
    "dix",
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingts",
    "quatre-vingt-dix",
  ],
  crore_lakhs: ["cents", "mille", "lakh", "crore"],
  million_billions: [
    "cents",
    "mille",
    "million",
    "billion",
    "billion",
    "quadrillion",
  ],
  and_currency: ["et", "Dirhams", "paise", "point"],
};
curValue.addLanguage("french", french);
@Injectable({
  providedIn: "root",
})
export class WordsHelper {
  getAmountInWords(doctor: Doctor, amount: string) {
    let text = curValue.millionWord(amount);
    if (doctor.locale === "fr") {
      text = curValue.millionWord(amount, "french");
    }
    return text;
  }
}
