import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Patient } from "src/app/models/medicalRecordPatient.model";
import { TranslateService } from "@ngx-translate/core";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "src/app/helpers/datepicker-helper";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class DialogComponent implements OnInit {
  patientForm: UntypedFormGroup;
  patient = <Patient>{};
  regexNameValidation: any = /^[a-zA-Z ]{1,30}$/;

  constructor(
    private medicalRecord: MedicalRecordService,
    public dialogRef: MatDialogRef<DialogComponent>,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  reloadAfterSuccess() {
    return window.location.reload();
  }

  createNewMedicalRecord() {
    this.medicalRecord.create(this.patient).subscribe((data) => {
      this.snackBar.open(
        this.translate.instant("connect.medical-dailog.medical_record_added")
      );
      this.onNoClick();
      this.reloadAfterSuccess();
      (error) => {
        this.snackBar.open(
          this.translate.instant("connect.globals.error_occured_message")
        );
      };
    });
  }

  ngOnInit() {
    this.patientForm = this.formBuilder.group({
      first_name: [
        null,
        [Validators.required, Validators.pattern(this.regexNameValidation)],
      ],
      last_name: [
        null,
        [Validators.required, Validators.pattern(this.regexNameValidation)],
      ],
      gender: [null, [Validators.required]],
      date_of_birth: [null, [Validators.required]],
    });

    this.patientForm.valueChanges.subscribe((data) => (this.patient = data));
  }
}
