import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  templateUrl: "./medical-record-print.component.html",
  styleUrls: ["./medical-record-print.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MedicalRecordPrintComponent implements OnInit, OnDestroy {
  MedicalRecordID: string;
  NoteID: string;
  prescriptionDetail: string = "";
  disabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private medicalRecordService: MedicalRecordService,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.MedicalRecordID = params["MedicalRecordID"];
      this.NoteID = params["noteId"];
    });
    this.getMedicalNote();
  }

  getMedicalNote() {
    this.medicalRecordService
      .getMedicalNote(this.MedicalRecordID, this.NoteID)
      .subscribe(
        (noteData: any) => {
          this.prescriptionDetail = noteData;
          this.globalService.hideHeader();
        },
        (error) =>
          this.snackBar.open(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }

  ngOnDestroy() {
    this.globalService.showHeader();
  }
}
