import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PremiumGuard } from './premium.guard';
import { DoctorService } from '../services/doctor.service';

@Injectable({ providedIn: 'root' })
export class PremiumDoctorGuard  extends PremiumGuard
{
  constructor(doctorService: DoctorService, router: Router)
  {
    super(doctorService, router);
  }

  protected condition(doctor: any): boolean
  {
    return doctor.is_premium;
  }
}