import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";
import { Patient } from "../models/medicalRecordPatient.model";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MedicalRecordService {
  private MedicalRecordsUrl: string = "/medical_records";
  private MedicalRecordsPatientUrl: string = '/medical-records';

  constructor(private http: HttpWrapper, private router: Router) {}

  navigateToMedicalRecordPatient(patientId:string)
  {
    this.router.navigate([this.MedicalRecordsPatientUrl, patientId]);
  }

  all(filters?: string) {
    return this.http.get(this.MedicalRecordsUrl + "?" + filters);
  }

  get(medicalRecordId: string) {
    return this.http.get(`${this.MedicalRecordsUrl}/${medicalRecordId}`);
  }

  create(medicalRecord: Patient) {
    return this.http.post(this.MedicalRecordsUrl, medicalRecord);
  }

  delete(medicalRecordId: string) {
    return this.http.delete(`${this.MedicalRecordsUrl}/${medicalRecordId}`);
  }

  update(medicalRecordId: string, medicalRecord: any) {
    return this.http.put(
      `${this.MedicalRecordsUrl}/${medicalRecordId}`,
      medicalRecord
    );
  }

  getEvents(MedicalRecordID: string, page: any) {
    return this.http.get(
      `${this.MedicalRecordsUrl}/${MedicalRecordID}/events?page=${page}`
    );
  }

  getMedicalNote(MedicalRecordID: string, noteID: string) {
    return this.http.get(
      `${this.MedicalRecordsUrl}/${MedicalRecordID}/notes/${noteID}`
    );
  }

  AddNote(
    MedicalRecordID: string,
    content: { content: string; attachments: any[] }
  ) {
    return this.http.post(
      `${this.MedicalRecordsUrl}/${MedicalRecordID}/notes`,
      content
    );
  }

  deleteNote(MedicalRecordID: string, noteID: any) {
    return this.http.delete(
      `${this.MedicalRecordsUrl}/${MedicalRecordID}/notes/${noteID}`
    );
  }

  updateNote(
    MedicalRecordID: string,
    noteID: any,
    content: {}
  ) {
    return this.http.put(
      `${this.MedicalRecordsUrl}/${MedicalRecordID}/notes/${noteID}`,
      content
    );
  }

  deleteAttachments(attachments, medicalRecordId, noteID) {
    return this.http.post(`${this.MedicalRecordsUrl}/${medicalRecordId}/notes/${noteID}/delete_attachments`, {urls:attachments})
  }
}
