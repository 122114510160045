import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookingSlotType } from 'src/app/models/booking-slot-type';
import { Department } from 'src/app/models/department';
import { Doctor } from 'src/app/models/doctor';
import { DoctorService } from "src/app/services/doctor.service";

export interface FormDialogData {
  actionType: 'create' | 'edit',
  element: BookingSlotType
}

@Component({
  selector: 'app-appointement-categorie-form-dialog',
  templateUrl: './appointement-categorie-form-dialog.component.html',
  styleUrls: ['./appointement-categorie-form-dialog.component.scss']
})
export class AppointementCategorieFormDialogComponent implements OnInit {
  departments: Array<Department>;
  current_doctor: Doctor;

  constructor(
    private doctorService: DoctorService,
    public dialogRef: MatDialogRef<AppointementCategorieFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData
    ) {
    switch (data.actionType) {
      case 'edit':
        this.formData.setValue({
          id: data.element.id,
          public: data.element.public,
          color: data.element.color,
          duration: data.element.duration,
          name: data.element.name,
          price: data.element.price,
          department_id: data.element.department_id
        });
        break;

      default:
        break;
    }
  }

  formData: FormGroup = new FormGroup({
    id: new FormControl(),
    public: new FormControl(),
    color: new FormControl(),
    duration: new FormControl(),
    name: new FormControl(),
    price: new FormControl(),
    department_id: new FormControl()
  });

  progressForm: any={
    mode: 'determinate',
    value: 0
  }

  ngOnInit() {
    this.getDoctorInfo();
  }

  onSubmit(){
    this.dialogRef.close(this.formData.value)
  }
  private
  getDoctorInfo() {
    this.doctorService.get().subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.current_doctor = currentDoctor;
        this.departments = this.current_doctor.departments;
      }
    });
  }
}
