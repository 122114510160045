<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.templates.my_template" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <button class="create-btn" (click)="openDialog()" mat-button>
            <i class="fa fa-plus-circle" style="margin-right: 5px"></i>
            <span>{{ "connect.templates.new_template" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="kt-portlet__body">
        <h6>{{ "connect.templates.customized_text" | translate }}:</h6>
        <div *ngFor="let attr of attributes">
          <h6> &lt;{{ attr.value }}&gt; : {{ 'connect.templates.' + attr.label | translate }}</h6>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="kt-widget1">
              <div class="kt-widget1__item" *ngFor="let template of templates">
                <div class="template-action-headers">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">{{ template.name }}</h3>
                  </div>
                  <span
                    class="kt-widget1__number kt-font-brand"
                    (click)="editTemplate(template.id)"
                  >
                    <a class="btn btn-elevate btn-circle btn-icon pointer mr-1"
                      ><i class="fas fa-pencil-alt text-dark"></i
                    ></a>
                    <a
                      class="btn delete-btn btn-icon pointer"
                      (click)="deleteTemplate(template.id)"
                      ><i class="fa fa-times"></i
                    ></a>
                  </span>
                </div>
                <div
                  class="template-body-content"
                  *ngIf="
                    editable_template === true && template.id == template_id
                  "
                >
                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <form class="inline-form">
                          <div class="form-group row">
                            <label class="col-md-3 pt-2">{{ tempName }}</label>
                            <input
                              type="text"
                              [(ngModel)]="templateEditable.name"
                              name="templateName"
                              class="form-control col-md-9"
                              aria-describedby="emailHelp"
                              placeholder="{{
                                'connect.templates.enter_template_name'
                                  | translate
                              }}"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="text-editor-box">
                      <app-editor
                        [content]="templateEditable.content"
                        (editorContentData)="getEditorContent($event)"
                      ></app-editor>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <button
                            class="btn save-btn mt-4 mr-3"
                            (click)="updateTemplateInfo()"
                          >
                            <span>{{
                              "connect.templates.save" | translate
                            }}</span>
                          </button>
                          <button
                            class="btn btn-outline-cancel mt-4"
                            (click)="cancelTemplateInfo()"
                          >
                            <span>{{
                              "connect.templates.cancel" | translate
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
