import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Optional,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
  FormGroup,
} from "@angular/forms";
import { MedicinesService } from "src/app/services/medicines.service";
import { PrescriptionsService } from "src/app/services/prescriptions.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { PatientDetailsComponent } from "src/app/components/medical-records/patient-details/patient-details.component";
import Swal from "sweetalert2";
import { Doctor } from "src/app/models/doctor";
import { DoctorService } from "src/app/services/doctor.service";
import { DoctorMedicines } from "src/app/models/doctor-medicines";

@Component({
  selector: "app-prescriptions",
  templateUrl: "./prescriptions.component.html",
  styleUrls: ["./prescriptions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PrescriptionsComponent implements OnInit {
  @Input() patient;
  @Input() medicalRecordId;
  @Input() prescription = null;
  @Input() edit = false;
  @Output() editChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  currentDoctor: Doctor;
  patientEmail: boolean = false;
  public prescriptionForm: UntypedFormGroup;
  public posologiesForm: UntypedFormArray;
  medicines: any;
  public medicineFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredMedicines: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  constructor(
    @Optional() private parent: PatientDetailsComponent,
    private formBuilder: UntypedFormBuilder,
    private medicinesService: MedicinesService,
    private prescriptionsService: PrescriptionsService,
    private errorHandler: ErrorsHandler,
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private doctorService: DoctorService
  ) {}

  ngOnInit() {
    this.medicinesService.get("").subscribe((data: DoctorMedicines[]) => {
      this.medicines = data;
      this.filteredMedicines.next(this.medicines.slice());
      this.medicineFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMedicines();
        });
    });

    if (this.patient.email) {
      this.patientEmail = true
    }
    this.posologiesForm = this.formBuilder.array(this.transform(this.prescription) || []);
    this.prescriptionForm = this.formBuilder.group({
      name: [this.patient.first_name + " " +this.patient.last_name],
      medicine: [null],
      dosage: [this.prescription?.dosage || null],
      send_to_patient: [null],
      posologies: this.posologiesForm
    }, { validators: this.posologiesNotEmptyValidator });
  }

  protected filterMedicines() {
    if (!this.medicines) {
      return;
    }
    let search = this.medicineFilterCtrl.value;
    if (!search) {
      this.filteredMedicines.next(this.medicines.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.medicinesService.get(search).subscribe((data: DoctorMedicines[]) => {
      this.medicines = data;
      this.filteredMedicines.next(
        this.medicines.filter(
          (bank) => bank.name.toLowerCase().indexOf(search) > -1
        )
      );
    });
  }
  addMedicine() {
    Swal.fire({
      input: "text",
      title: this.translate.instant("connect.globals.add_medicine"),
      text: this.translate.instant("connect.globals.medicine_info_message"),
      inputPlaceholder: this.translate.instant("connect.globals.place_holder"),
      showCancelButton: true,
    }).then((res) => {
      if (!res.dismiss && res.value !== "") {
        this.doctorService.addMedicine(res.value).subscribe();
      }
    });
  }

  transform(data)
  {
    if (!data) return [];
    const posologies = data.posologies.map((posologie) =>
    {
      return this.formBuilder.group({
        medicine_name: [posologie.medicine_name],
        medicine_id: [posologie.medicine_id],
        medicine_dosage: [posologie.medicine_dosage],
      })
    })
    return posologies;
  }

  createDosage(): UntypedFormGroup {
    var medicines = this.prescriptionForm.value.medicine.split("/medicine/");
    return this.formBuilder.group({
      medicine_name: [medicines[0]],
      medicine_id: [medicines[1]],
      medicine_dosage: [null],
    });
  }

  checkMedicine(): boolean {
    return this.prescriptionForm.value.medicine ? false : true;
  }

  addDosage() {
    this.posologiesForm.push(this.createDosage());
  }

  removeDosage(index) {
    this.posologiesForm.removeAt(index);
  }

  cancel()
  {
    this.editChange.emit(false);
  }

  savePrescription() {
    const prescriptionData = this.prescriptionForm.value;
    const medicineIds = prescriptionData.posologies.map((posologie) => posologie.medicine_id);
    const completePrescriptionData = { ...prescriptionData, medicine_ids: medicineIds };
    this.prescriptionsService[this.edit ? 'update' : 'create']?.(completePrescriptionData, this.edit ? this.prescription.id : this.medicalRecordId)
      .subscribe(
        data => {
          this.editChange.emit(false);
          this.snackBar.open(this.translate.instant('connect.prescriptions.' + this.edit ? 'prescription_updated' : 'prescription_created'))
          this.ngOnInit()
          return this.parent.getTimelineEvents(this.parent.listParams.pageNumber=0)
        },
        error => this.errorHandler.handleError(this.translate.instant('connect.globals.error_occured_message'))
      )
  }

  posologiesNotEmptyValidator(formGroup: FormGroup) {
    const posologies = formGroup.get('posologies') as FormArray;
    return posologies.length > 0 ? null : { posologiesEmpty: true };
  }
}
