import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class SmsLogsService {
  private SmsLogsUrl: string = "/sms_logs";

  constructor(private http: HttpWrapper) {}

  get(filters?: string) {
    return this.http.get(this.SmsLogsUrl + "?" + filters);
  }
}
