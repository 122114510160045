<div class="mob-push-top invoice-container">
  <div class="col-12">
    <div
      class="kt-portlet setting-data dr-invoices kt-portlet--height-fluid"
      *ngIf="invoiceDetail"
    >
      <div class="kt-portlet__body">
        <div class="row">
          <div class="col-md-6 col-sm-5 col-6">
            <div class="order-box">
              <h1>
                <img
                  alt="Logo"
                  src="../../../assets/images/dabadoc-logo-blue.png"
                />
              </h1>
            </div>
          </div>
          <div class="col-md-6 col-sm-7 col-6 text-right">
            <div class="doctor-info">
              <h1>{{ "connect.invoices_details.bill" | translate }}</h1>
              <h1>
                <small>N˚ {{ invoiceDetail.number }}</small>
              </h1>
              <h4>{{ invoiceDetail.created_at | date : "yyyy-MM-dd" }}</h4>
            </div>
          </div>
        </div>
        <div class="patient_info_box" *ngIf="invoiceDetail">
          <div class="row">
            <div class="col-md-5">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4>
                    {{ "connect.invoices_details.recipient" | translate }}
                  </h4>
                </div>
                <div class="panel-body">
                  {{ invoiceDetail.first_name }}
                  {{ invoiceDetail.last_name }}
                  <br />
                  {{ invoiceDetail.email }}
                  <br />
                  {{ invoiceDetail.phone_number }}
                  <br />
                  {{ invoiceDetail.address }}
                  <br />
                  {{ invoiceDetail.ice }}
                </div>
              </div>
            </div>
            <div class="col-md-3 offset-md-4">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4>
                    {{ "connect.invoices_details.status" | translate }}
                  </h4>
                </div>
                <div class="panel-body">
                  <div class="invoice-state">
                    {{ "connect.invoices." + invoiceDetail.state | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="col-md-5 width-60">
                  <h4>
                    {{ "connect.invoices_details.description" | translate }}
                  </h4>
                </th>
                <th class="col-md-2 width-40">
                  <h4>
                    {{ "connect.invoices_details.total_price" | translate }}
                  </h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    "connect.invoices_details.non_binding_subscription"
                      | translate
                  }}:
                  {{ invoiceDetail.plan_name }}
                </td>
                <td class="text-right">
                  {{ invoiceDetail.total_amount }}
                  DH
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row text-right">
            <div class="col-md-2 col-lg-2 col-6 offset-md-8 offset-lg-8">
              <p>
                <strong>
                  {{ "connect.invoices_details.total_excluding" | translate }} :
                  <br />
                  {{ "connect.invoices_details.vat" | translate }} :
                  <br />
                  {{
                    "connect.invoices_details.total_including_vat" | translate
                  }}
                  :
                  <br />
                </strong>
              </p>
            </div>
            <div class="col-md-2 col-lg-2 col-6">
              <strong>
                {{ invoiceDetail.total_amount / 1.2 | number : "1.2-2" }}
                <br />
                {{
                  invoiceDetail.total_amount - invoiceDetail.total_amount / 1.2
                    | number : "1.2-2"
                }}
                <br />
                {{ invoiceDetail.total_amount | number : "1.2-2" }}
                <br />
              </strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              {{ "connect.invoices_details.invoice_is_approved" | translate }} :
              <b> {{ numInWords[0] }} Dirhams </b>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <div id="cachet">
                <img width="250" src="../../../assets/images/stamp.png" />
              </div>
            </div>
          </div>
          <footer class="footer" style="font-size: 0.8em">
            <div class="container text-center">
              <b>DabaDoc</b>
              <br />
              {{ "connect.invoices_details.company_description" | translate }}
              <br />
              {{ "connect.invoices_details.headquarters" | translate }}
              <p></p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</div>
