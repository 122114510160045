import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { environment as appConfig } from "../../environments/environment";

/** Adds DabaDoc authentication headers to all requests. */
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var ca = document.cookie.split(";");
    var cookie_doctor_email = "doctor_email=";
    var cookie_doctor_token = "doctor_token=";

    for (let i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(cookie_doctor_email) == 0) {
        var doctor_email = c.substring(cookie_doctor_email.length, c.length);
      }
      if (c.indexOf(cookie_doctor_token) == 0) {
        var doctor_token = c.substring(cookie_doctor_token.length, c.length);
      }
    }
    localStorage.setItem("doctor_token", doctor_token);
    const authReq = req.clone({
      headers: new HttpHeaders({
        "X-Doctor-Email": decodeURIComponent(doctor_email),
        "X-Doctor-Token": doctor_token
      }),
    });
    return next.handle(authReq);
  }
}
