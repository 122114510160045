<div class="kt-aside--enabled kt-aside--fixed kt-aside--minimiz">
  <div
    class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
    [ngClass]="[
      this.event ? 'aside-custom' : '',
      this.toggleData ? 'kt-aside--on' : ''
    ]"
    id="kt_aside"
    (mouseover)="changeClass($event)"
    (mouseout)="changeClass($event)"
  >
    <button
      class="kt-aside-close"
      id="kt_aside_close_btn"
      (click)="changeSidebarClass(false)"
    >
      <i class="fa fa-times"></i>
    </button>
    <div
      class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
      id="kt_aside_menu_wrapper"
    >
      <div
        id="kt_aside_menu"
        class="kt-aside-menu kt-scroll ps"
        data-ktmenu-vertical="1"
        data-ktmenu-scroll="1"
        data-ktmenu-dropdown-timeout="500"
      >
        <ul class="kt-menu__nav">
          <li
            *ngFor="let item of asideMenuItems; let i = index"
            class="kt-menu__item kt-menu__item--submenu"
            (click)="toggleMenu(i)"
            [ngClass]="
              isActive === true && currentIndex === i
                ? 'kt-menu__item--open'
                : ''
            "
            data-ktmenu-submenu-toggle="hover"
          >
            <a class="kt-menu__link">
              <i class="kt-menu__link-icon {{ item.icon }}"></i>
              <span class="kt-menu__link-text">{{
                "connect.side_menu." + item.name | translate
              }}</span>
              <i class="kt-menu__ver-arrow la la-angle-right"></i>
            </a>
            <div class="kt-menu__submenu">
              <span class="kt-menu__arrow"></span>
              <ul
                class="kt-menu__subnav"
                *ngFor="let subMenu of item.subMenusItems"
              >
                <li
                  class="kt-menu__item kt-menu__item--parent"
                  aria-haspopup="true"
                  *ngIf="showSection(subMenu)"
                >
                  <span class="kt-menu__link"><span class="kt-menu__link-text">Actions</span></span>
                </li>
                <li
                  class="kt-menu__item kt-menu__item--submenu"
                  aria-haspopup="true"
                  data-ktmenu-submenu-toggle="hover"
                  *ngIf="showSection(subMenu)"
                >
                  <a
                    #myDiv
                    (click)="navigateToLink(subMenu.link)"
                    class="kt-menu__link kt-menu__toggle"
                    *ngIf="
                      subMenu.name != 'inbox' && subMenu.name != 'templates'
                    "
                  >
                    <i class="kt-menu__link-bullet kt-menu__link-bullet--line">
                      <span></span>
                    </i>
                    <span class="kt-menu__link-text">
                      {{ "connect.side_menu." + subMenu.name | translate }}
                    </span>
                  </a>
                  <a
                    [href]="subMenu.link"
                    routerLinkActive="active"
                    class="kt-menu__link kt-menu__toggle"
                    *ngIf="
                      subMenu.name == 'inbox' && subMenu.name != 'templates'
                    "
                  >
                    <i class="kt-menu__link-bullet kt-menu__link-bullet--line">
                      <span></span>
                    </i>
                    <span class="kt-menu__link-text">
                      {{ "connect.side_menu." + subMenu.name | translate }}
                    </span>
                  </a>
                  <a
                    (click)="navigateToLink(subMenu.link)"
                    routerLinkActive="active"
                    class="kt-menu__link kt-menu__toggle"
                    *ngIf="
                      subMenu.name != 'inbox' &&
                      subMenu.name == 'templates' &&
                      hasConnectPlusAccess
                    "
                  >
                    <i class="kt-menu__link-bullet kt-menu__link-bullet--line">
                      <span></span>
                    </i>
                    <span class="kt-menu__link-text">
                      {{ "connect.side_menu." + subMenu.name | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
