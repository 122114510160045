import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Doctor } from "src/app/models/doctor";
import { DoctorService } from "src/app/services/doctor.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";

@Component({
  selector: "doctor-prescription-header",
  templateUrl: "./doctor-prescription-header.component.html",
  styleUrls: ["./doctor-prescription-header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorPrescriptionHeaderComponent implements OnInit {
  editorContent: string = "";

  constructor(
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private errorHandler: ErrorsHandler
  ) {}

  ngOnInit() {
    this.getDoctorInfo();
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.editorContent = currentDoctor.prescription_title;
      }
    });
  }

  SaveDetails() {
    this.doctorService.updatePrescriptionTitle(this.editorContent).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant(
            "connect.booking_availability.appointment_category_updated"
          )
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  getEditorContent(evt) {
    this.editorContent = evt;
  }
}
