<div
  class="patientTimeline"
  data-infinite-scroll
  debounce
  [infiniteScrollDistance]="1"
  [infiniteScrollUpDistance]="2"
  [infiniteScrollThrottle]="15"
  (scrolled)="onScrollDown()"
>
  <div class="kt-portlet" *ngIf="doctorService.current">
    <div class="kt-portlet__body">
      <div class="kt-notes">
        <div
          class="kt-notes__items"
          *ngFor="let event of parent.patientEvents; let i = index"
        >
          <div
            class="kt-notes__item"
            *ngIf="event.type === 'medical_record_note'"
          >
            <div class="kt-notes__media">
              <span class="kt-notes__icon">
                <i class="fas fa-briefcase-medical"></i
              ></span>
            </div>
            <div class="kt-notes__content">
              <div class="kt-notes__section">
                <div class="kt-notes__info event-info">
                  <span class="kt-notes__desc">
                    {{ event.created_at | customDate }},
                    {{ event.created_at | customTime }}
                  </span>
                </div>
                <div class="kt-notes__info">
                  <span class="kt-notes__desc"
                    >({{ "connect.medical_events.last_modified" | translate }}:
                    {{ event.updated_at | customDate }},
                    {{ event.updated_at | customTime }} )
                  </span>
                </div>
                <div>
                  <span class="kt-notes__desc"></span>
                </div>
                <div class="kt-notes__dropdown">
                  <a
                    [matMenuTriggerFor]="menu"
                    class="btn btn-sm btn-icon-md btn-icon pointer"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="fa fa-ellipsis-h kt-font-brand"></i>
                  </a>
                  <mat-menu #menu="matMenu">
                    <button (click)="updateSingleNote(i)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>{{
                        "connect.medical_events.modify" | translate
                      }}</span>
                    </button>
                    <button (click)="printNote(event.id)" mat-menu-item>
                      <mat-icon>print</mat-icon>
                      <span>{{
                        "connect.medical_events.print" | translate
                      }}</span>
                    </button>
                    <button (click)="deleteNote(event.id)" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>{{
                        "connect.medical_events.delete" | translate
                      }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <span
                class="kt-notes__body doctor-info"
                style="overflow-wrap: break-word"
                *ngIf="event"
              >
                <app-editor
                  *ngIf="!editMode"
                  [content]="event.content"
                  [disabled]="disabled"
                ></app-editor>
                <span
                  *ngIf="editMode === true && noteIndex === i"
                  [ngClass]="editMode === true ? 'active' : ''"
                >
                  <app-note
                    [noteContent]="event.content"
                    [patientDetails]="patient"
                    [noteId]="event.id"
                    (editorContentData)="getEditorContent(event.id, $event)"
                  ></app-note>
                  <div class="attachment-btn attachment-btn-right" *ngIf="!isToggled">
                    <button (click)="uppyOpenDialog()" color="primary" class="btn btn-outline-neutral ml-auto mt-3">
                      <i class="fa fa-paperclip mr-2"></i>
                        {{ 'connect.patient_details.attachments' | translate }}
                    </button>
                  </div>
                </span>
                <div class="send-all-div" *ngIf="event.attachments.length > 1">
                  <button
                    class="btn editorBtn max-100 save-btn mt-3"
                    (click)="openSharingDialog(event.attachments)"
                  >
                    {{ "connect.medical_events.share_all" | translate }}
                  </button>
                  <button class="btn editorBtn max-100 delete-btn-attachments mt-3 ml-2" (click)="openDeleteDialog(event.attachments, event.id, i)">{{ 'connect.medical_events.delete_all' | translate }}</button>
                </div>
                <div class="note-images">
                  <div
                    *ngFor="let attachments of event.attachments"
                    class="note-image-box"
                  >
                    <app-attachment-files
                      [event]="attachments"
                    ></app-attachment-files>
                    <a
                      class="medical-record-button mat-button mat-button-base btn btn-block mt-2"
                      href="{{ attachments.url }}"
                      target="_blank"
                      >{{ "connect.medical_events.open" | translate }}</a
                    >
                    <button class="width-100 btn editorBtn max-100 delete-btn-attachments mt-3" (click)="openDeleteDialog(attachments, event.id, i)">{{ 'connect.medical_events.delete' | translate }}</button>
                    <button
                      class="width-100 btn editorBtn max-100 save-btn mt-3"
                      (click)="openSharingDialog(attachments)"
                    >
                      {{ "connect.medical_events.share" | translate }}
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="kt-notes__item" *ngIf="event.type === 'prescription'">
            <div class="kt-notes__media">
              <span class="kt-notes__icon">
                <i class="fas fa-file-alt"></i
              ></span>
            </div>
            <div class="kt-notes__content">
              <div class="kt-notes__section">
                <div class="kt-notes__info event-info">
                  <span class="kt-notes__desc">
                    {{ event.created_at | customDate }},
                    {{ event.created_at | customTime }}
                  </span>
                </div>
                <div class="kt-notes__info">
                  <span class="kt-notes__desc"
                    >({{ "connect.medical_events.last_modified" | translate }}:
                    {{ event.updated_at | customDate }},
                    {{ event.updated_at | customTime }} )
                  </span>
                </div>
                <div class="kt-notes__dropdown">
                  <a [matMenuTriggerFor]="menu" class="btn btn-sm btn-icon-md btn-icon pointer" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-h kt-font-brand"></i></a>
                  <mat-menu #menu="matMenu">
                    <button (click)="updatePrescription(event, i)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>{{ 'connect.medical_events.modify' | translate }}</span>
                    </button>
                    <button (click)="showMedicineDetails(event.id)" mat-menu-item>
                      <mat-icon>print</mat-icon>
                      <span>{{ 'connect.medical_events.print' | translate }}</span>
                    </button>
                    <button (click)="deletePrescription(event, i)" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>{{ 'connect.medical_events.delete' | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div>
                <div *ngIf="!editMode" class="preview-prescription"> 
                  <span class="kt-notes__body" style="overflow-wrap: break-word;" *ngFor="let posologie of event.posologies;">
                    <div class="medicine-title">
                      <h3>{{ posologie.medicine_name}}</h3>
                      <p>{{ posologie.medicine_dosage }}</p>
                    </div>
                  </span>
                  <div class="medicine-title">
                    <h3>{{ 'connect.medical_events.other_instructions' | translate }}</h3>
                    <p>{{ event.dosage}}</p>
                  </div>
                </div>
                <div *ngIf="(editMode == true && noteIndex == i)" [ngClass]="editMode === true ? 'active' : ''" class="edit-prescription">
                  <app-prescriptions [patient]="patient" [medicalRecordId]="MedicalRecordID" [prescription]="event" [(edit)]="editMode" (editChange)="editMode = $event"></app-prescriptions>
                </div>
            </div>
          </div>
          <div class="kt-notes__item" *ngIf="event.type === 'booking_slot'">
            <div class="kt-notes__media">
              <span class="kt-notes__icon">
                <i class="fa fa-calendar"></i
              ></span>
            </div>
            <div class="kt-notes__content">
              <div class="kt-notes__section">
                <div>
                  <span class="kt-notes__desc"></span>
                </div>
              </div>
              <span class="kt-notes__body" style="overflow-wrap: break-word">
                <p>
                  {{ "connect.medical_events.appointment" | translate }}:
                  {{ event.starts_at | customDate }},
                  {{ event.starts_at | customTime }}<br />
                  {{ "connect.medical_events.booked_on" | translate }}:
                  {{ event.created_at | customDate }},
                  {{ event.created_at | customTime }}
                </p>
                <button
                  *ngIf="event.reason_of_visit"
                  class="multi-clr-btn"
                  [style.backgroundColor]="event.color"
                >
                  {{ event.reason_of_visit }}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="no-events" *ngIf="isEmptyObject(parent.patientEvents)">
          <h2 class="no-records">
            {{ "connect.medical_events.no_event_record_yet" | translate }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
