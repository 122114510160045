<div class="col-md-4 col-xs-12">
  <h1 class="ratinghead">{{ review.created_at | date : "yyyy-MM-dd" }}</h1>
  <span
    >{{ "connect.doctor_comments.by" | translate }}
    {{ review.user_first_name }} {{ review.user_last_name }}</span
  >
</div>
<div class="col-md-4 col-6">
  <div class="textbold" *ngIf="review.wait_time">
    {{ "connect.doctor_comments.punctuality" | translate }}
  </div>
  <span class="gold-star" *ngFor="let star of [].constructor(review.wait_time)">
    <i class="fa fa-star review-stars"></i>
  </span>
</div>
<div *ngIf="review.global_rating" class="col-md-3 col-6">
  <div class="textbold">
    {{ "connect.doctor_comments.global_evaluation" | translate }}
  </div>
  <span
    class="gold-star"
    *ngFor="let star of [].constructor(review.global_rating)"
  >
    <i class="fa fa-star review-stars"></i>
  </span>
</div>
<div
  class="col-md-1 col-1"
  *ngIf="review.comment && !review.doctor_comment && comments"
>
  <button
    (click)="showCommentDetails(review.id)"
    class="btn btn-success btn-elevate btn-pill setting-btn"
  >
    <i class="fas fa-comment-dots"></i>
  </button>
</div>
<div class="col-12">
  <span class="comment">{{ review.comment }}</span>
</div>
<div class="col-1 mt-4"></div>
