import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SlotType } from "src/app/enums/SlotType.enum";
import { BookingSlotType } from "src/app/models/booking-slot-type";
import { AppointementCategorieFormDialogComponent } from "../appointement-categorie-form-dialog/appointement-categorie-form-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-booking-availability",
  templateUrl: "./booking-availability.component.html",
  styleUrls: ["./booking-availability.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BookingAvailabilityComponent implements OnInit {
  doctorBookingTypes: BookingSlotType[] = [];
  bookingAvailabilities: BookingSlotType[] = [];
  bookingType: any = {};
  isEditable: boolean = false;
  tempName: string = "";
  templateName: string = "";
  isBookingTypes: boolean = true;
  isValidate: boolean = true;
  nameIsNotValid: boolean = false;

  constructor(
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private errorHandler: ErrorsHandler,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getDoctorBookingsTypes();
  }

  getDoctorBookingsTypes() {
    this.isEditable = false;
    this.doctorService
      .getBookingsTypes()
      .subscribe((types: BookingSlotType[]) => {
        this.doctorBookingTypes = types;
        this.bookingAvailabilities = this.doctorBookingTypes.slice(0);
        if (types.length == 0) {
          this.isBookingTypes = false;
        }
      });
  }

  deleteAppointmentCategory(element){

    if (!window.confirm(this.translate.instant("connect.booking_availability.are_you_sure"))) return;
    if(element){
      let payload = [{...element, _destroy: true}]
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.booking_availability.appointment_category_deleted")
        ), this.getDoctorBookingsTypes();
      });;
    }
  }

  editAppointmentCategory(element){
    let dialogRef = this.dialog.open(AppointementCategorieFormDialogComponent, {
      data:{
        actionType: 'edit',
        element: element
      },
      width: '560px',
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((result : BookingSlotType)  => {
      if(!result) return;
      let payload = [result];
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.booking_availability.appointment_category_updated")
        ), this.getDoctorBookingsTypes();
      });;
    })
  }

  addAppointmentCategory(){
    let dialogRef = this.dialog.open(AppointementCategorieFormDialogComponent, {
      data:{
        actionType: 'create',
        element: null
      },
      width: '560px',
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((result : BookingSlotType)  => {
      if(!result) return;
      delete result.id
      let payload = [result]
      this.applyUpdatesToEntities(payload).then(()=>{
        this.snackBar.open(
          this.translate.instant("connect.booking_availability.appointment_category_created")
        ), this.getDoctorBookingsTypes();
      });
    })
  }

  applyUpdatesToEntities(entities){
    return new Promise((resolve, reject) => {
      this.doctorService.updateBookingstypes(entities).subscribe(
        (data) => {
          resolve(data)
        },
        (error) =>{
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
            )
          reject(error)
        }
      );
    })
  }
  
  resetBookingType() {
    this.isEditable = false;
    this.bookingType = "";
  }
}
