<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css"
/>
<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>
<link
  href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap"
  rel="stylesheet"
/>
<link
  href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"
  rel="stylesheet"
/>

<!-- Quill editor stylesheets -->
<link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
<link href="https://cdn.quilljs.com/1.3.6/quill.bubble.css" rel="stylesheet" />

<div class="loading-overlay" *ngIf="isLoading">
  <mat-spinner style="position: absolute; top: 30%; left: 45%"></mat-spinner>
</div>
<app-header
  [currentDoctor]="currentDoctor$ | async"
  *ngIf="isHeaderShow$ | async as isHeaderShow"
></app-header>

<app-spinner-input
  style="z-index: 9999; position: absolute; margin-top: 15%; margin-left: 42%"
  *ngIf="isSpinnerShow$ | async as isSpinnerShow"
></app-spinner-input>
<router-outlet></router-outlet>
<app-bottom-navbar
  *ngIf="isHeaderShow$ | async as isHeaderShow"
></app-bottom-navbar>
<div *ngIf="this.currentDoctor?.is_premium" class="example-flex-container positioning" [class]="icondAddColor">
  <div class="example-button-container" (click)="openBookingModal($event)">
    <button
      mat-icon-button
      class="text-white"
      aria-label="Example icon button with a home icon"
    >
      <mat-icon fontSize="large" *ngIf="!isDialogOpen">add</mat-icon>
      <mat-icon fontSize="large" *ngIf="isDialogOpen">close</mat-icon>
    </button>
  </div>
</div>
