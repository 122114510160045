import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { PrescriptionsService } from "src/app/services/prescriptions.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Prescription } from "src/app/models/prescription";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import {
  trigger,
  transition,
  style,
  sequence,
  animate,
  state,
} from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { PatientLabel } from "src/app/models/patient-label";
import { Doctor } from "src/app/models/doctor";

@Component({
  selector: "app-prescription-table",
  templateUrl: "./prescription-table.component.html",
  styleUrls: ["./prescription-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("rowsAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(600)]),
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class PrescriptionTableComponent implements OnInit {
  displayedColumns: string[] = [
    "patient_name",
    "created_at",
    "medication",
    "actions",
  ];
  dataSource;
  currentDoctor: Doctor;
  @Input() name: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private prescriptionsService: PrescriptionsService,
    private router: Router,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler
  ) {}

  getPrescriptionRecords() {
    this.prescriptionsService.get().subscribe(
      (data: Prescription[]) => {
        this.dataSource = new MatTableDataSource(data.reverse());
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  ngOnInit() {
    this.getPrescriptionRecords();
  }

  showMedicineDetails(prescription_id) {
    return this.router.navigate(["/prescriptions", prescription_id]);
  }

  showPrescriptionHeader() {
    return this.router.navigate(["/prescriptions/header"]);
  }
}
