<div *ngIf="profileForm">
  <div class="row jsutify-content-center">
    <div class="col-12">
      <div
        class="kt-portlet setting-data kt-portlet--height-fluid edit-profile-form"
      >
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title card-title">
              {{ "connect.profile.edit_profile" | translate }}
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <form [formGroup]="profileForm" (ngSubmit)="SaveDetails()">
            <div class="row justify-content-center no-gutters">
              <div
                class="col-lg-11 col-md-10 col-sm-9 order-2 order-md-1 order-sm-1 order-lg-1"
              >
                <div class="edit-profile-wrapper">
                  <mat-tab-group>
                    <mat-tab
                      [label]="currentDoctor.clinic ? ('connect.profile.your_clinic' | translate) : ('connect.profile.your_cabinate' | translate)"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.cabinet_number_street'
                                    | translate
                                }}"
                                formControlName="address"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.building' | translate
                                }}"
                                formControlName="address_additional_1"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.district' | translate
                                }}"
                                formControlName="address_neighborhood"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.indications' | translate
                                }}"
                                formControlName="address_additional_2"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.postal_code' | translate
                                }}"
                                formControlName="postal_code"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select
                                formControlName="city_id"
                                placeholder="{{
                                  'connect.profile.city' | translate
                                }}"
                                [(value)]="currentDoctor.city_id"
                              >
                                <mat-option
                                  *ngFor="let city of cities"
                                  [value]="city.id"
                                >
                                  {{ city.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field>
                            <input
                              matInput
                              placeholder="{{
                                'connect.profile.website' | translate
                              }}"
                              formControlName="website"
                              (ngModelChange)="validate($event)"
                            />
                          </mat-form-field>
                          <div class="error-msg" *ngIf="isValidate">
                            <span class="error-msg-arrow"></span>
                            {{ "connect.profile.must_start_with" | translate }}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group edit-profile-phone">
                            <label class="mb-2"
                              >*{{
                                "connect.profile.telephone_number_other"
                                  | translate
                              }}</label
                            >
                            <app-phone-number
                              controlName="phone_number"
                            ></app-phone-number>
                            <mat-hint
                              *ngIf="profileForm.get('phone_number').errors"
                              class="phone-error"
                            >
                              <mat-error>{{
                                "connect.user_profiles.errors.phone_number"
                                  | translate
                              }}</mat-error>
                            </mat-hint>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group edit-profile-phone">
                            <label class="mb-2">{{
                              "connect.profile.telephone_number_office"
                                | translate
                            }}</label>
                            <app-phone-number
                              controlName="phone_number_secondary"
                            ></app-phone-number>
                            <mat-hint
                              *ngIf="
                                profileForm.get('phone_number_secondary').errors
                              "
                              class="phone-error"
                            >
                              <mat-error>{{
                                "connect.user_profiles.errors.phone_number"
                                  | translate
                              }}</mat-error>
                            </mat-hint>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group edit-profile-phone">
                            <label class="mb-2"
                              >*{{
                                "connect.profile.mobile_number" | translate
                              }}</label
                            >
                            <app-phone-number
                              controlName="cellphone_number"
                            ></app-phone-number>
                            <mat-hint
                              *ngIf="profileForm.get('cellphone_number').errors"
                              class="phone-error"
                            >
                              <mat-error>{{
                                "connect.user_profiles.errors.phone_number"
                                  | translate
                              }}</mat-error>
                            </mat-hint>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="isScriptLoaded">
                        <div class="col-md-6">
                          <div class="google-map-container">
                            <app-map [currentDoctor]="currentDoctor"></app-map>
                            <div class="direction-message">
                              <strong *ngIf="!clinic">{{
                                "connect.profile.your_cabinate" | translate
                              }}</strong>
                              <strong *ngIf="clinic">{{
                                "connect.profile.your_clinic" | translate
                              }}</strong>
                              <p>
                                {{ "connect.profile.zoom_in" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="{{ 'connect.profile.your_account' | translate }}">
                      <div class="d-flex flex-wrap">
                        <div class="col-md-6" *ngIf="!currentDoctor.clinic">
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select
                                class="full-width"
                                placeholder="{{
                                  'connect.profile.title' | translate
                                }}"
                                formControlName="title"
                              >
                                <mat-option
                                  *ngFor="let title of titles"
                                  value="{{ title.value }}"
                                  >{{ title.viewValue }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select
                                class="full-width"
                                placeholder="{{
                                  'connect.profile.sex' | translate
                                }}"
                                formControlName="gender"
                              >
                                <mat-option
                                  *ngFor="let gender of genders"
                                  value="{{ gender.value }}"
                                  >{{
                                    "connect.profile." + gender.viewValue
                                      | translate
                                  }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6" *ngIf="!currentDoctor.clinic">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.first_name' | translate
                                }}"
                                formControlName="first_name"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="!currentDoctor.clinic">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.name' | translate
                                }}"
                                formControlName="last_name"
                              />
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6 d-flex flex-column">
                          <mat-form-field>
                            <input matInput placeholder="{{'connect.profile.specialities' | translate }}" formControlName="specialities"/>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select
                                multiple
                                class="full-width"
                                formControlName="language_ids"
                                placeholder="{{
                                  'connect.profile.spoken_languages' | translate
                                }}"
                              >
                                <mat-option
                                  *ngFor="let language of languages"
                                  value="{{ language._id }}"
                                  >{{ language.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.first_year_practice'
                                    | translate
                                }}"
                                formControlName="started_practice"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.common_company_identifier'
                                    | translate
                                }}"
                                formControlName="ice"
                              />
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.password' | translate
                                }}"
                                formControlName="password"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.password_confirmation'
                                    | translate
                                }}"
                                formControlName="password_confirmation"
                              />
                            </mat-form-field>
                          </div>
                        </div>

                        <div
                          class="col-md-6"
                          *ngIf="
                            currentDoctor &&
                            currentDoctor.video_consultation_enabled
                          "
                        >
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.price_video_consultation'
                                    | translate
                                }}"
                                formControlName="video_consultation_price"
                              />
                            </mat-form-field>
                            <small>{{
                              "connect.profile.rate_visible" | translate
                            }}</small>
                          </div>
                        </div>
                        <div
                          class="col-md-6"
                          *ngIf="currentDoctor.country == 'MA'"
                        >
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="{{
                                  'connect.profile.rib' | translate
                                }}"
                                formControlName="rib"
                                disabled
                              />
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{
                              "connect.profile.diploma_training_aid" | translate
                            }}</label>
                            <mat-form-field>
                              <textarea
                                matInput
                                placeholder=""
                                formControlName="degrees"
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{
                              "connect.profile.help_cabinet_presentation"
                                | translate
                            }}</label>
                            <mat-form-field>
                              <textarea
                                matInput
                                placeholder=""
                                formControlName="presentation"
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{
                              "connect.profile.home_visit_info" | translate
                            }}</label>
                            <mat-form-field>
                              <textarea
                                matInput
                                placeholder=""
                                formControlName="home_visit_info"
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="عربي" *ngIf="isArabicCountry">
                      <div class="d-flex flex-wrap" dir="rtl">
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="ﺭﻗﻢ ﻭﺷﺎﺭﻉ اﻟﻌﻴﺎﺩﺓ"
                                formControlName="address_ar"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="اﻟﺸﻘﺔ. / اﻟﺒﻨﺎﻳﺔ"
                                formControlName="address_additional_1_ar"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="اﻟﺤﻲ"
                                formControlName="address_neighborhood_ar"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <input
                                matInput
                                placeholder="ﺇﺷﺎﺭاﺕ"
                                formControlName="address_additional_2_ar"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      
                        <div class="col-md-6" *ngIf="!currentDoctor.clinic">
                          <div class="form-group">
                            <mat-form-field>
                              <label>: اﻹﺳﻢ</label>
                              <input matInput formControlName="first_name_ar" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="!currentDoctor.clinic">
                          <div class="form-group">
                            <mat-form-field>
                              <label>: اﻟﻨﺴﺐ</label>
                              <input matInput formControlName="last_name_ar" />
                            </mat-form-field>
                          </div>
                        </div>
                     
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <label>: اﻟﺪﺑﻠﻮﻡ ﻭاﻟﺘﻜﻮﻳﻦ</label>
                              <textarea
                                matInput
                                formControlName="degrees_ar"
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <mat-form-field>
                              <label>: ﺗﻘﺪﻳﻢ اﻟﻌﻴﺎﺩﺓ</label>
                              <textarea
                                matInput
                                formControlName="presentation_ar"
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab
                      label="{{ 'connect.profile.my_pictures' | translate }}"
                    >
                      <div class="rows">
                        <div class="grid">
                          <div
                            class="image position-relative"
                            *ngFor="let image of currentDoctor.images"
                          >
                            <button
                              (click)="
                                deletePhoto(image.public_id || image._id)
                              "
                              class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"
                            >
                              <span class="visually-hidden">
                                <i class="fa fa-remove"></i>
                              </span>
                            </button>
                            <img src="{{ image.image }}" />
                          </div>
                        </div>
                        <div
                          class="profile_pic"
                          style="background-image: url({{profilePic}}); background-size: cover; background-position: center;"
                        >
                          <label class="custom-file-upload">
                            <input
                              type="file"
                              accept="image/*"
                              (change)="updateProfilePicture($event)"
                            />
                            <i
                              class="fa fa-camera"
                              *ngIf="!profilePictureIsUploading"
                            ></i>
                            <mat-spinner
                              *ngIf="profilePictureIsUploading"
                            ></mat-spinner>
                          </label>
                        </div>
                      </div>

                      <br />
                      <div class="row dndZone">
                        <div class="drop-it-here">
                          <i class="fa fa-upload" *ngIf="!isUploading"></i>
                          <i class="fa fa-spinner spin" *ngIf="isUploading"></i>
                        </div>
                        <input
                          type="file"
                          multiple="multiple"
                          accept="image/*"
                          (change)="uploadImages($event)"
                        />
                      </div>
                    </mat-tab>
                    <mat-tab
                      [label]="currentDoctor.clinic ? ('connect.profile.clinics_places_ifClinic' | translate) : ('connect.profile.your_cabinate' | translate)"
                      *ngIf="!currentDoctor.clinic"
                    >
                      <div class="clinic-wrapper">
                        <div class="clinic-wrapper-left">
                          <div>
                            <label>{{
                              "connect.profile.select_confirm_clinic"
                                | translate
                            }}</label>
                            <mat-form-field class="width-100">
                              <mat-select
                                formControlName="establishment_ids"
                                #singleSelect
                              >
                                <mat-option>
                                  <ngx-mat-select-search
                                    [formControl]="establishmentFilterCtrl"
                                    placeholderLabel="{{
                                      'connect.profile.enter_a_name' | translate
                                    }}"
                                    noEntriesFoundLabel="{{
                                      'connect.profile.no_matching_found'
                                        | translate
                                    }}"
                                  ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                  *ngFor="
                                    let establishment of filteredEstablishments
                                      | async
                                  "
                                  value="{{
                                    establishment.full_name
                                  }}/establishment/{{ establishment.id }}"
                                >
                                  {{ establishment.full_name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <button
                            class="add-btn btn"
                            type="button"
                            (click)="addEstablishment()"
                          >
                            {{
                              "connect.prescriptions.add_prescription"
                                | translate
                            }}
                          </button>
                        </div>
                        <div class="clinic-wrapper-right">
                          <div class="consultation-places">
                            <ul>
                              <li
                                *ngFor="
                                  let establishment of doctorEstablishments;
                                  let i = index
                                "
                              >
                                <div class="list-title">
                                  {{ establishment.full_name }}
                                </div>
                                <div class="list-content">
                                  <div class="list-bages">
                                    <span
                                      class="fas fa-times"
                                      (click)="deleteEstablishment(i)"
                                    ></span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="bottom-grey">
                        {{
                          "connect.profile.clinic_place_consultation"
                            | translate
                        }}
                        contact@dabadoc.com
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
              <div
                class="col-lg-1 col-md-2 col-sm-3 order-1 order-md-2 order-sm-2 order-lg-2"
              >
                <div class="save-steps">
                  <button
                    class="btn save-btn btn-warning full-btn"
                    [disabled]="!profileForm.valid || isValidate"
                  >
                    {{ "connect.profile.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
