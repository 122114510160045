<div mat-dialog-content class="video-consult-modal">
  <div class="booking-modal-header modal-header">
    <div>
      <h4>{{ "connect.doctor_settings.modify_price" | translate }}</h4>
    </div>
    <div>
      <button type="button" class="close" (click)="onNoClick()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="videoConsultPriceForm" [formGroup]="videoConsultPriceForm">
      <div class="form-group mb-3">
        <label class="col-form-label hide-label">{{
          "connect.profile.price_video_consultation" | translate
        }}</label>
        <input
          formControlName="video_consultation_price"
          class="form-control"
        />
      </div>
    </div>
    <div class="form-group mt-4 mb-0 footer text-right" mat-dialog-actions>
      <button
        class="btn mr-3 save-btn"
        (click)="SaveDetails()"
        type="button"
        [disabled]="!videoConsultPriceForm.valid"
      >
        {{ "connect.user_profiles.edit" | translate }}
      </button>
    </div>
  </div>
</div>
