import { Component, Optional, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DoctorTemplateDialogComponent } from "../../components/doctor-template-dialog/doctor-template-dialog.component";
import Swal from "sweetalert2";
import { TemplatesService } from "src/app/services/templates.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Template } from "src/app/models/doctorTemplate.model";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  styleUrls: ["./template.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateComponent implements OnInit {
  public attributes = [
    { label: 'first_name', value: 'first_name' },
    { label: 'last_name', value: 'last_name' },
    { label: 'name', value: 'name' },
    { label: 'email', value: 'email' },
    { label: 'reference_id', value: 'reference_id' },
    { label: 'phone_number', value: 'phone_number' },
    { label: 'date', value: 'date' },
    { label: 'birthday', value: 'birthday' },
    { label: 'doctor_name', value: 'doctor_name' },
    { label: 'doctor_city', value: 'doctor_city' },
    { label: 'weight', value: 'weight' },
    { label: 'height', value: 'height' },
    { label: 'blood_type', value: 'blood_type' },
    { label: 'cin', value: 'cin' },
    { label: 'allergies', value: 'allergies' },
    { label: 'insurance', value: 'insurance' },
    { label: 'referred_by', value: 'referred_by' },
    { label: 'address', value: 'address' },
    { label: 'country', value: 'country' },
    { label: 'labels', value: 'labels' }
  ];
  template_edit: boolean = false;
  templates: Template[] = [];
  templateEditable: any = {};
  editorContent: string = "";
  tempName: string = "";
  templateName: string = "";
  editable_template: boolean = false;
  template_id: string;
  constructor(
    public dialog: MatDialog,
    private templatesService: TemplatesService,
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private errorHandlingService: ErrorsHandler
  ) {}

  ngOnInit() {
    this.getTemplates();
  }

  getTemplates() {
    this.templatesService.all().subscribe(
      (data: Template[]) => {
        this.templates = data;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  openDialog() {
    let dialogRef = this.dialog.open(DoctorTemplateDialogComponent, {
      width: "800px",
      height: "auto",
    });
    return dialogRef.afterClosed();
  }

  editTemplate(templateId) {
    this.template_id = templateId;
    this.editable_template = true;
    let oldData = this.templates.filter((temp) => temp.id == templateId)[0];
    this.tempName = oldData.name;
    this.templateEditable = {
      name: oldData.name,
      content: oldData.content,
      id: oldData.id,
    };
  }

  updateTemplateInfo() {
    if (
      this.templateEditable.content === "" ||
      this.templateEditable.name === ""
    ) {
      this.snackBar.open(
        this.translate.instant("connect.templates.can_not_add")
      );
    } else {
      let id = this.templateEditable.id;
      this.templatesService.update(id, this.templateEditable).subscribe(
        (data) => {
          this.snackBar.open(
            this.translate.instant(
              "connect.templates.template_updated_successfully"
            )
          ),
            this.getTemplates;
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
      this.editable_template = false;
      this.getTemplates();
    }
  }

  cancelTemplateInfo() {
    this.editable_template = false;
  }

  deleteTemplate(templateId) {
    this.editable_template = false;
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      icon: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.confirmation_box_delete_it"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      this.editable_template = false;

      if (result.value) {
        Swal.fire(this.translate.instant("connect.templates.has_deleted"));
        this.templatesService.delete(templateId).subscribe(
          (success) => {
            this.snackBar.open(
              this.translate.instant("connect.templates.has_deleted")
            );
            return this.getTemplates();
          },
          (error) =>
            this.snackBar.open(
              this.translate.instant("connect.globals.try_again")
            )
        );
      }
    });
  }

  getEditorContent(evt) {
    this.templateEditable.content = evt;
  }
}
