<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template medical-record">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.medical_record.patient_record" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <button class="create-btn" (click)="openDialog()" mat-button>
            <i class="fa fa-plus-circle" style="margin-right: 5px"></i>
            <span>{{ "connect.medical_record.add_record" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row mt-4">
          <div class="col-md-12">
            <app-medical-record-table [name]="name"></app-medical-record-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
