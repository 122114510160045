import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
import { BookingSlot } from "src/app/models/booking-slot";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BookingSlotsService {
  autocomplete_patient(filters?: string): Observable<any> {
    return this.http.get(`${this.bookingSlotsUrl}/autocomplete_patient`+ '?' + filters);
  }
  private bookingSlotsUrl: string = "/booking_slots";

  constructor(private http: HttpWrapper) {}

  get(filters?: string) {
    return this.http.get(this.bookingSlotsUrl + "?" + filters);
  }

  toConfirm() {
    return this.http.get(`${this.bookingSlotsUrl}/to_confirm`);
  }

  create(bookingSlot: BookingSlot) {
    return this.http.post(this.bookingSlotsUrl, bookingSlot);
  }

  delete(bookingSlotId: string | undefined) {
    return this.http.delete(`${this.bookingSlotsUrl}/${bookingSlotId}`);
  }

  update(bookingSlot: BookingSlot) {
    return this.http.put(
      `${this.bookingSlotsUrl}/${bookingSlot.id}`,
      bookingSlot
    );
  }

  blockInterval(bookingSlot: BookingSlot) {
    return this.http.post(
      `${this.bookingSlotsUrl}/block_interval`,
      bookingSlot
    );
  }

  confirm(BookingSlotId: any) {
    return this.http.put(
      `${this.bookingSlotsUrl}/${BookingSlotId}/confirm`,
      ""
    );
  }

  cancel(BookingSlotId: any) {
    return this.http.put(`${this.bookingSlotsUrl}/${BookingSlotId}/cancel`, "");
  }

  didntShowUp(BookingSlotId: any, showingup: boolean) {
    return this.http.put(`${this.bookingSlotsUrl}/${BookingSlotId}/showingup`, {
      patient_didnt_showup: showingup,
    });
  }

  appointment_delayed(BookingSlotId: any, minutes_delayed: string) {
    return this.http.put(
      `${this.bookingSlotsUrl}/${BookingSlotId}/appointment_delayed`,
      { minutes_delayed: minutes_delayed }
    );
  }

  toggleDayAvailability(current_day: string, resourceId: string) {
    return this.http.post(`${this.bookingSlotsUrl}/toggle_day_availability`, {
      current_day: current_day,
      resourceId: resourceId
    });
  }
}
