<form [formGroup]="prescriptionForm" (submit)="savePrescription()">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>{{ "connect.prescriptions.patient_name" | translate }}</label>
        <input
          class="form-control"
          formControlName="name"
          type="text"
          readonly
        />
      </div>
    </div>
  </div>
  <div class="medication-box">
    <div class="row">
      <div class="col-lg-10 col-md-10 col-sm-9 col-8">
        <div>
          <label>{{
            "connect.prescriptions.choose_medication" | translate
          }}</label>
          <mat-form-field class="width-100">
            <mat-select formControlName="medicine" #singleSelect>
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="medicineFilterCtrl"
                  placeholderLabel="{{
                    'connect.prescriptions.enter_medication_name' | translate
                  }}"
                  noEntriesFoundLabel="{{
                    'connect.prescriptions.no_matching_found' | translate
                  }}"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let medicine of filteredMedicines | async"
                value="{{ medicine.name }}/medicine/{{
                  medicine.id || medicine._id
                }}"
                [ngClass]="medicine.manual && 'highlight'"
              >
                {{ medicine.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-3 col-4">
        <button
          class="create-btn mat-button mat-button-base btn-block"
          type="button"
          (click)="addDosage()"
          [disabled]="checkMedicine()"
        >
          {{ "connect.prescriptions.add_prescription" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row" formArrayName="posologies">
    <div
      class="col-md-12"
      *ngFor="let posologie of posologiesForm.controls; let i = index"
    >
      <div [formGroupName]="i" class="added-items-list-container mb-3">
        <div class="added-items-list-head">
          <div class="number-and-title">
            <div class="numbers">{{ i + 1 }}</div>
            <div class="title-header medicine-input">
              <input formControlName="medicine_name" readonly />
            </div>
          </div>
          <div class="close-icons">
            <a
              class="btn delete-btn btn-icon pointer"
              (click)="removeDosage(i)"
            >
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
        <div class="added-items-list-body">
          <input
            class="form-control"
            formControlName="medicine_dosage"
            placeholder="{{
              'connect.prescriptions.enter_instructions' | translate
            }}"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{
          "connect.prescriptions.prescription_dosage" | translate
        }}</label>
        <textarea class="form-control" formControlName="dosage"></textarea>
        <p *ngIf="patientEmail" class="pt-3">
          <span>
            <label class="kt-checkbox kt-checkbox--success">
              <input type="checkbox" formControlName="send_to_patient" />{{
                "connect.prescriptions.send_to_patient" | translate
              }}
              <span></span>
            </label>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <a class="mb-3 add-new pointer" (click)="addMedicine()">{{
        "connect.prescriptions.add_new" | translate
      }}</a>
    </div>
    <div class="col-md-6 text-right">
      <button class="btn save-btn" type="submit" [disabled]="prescriptionForm.invalid">{{ 'connect.prescriptions.' + (edit ? 'update_prescription' : 'save_prescription') | translate }}</button>
      <button class="btn save-btn mr-2 delete-btn-attachments" type="button" *ngIf="edit" (click)="cancel()">{{ 'connect.prescriptions.cancel' | translate }}</button>
    </div>
  </div>
</form>
