<ngx-intl-tel-input
  [cssClass]="'custom select-country'"
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [selectFirstCountry]="false"
  [selectedCountryISO]="country"
  [phoneValidation]="true"
  [separateDialCode]="false"
  [formControlName]="controlName"
>
</ngx-intl-tel-input>
