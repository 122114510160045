import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})

export class PhoneValidationHelper {
  constructor() {}

  validatePhoneNumber(control: AbstractControl, countryCode: CountryCode = 'MA'): boolean
  {
    const phone = control.value;
    if (phone?.number)
    {
      try
      {
        const parseNumber = parsePhoneNumber(phone?.e164Number, countryCode);
        return parseNumber.isValid();
      } 
      catch
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  setPhoneNumberErrors(control: AbstractControl, isValid: boolean): void
  {
    if (!isValid)
    {
      control.setErrors({ phone_number: true });
    }
    else
    {
      control.setErrors(null);
    }
  }
}