import { Component, ViewChild, OnInit, ViewEncapsulation } from "@angular/core";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { PaymentService } from "src/app/services/payment.service";
import { Plan } from "src/app/models/plan";
import { GlobalService } from "src/app/services/global.service";
import { PlanType } from "src/app/enums/planType.enum";
import { PlanTypeFilter } from "src/app/enums/planTypeFilter.enum";
@Component({
  selector: "app-payment-sms",
  templateUrl: "./payment-sms.component.html",
  styleUrls: ["./payment-sms.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentSmsComponent implements OnInit {
  invoiceForm: UntypedFormGroup;
  currentDoctor: Doctor;
  plans: Plan[];
  paymentMethod: boolean = true;
  cmis: any;
  keys: any;
  binga: any;

  constructor(
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private formBuilder: UntypedFormBuilder,
    private doctorService: DoctorService,
    private paymentService: PaymentService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.getPlans();
  }

  getPlans() {
    this.paymentService
      .getPlans(PlanTypeFilter.SMS)
      .subscribe((plans: Plan[]) => {
        this.plans = plans;
        this.getDoctorInformation();
      });
  }

  getDoctorInformation() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
        this.initProfileForm();
      }
    });
  }

  initProfileForm() {
    this.invoiceForm = this.formBuilder.group({
      email: [this.currentDoctor.email, Validators.required],
      phone_number: [this.currentDoctor.phone_number, Validators.required],
      first_name: [this.currentDoctor.first_name, Validators.required],
      last_name: [this.currentDoctor.last_name, Validators.required],
      address: [this.currentDoctor.full_address, Validators.required],
      plan_id: [null, Validators.required],
      payment_method: ["MTC"],
      type: ["sms"],
      cgv: [null],
    });
  }

  changePaymentMethod(type) {
    this.invoiceForm.controls["payment_method"].setValue(type);
    this.paymentMethod = false;
  }

  createInvoice() {
    this.globalService.showSpinner();
    let interNumber = this.invoiceForm.controls["phone_number"].value[
      "internationalNumber"
    ].replace(/-|\s/g, "");
    this.invoiceForm.controls["phone_number"].setValue(interNumber);
    this.paymentService.create(this.invoiceForm.value).subscribe(
      (data: any) => {
        this.submitForm(data);
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  submitForm(data) {
    if (data.invoice.payment_method == "MTC") {
      this.cmis = data;
      this.keys = Object.keys(data.params_hash);
    } else {
      this.keys = Object.keys(data);
      (this.binga = data),
        ["invoice", "privateKey", "actionUrl"].forEach(function (type) {
          const index = this.keys.indexOf(type);
          this.keys.splice(index, 1);
        }, this);
    }
    setTimeout(() => {
      this.globalService.hideSpinner();
      document.forms["paymentForm"].submit();
    }, 1000);
  }
}
