import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { StatisticsService } from "src/app/services/statistics.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Statistics } from "src/app/models/statistics.model";

@Component({
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StatisticsComponent {
  DoctorStats: Statistics;
  constructor(
    private statisticsService: StatisticsService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private errorHandler: ErrorsHandler
  ) {}

  ngOnInit(): void {
    this.getStatistics();
  }

  getStatistics() {
    this.statisticsService.get().subscribe(
      (data: Statistics) => {
        this.DoctorStats = data;
        this.DoctorStats.year = new Date().getFullYear();
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }
}
