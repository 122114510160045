import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class ActsService {
  private actsUrl: string = "/acts";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.actsUrl);
  }

  create(acts: any[]) {
    return this.http.post(this.actsUrl, { act_ids: acts });
  }
}
