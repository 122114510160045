import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { InvoicesService } from "src/app/services/invoices.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Invoice } from "src/app/models/invoices";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import {
  trigger,
  transition,
  style,
  sequence,
  animate,
  state,
} from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { DatePipe } from "@angular/common";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject, Subject } from "rxjs";

@Component({
  selector: "app-invoice-record-table",
  templateUrl: "./invoice-record-table.component.html",
  providers: [DatePipe],
  styleUrls: ["./invoice-record-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("rowsAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(600)]),
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class InvoiceRecordTableComponent implements OnInit {
  displayedColumns: string[] = [
    "created_at",
    "description",
    "total_amount",
    "payment_method",
    "state",
    "actions",
  ];
  dataSource;
  currentDoctor: Doctor;
  datePremiumExpiration: any;
  dateToday: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  protected _onDestroy = new Subject<void>();

  nextDisable: boolean = false;
  previousDisable: boolean = false;
  listParams = {
    pageNumber: 1,
  };

  constructor(
    private invoiceService: InvoicesService,
    private router: Router,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private datePipe: DatePipe,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler
  ) {}

  getInvoiceRecords(params) {
    this.listParams.pageNumber == 1
      ? (this.previousDisable = true)
      : (this.previousDisable = false);
    const filter = "page=" + params;
    this.invoiceService
      .all(filter)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: Invoice[]) => {
          data.length < 10
            ? (this.nextDisable = true)
            : (this.nextDisable = false);
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }

  paginatorInvoiceRecords(key: string) {
    key == "previous"
      ? this.listParams.pageNumber--
      : this.listParams.pageNumber++;
    this.getInvoiceRecords(this.listParams.pageNumber);
  }

  ngOnInit() {
    this.getDoctorInfo();
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        const today = moment().format();
        this.datePremiumExpiration = new Date(
          currentDoctor.premium_expiration_date
        );
        this.dateToday = new Date(this.datePipe.transform(today, "yyyy-MM-dd"));
        this.datePremiumExpiration =
          (this.datePremiumExpiration - this.dateToday) / 1000 / 60 / 60 / 24;
        this.currentDoctor = currentDoctor;
        this.getInvoiceRecords((this.listParams.pageNumber = 1));
      }
    });
  }

  showInvoiceDetails(invoice_id) {
    return this.router.navigate(["/invoices", invoice_id]);
  }
}
