import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment as appConfig } from "../../environments/environment";
import { catchError, retry } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpWrapper {
  API_BASE_URL = appConfig.doctor_api_url;
  BASE_URL = appConfig.base_url;

  constructor(private http: HttpClient) {}

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.API_BASE_URL + url);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(this.API_BASE_URL + url, body);
  }

  searchGet<T>(url: string): Observable<T> {
    return this.http.get<T>(this.BASE_URL + url);
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(this.API_BASE_URL + url, body);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.API_BASE_URL + url);
  }

  postFile(url, data) {
    return this.http.post(this.API_BASE_URL + url, data, {}).pipe(retry(1));
  }

  patch<T>(url: string, body: string): Observable<T> {
    return this.http.patch<T>(this.API_BASE_URL + url, body);
  }
}