<div class="patientProfile">
  <div class="kt-portlet">
    <div class="parient-details">
      <div class="row">
        <div class="col-sm-12">
          <div class="multi-clr-btns-box">
            <ul>
              <li *ngFor="let lebel of medicalPatientLabels">
                <button
                  class="multi-clr-btn btn-white-color"
                  [style.backgroundColor]="lebel.color"
                >
                  {{ lebel.name }}
                </button>
              </li>
            </ul>
          </div>
          <div class="form-group patient-details__section">
            <div class="d-flex edit-form">
              <button
                *ngIf="!editable"
                (click)="showLabels()"
                class="btn btn-outline-neutral"
              >
                <i class="fa fa-plus mr-2"></i
                >{{ "connect.user_profiles.edit_label" | translate }}
              </button>
              <button
                class="ml-auto btn btn-outline-neutral"
                *ngIf="!editable"
                (click)="initProfileForm()"
              >
                <i class="fa fa-edit mr-2"></i
                >{{ "connect.user_profiles.edit" | translate }}
              </button>
            </div>
            <div class="multiple-select" *ngIf="isToggled">
              <mat-form-field class="w-full" appearance="fill">
                <mat-label>{{ "connect.user_profiles.apply_labels" | translate }}</mat-label>
                <mat-chip-list #chipList aria-label="Patient Labels">
                  <mat-chip
                    *ngFor="let label of patientLabels"
                    (removed)="removeLabel(label.id)">
                    {{label.name}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    placeholder="Label..."
                    #labelInput
                    [formControl]="labelCtrl"
                    [matAutocomplete]="autocompleteLabels"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (input)="onLabelCtrlChanges($event)"
                    >
                </mat-chip-list>
                <mat-autocomplete #autocompleteLabels="matAutocomplete" (optionSelected)="labelOptionSelected($event)">
                  <mat-option *ngFor="let label of filteredLabels" [value]="label.id">
                    {{label.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <button
                (click)="navigateLabels()"
                class="btn btn-outline-neutral mt-1 ml-1"
              >
                <i class="fa fa-plus"></i
                >{{ "connect.user_profiles.add_patient_labels" | translate }}
              </button>
            </div>
            <div *ngIf="!editable">
              <div class="edit-form-custom">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{
                        "connect.user_profiles.first_name" | translate
                      }}</label>
                      <div class="showing-data">
                        {{ patient.first_name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{
                        "connect.user_profiles.last_name" | translate
                      }}</label>
                      <div class="showing-data">
                        {{ patient.last_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>{{
                        "connect.user_profiles.email" | translate
                      }}</label>
                      <div class="showing-data">&nbsp;{{ patient.email }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{
                        "connect.user_profiles.phone" | translate
                      }}</label>
                      <div class="showing-data">
                        &nbsp;{{ patient.phone_number }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 hide-field">
                    <div class="form-group">
                      <label>{{
                        "connect.user_profiles.date_of_birth" | translate
                      }}</label>
                      <div class="showing-data">
                        &nbsp;{{ patient.date_of_birth | date : "dd-MM-yyyy" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="more-fields"
                  [ngClass]="{ 'show-more-fields': showToggled === true }"
                >
                  <div class="row">
                    <div class="col-md-6 show-field">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.date_of_birth" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{
                            patient.date_of_birth | date : "dd-MM-yyyy"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.insurance" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{ patient.insurance }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.reffered_by" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{ patient.referred_by }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.blood_type" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{ patient.blood_type }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.patient_address" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{ patient.address }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.city" | translate
                        }}</label>
                        <div class="showing-data">&nbsp;{{ patient.city }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.country" | translate
                        }}</label>
                        <div class="showing-data">
                          &nbsp;{{ patient.country }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{
                          "connect.user_profiles.cin" | translate
                        }}</label>
                        <div class="showing-data">&nbsp;{{ patient.cin }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <a
                    class="show-more-btn add-new pointer"
                    [ngClass]="{ 'active-button': showButtonToggled === true }"
                    (click)="addClasses()"
                    ><span class="show-more">{{
                      "connect.user_profiles.view_more" | translate
                    }}</span
                    ><span class="show-less">{{
                      "connect.user_profiles.view_less" | translate
                    }}</span></a
                  >
                </div>
              </div>
            </div>
            <form
              [formGroup]="profileForm"
              (ngSubmit)="updatePatientInfo()"
              *ngIf="editable"
            >
              <div class="text-right edit-form">
                <button
                  class="btn mr-3 btn-outline-cancel"
                  *ngIf="editable"
                  (click)="resetForm()"
                >
                  {{ "connect.user_profiles.cancle" | translate }}
                </button>
                <button
                  type="submit"
                  class="btn mr-3 save-btn"
                  *ngIf="editable"
                  [disabled]="!profileForm.valid"
                >
                  {{ "connect.user_profiles.save" | translate }}
                </button>
              </div>
              <div class="edit-form-custom mt-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="{{
                            'connect.user_profiles.first_name' | translate
                          }}"
                          formControlName="first_name"
                          class="text-dark text-font-mid"
                          [ngClass]="
                            nameIsNotValid === true ? 'is-invalid' : ''
                          "
                        />
                        <mat-hint *ngIf="profileForm.get('first_name').errors">
                          <mat-error>{{
                            "connect.user_profiles.errors.name" | translate
                          }}</mat-error>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="{{
                            'connect.user_profiles.last_name' | translate
                          }}"
                          formControlName="last_name"
                          class="text-dark text-font-mid"
                          [ngClass]="
                            emailIsNotValid === true ? 'is-invalid' : ''
                          "
                          name="email"
                          placeholderText="last_name"
                        />
                        <mat-hint *ngIf="profileForm.get('last_name').errors">
                          <mat-error>{{
                            "connect.user_profiles.errors.name" | translate
                          }}</mat-error>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="{{
                            'connect.user_profiles.email' | translate
                          }}"
                          formControlName="email"
                          class="text-dark text-font-mid"
                          [ngClass]="
                            emailIsNotValid === true ? 'is-invalid' : ''
                          "
                        />
                        <mat-hint *ngIf="profileForm.get('email').errors">
                          <mat-error>{{
                            "connect.user_profiles.errors.email" | translate
                          }}</mat-error>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-group phone-selection phone-number-container country-phone"
                    >
                      <label class="mb-2">{{
                        "connect.user_profiles.phone" | translate
                      }}</label>
                      <ngx-intl-tel-input
                        [cssClass]="'custom select-country'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="country"
                        [phoneValidation]="false"
                        [separateDialCode]="false"
                        formControlName="phone_number"
                      >
                      </ngx-intl-tel-input>
                      <mat-hint
                        *ngIf="profileForm.get('phone_number').errors"
                        class="phone-error"
                      >
                        <mat-error>{{
                          "connect.user_profiles.errors.phone_number"
                            | translate
                        }}</mat-error>
                      </mat-hint>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <mat-select
                          multiple
                          class="full-width"
                          formControlName="insurance"
                          placeholder="{{
                            'connect.user_profiles.select_insurance' | translate
                          }}"
                        >
                          <mat-option
                            *ngFor="let insurance of patientInsurances"
                            value="{{ insurance.value }}"
                            >{{ insurance.viewValue }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="referred_by"
                          placeholder="{{
                            'connect.user_profiles.referred_by' | translate
                          }}"
                          class="text-dark text-font-mid"
                          [ngClass]="
                            emailIsNotValid === true ? 'is-invalid' : ''
                          "
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <mat-select
                          class="full-width"
                          placeholder="{{
                            'connect.user_profiles.select_blood_type'
                              | translate
                          }}"
                          formControlName="blood_type"
                        >
                          <mat-option
                            *ngFor="let blood_type of patientBloodTypes"
                            value="{{ blood_type.value }}"
                            >{{ blood_type.viewValue }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="address"
                          placeholder="{{
                            'connect.user_profiles.address' | translate
                          }}"
                          class="text-dark text-font-mid"
                          [ngClass]="is - invalid"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="city"
                          placeholder="{{
                            'connect.user_profiles.city' | translate
                          }}"
                          class="text-dark text-font-mid"
                          [ngClass]="is - invalid"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="country"
                          placeholder="{{
                            'connect.user_profiles.country' | translate
                          }}"
                          class="text-dark text-font-mid"
                          [ngClass]="is - invalid"
                          readonly="true"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="cin"
                          placeholder="{{
                            'connect.user_profiles.cin' | translate
                          }}"
                          class="text-dark text-font-mid"
                          [ngClass]="is - invalid"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          formControlName="date_of_birth"
                          placeholder="{{
                            'connect.user_profiles.date_of_birth' | translate
                          }}"
                          matInput
                          class="text-dark text-font-mid"
                          [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-hint
                          *ngIf="profileForm.get('date_of_birth').errors"
                        >
                          <mat-error>{{
                            "connect.user_profiles.errors.date_of_birth"
                              | translate
                          }}</mat-error>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-11 col-11">
          <button
            (click)="openDialog()"
            class="medical-record-button mat-button mat-button-base btn btn-block"
          >
            <i class="fas fa-calendar-plus"></i>
            {{ "connect.user_profiles.make_an_appointment" | translate }}
          </button>
        </div>
      </div>
      <div class="row justify-content-center patient-details__actions">
        <div class="col-sm-11 col-12 pb-5">
          <ul class="list-group">
            <li
              class="list-group-group d-flex align-items-center justify-content-between p-2 mb-2 list-items"
            >
              <span class="text-dark text-capitalize weight-bold">{{
                "connect.user_profiles.consultation" | translate
              }}</span>
              <span class="count">{{ patient.booking_slots_count }} </span>
            </li>
            <li
              class="list-group-group d-flex align-items-center justify-content-between p-2 mb-2 list-items"
            >
              <span class="text-dark text-capitalize weight-bold">{{
                "connect.user_profiles.absence" | translate
              }}</span>
              <span class="count">{{
                patient.missed_booking_slots_count
              }}</span>
            </li>
            <li
              class="list-group-group d-flex align-items-center justify-content-between p-2 mb-2 list-items"
            >
              <span class="text-dark text-capitalize weight-bold">{{
                "connect.user_profiles.notes" | translate
              }}</span>
              <span class="count">{{ patient.notes_count }} </span>
            </li>
            <li
              class="list-group-group d-flex align-items-center justify-content-between p-2 mb-2 list-items"
            >
              <span class="text-dark text-capitalize weight-bold">{{
                "connect.user_profiles.prescriptions" | translate
              }}</span>
              <span class="count">{{ patient.prescriptions_count }} </span>
            </li>
          </ul>
          <button
            (click)="deleteMedicalRecordNotes()"
            mat-stroked-button
            class="delete-btn col-sm-12"
          >
            <i class="fa fa-trash"></i>
            {{ "connect.user_profiles.remove" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
