import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { DoctorHelper } from "src/app/helpers/doctor_helper";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
})
export class MapComponent implements OnInit {
  @Input() currentDoctor: Doctor;
  zoom = 11;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };
  markers = [];
  center = {};
  address: string;
  private geoCoder;

  @ViewChild("search", { static: true })
  public searchElementRef: ElementRef;

  constructor(
    private doctorService: DoctorService,
    private ngZone: NgZone,
    private errorHandler: ErrorsHandler,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private doctorHelper: DoctorHelper
  ) {}

  ngOnInit() {
    this.updatePosition(
      this.currentDoctor.coordinates[1],
      this.currentDoctor.coordinates[0]
    );
    this.center = {
      lat: this.currentDoctor.coordinates[1],
      lng: this.currentDoctor.coordinates[0],
    };
  }

  ngAfterViewInit() {
    this.geoCoder = new google.maps.Geocoder();
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
      });
    });
  }
  // @ts-ignore
  click(event: google.maps.MouseEvent) {
    const lng = event.latLng.lng();
    const lat = event.latLng.lat();
    this.markers = [];
    this.updatePosition(lat, lng);
    this.doctorService.updateCoordinates([lng, lat]).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.profile.updated_location")
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  updatePosition(lat, lng) {
    this.markers.push({
      position: {
        lat: lat,
        lng: lng,
      },
      title: this.doctorHelper.fullName(this.currentDoctor),
    });
  }
}
