import { Component, ViewEncapsulation } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";

@Component({
  selector: "header-search-field",
  templateUrl: "./header-search-field.component.html",
  styleUrls: ["./header-search-field.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSearchFieldComponent {
  templateEditable: any = {};
  addToggleSearchClass: boolean = false;

  constructor(private router: Router) {}

  searchMedicalRecord() {
    let value = this.templateEditable.name;
    if (value) value = value.replace("%", "");

    let navigationExtras: NavigationExtras = {
      queryParams: {
        name: value,
      },
    };
    this.router.navigate(["/medical-records"], navigationExtras);
  }

  changeSearchClass() {
    this.addToggleSearchClass = !this.addToggleSearchClass;
  }
}
