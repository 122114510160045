<div mat-dialog-content class="booking-modal">
  <div class="booking-modal-header modal-header">
    <div>
      <h4>{{ "connect.delayed_appointment_dailog.late_email" | translate }}</h4>
    </div>
    <div>
      <button type="button" class="close" (click)="onNoClick()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="modal-form-container">
      <p>
        {{
          "connect.delayed_appointment_dailog.send_email_patient" | translate
        }}
      </p>
      <div class="form-group mb-3">
        <select class="form-control" [(ngModel)]="delayed_length">
          <option value="15" selected="">
            {{ "connect.delayed_appointment_dailog.15_minutes" | translate }}
          </option>
          <option
            value="{{ time_slot.value }}"
            *ngFor="let time_slot of time_slots"
          >
            {{
              "connect.delayed_appointment_dailog." + time_slot.viewValue
                | translate
            }}
          </option>
        </select>
      </div>
      <div class="form-group mt-4 mb-0 footer text-right" mat-dialog-actions>
        <button
          class="btn btn-sm mr-3 btn-outline-cancel"
          (click)="onNoClick()"
        >
          {{ "connect.delayed_appointment_dailog.cancel" | translate }}
        </button>
        <button class="btn mr-3 add-btn" (click)="appointmentDelayed()">
          {{ "connect.delayed_appointment_dailog.send_email" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
