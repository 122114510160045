import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { AssistantsService } from "src/app/services/assistants.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Assistant } from "src/app/models/assistants";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { GlobalService } from "src/app/services/global.service";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";

@Component({
  selector: "app-assistants",
  templateUrl: "./assistants.component.html",
  styleUrls: ["./assistants.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AssistantsComponent implements OnInit {
  email: string;
  assistants: Assistant[];
  assistantsForm: UntypedFormGroup;
  isEmailAvailable: boolean = false;
  isAssistantForm: boolean = false;
  isInvalidPattern: boolean = true;
  submitted = false;
  isEmailError = false;

  constructor(
    private assistantsService: AssistantsService,
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private errorHandler: ErrorsHandler
  ) {}

  ngOnInit() {
    this.getAssistants();
  }

  getAssistants() {
    this.globalService.showSpinner();
    this.assistantsService.get().subscribe(
      (data: Assistant[]) => {
        this.globalService.hideSpinner();
        this.assistants = data;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  getAssistant() {
    if ((this.isInvalidPattern = this.validateEmail(this.email))) {
      this.globalService.showSpinner();
      this.assistantsService.getEmail(this.email).subscribe(
        (data: any) => {
          if (data && data.email_error) {
            this.isEmailError = true;
          } else {
            this.initializeForm();
            this.isAssistantForm = true;
            this.isEmailAvailable = false;
            this.assistantsForm.controls["new"].setValue(true);
            if (data) {
              this.isEmailAvailable = true;
              this.setControlValues(data);
            }
          }
          this.globalService.hideSpinner();
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    }
  }

  setControlValues(assistant) {
    this.assistantsForm.controls["first_name"].setValue(assistant.first_name);
    this.assistantsForm.controls["last_name"].setValue(assistant.last_name);
    this.assistantsForm.controls["email"].setValue(assistant.email);
    this.assistantsForm.controls["new"].setValue(false);
    this.assistantsForm.get("password").clearValidators();
    this.assistantsForm.get("password_confirmation").clearValidators();
  }

  validateEmail(email: string): boolean {
    if (email) {
      const regularExpression = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      return regularExpression.test(String(email).toLowerCase());
    }
    return false;
  }

  backToEmail() {
    this.isAssistantForm = false;
  }

  initializeForm() {
    this.assistantsForm = this.formBuilder.group(
      {
        email: [],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        password: ["", Validators.required],
        password_confirmation: ["", Validators.required],
        new: [false],
      },
      {
        validator: this.confirmedValidator("password", "password_confirmation"),
      }
    );
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  createAssistant() {
    this.submitted = true;
    if (this.assistantsForm.invalid) return;
    this.globalService.showSpinner();
    this.assistantsForm.controls["email"].setValue(this.email);
    this.assistantsService.create(this.assistantsForm.value).subscribe(
      (data: Assistant) => {
        this.snackBar.open(
          this.translate.instant("connect.assistants.assistant_added")
        );
        this.getAssistants();
        this.isAssistantForm = false;
      },
      (error) => {
        this.globalService.hideSpinner();
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        );
      }
    );
  }

  deleteAssistant(assistantId) {
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      icon: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.confirmation_box_delete_it"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        this.assistantsService.delete(assistantId).subscribe(
          (deleteRecord: any) => {
            this.snackBar.open(
              this.translate.instant("connect.assistants.assistant_removed")
            );
            this.getAssistants();
          },
          (error) => {
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            );
          }
        );
      }
    });
  }
}
