<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.prescriptions.heading_of_orders" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <app-editor
          [content]="editorContent"
          (editorContentData)="getEditorContent($event)"
        ></app-editor>
        <div class="text-right">
          <button (click)="SaveDetails()" class="btn save-btn ml-auto mt-3">
            {{ "connect.prescriptions.save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
