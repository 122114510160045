import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { ControlContainer, FormGroupDirective } from "@angular/forms";

@Component({
  selector: "app-phone-number",
  templateUrl: "./phone-number.component.html",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class PhoneNumberComponent implements OnInit {
  @Input() controlName: string;
  @Output() editorContentData: EventEmitter<string> = new EventEmitter();

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Morocco,
    CountryISO.Algeria,
    CountryISO.Tunisia,
  ];
  country: string = "MA";

  constructor() {}

  ngOnInit() {}
}
