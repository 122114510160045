import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { DoctorService } from '../services/doctor.service';

@Injectable({ providedIn: 'root' })
export class PremiumGuard implements CanActivate 
{
  constructor(private doctorService: DoctorService, private router: Router) {}

  canActivate() 
  {
    return this.doctorService.currentDoctor$.pipe(
      filter(doctor => !!doctor),
      take(1),
      map(doctor => this.condition(doctor)),
      map(conditionResult  => 
      {
        if (!conditionResult)
        {
          this.router.navigate(['/payment']);
          return false;
        }
        return true;
      })
    );
  }

  protected condition(doctor: any): boolean
  {
    return false;
  }
}