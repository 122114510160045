<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.attachment_sharings_list.attachments" | translate }}
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="sharing-table-link">
          <ul>
            <li>
              <a
                (click)="changeTabs()"
                [ngClass]="{ active: !isActive }"
                class="cursor-pointer"
                >{{ "connect.attachment_sharings_list.inbox" | translate }}</a
              >
            </li>
            <li>
              <a
                (click)="changeTabs()"
                [ngClass]="{ active: isActive }"
                class="cursor-pointer"
                >{{ "connect.attachment_sharings_list.sent" | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div class="row mt-4" *ngIf="isInboxTab">
          <div class="col-md-12">
            <table
              mat-table
              [dataSource]="dataSourceReciepent"
              matSort
              class="mat-elevation-z1"
              class="patient-table responsive-table sharing_table"
            >
              <ng-container matColumnDef="patient">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.patient" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing">
                  <span>
                    {{ sharing.patient_first_name }}
                    {{ sharing.patient_last_name }}<br />
                    {{ sharing.patient_date_of_birth }}<br />
                    {{ sharing.patient_phone_number }}<br />
                    {{ sharing.patient_email }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="document">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.document" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing" class="note-images">
                  <div class="attachments-list-td">
                    <div class="attachments-list-div">
                      <div
                        *ngFor="let event of sharing.attachments"
                        class="note-image-box"
                      >
                        <app-attachment-files
                          [event]="event"
                        ></app-attachment-files>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.sender" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing">
                  <span *ngIf="currentDoctor">
                    {{ sharing.sender_doctor.first_name }}
                    {{ sharing.sender_doctor.last_name }}
                    <br />
                    <p class="ellipses-box">
                      {{ sharing.sender_doctor.specialities }}
                    </p>
                    <br />
                    {{ sharing.sender_doctor.city }} ({{
                      sharing.sender_doctor.phone_number
                    }})
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="actions-th">
                  <span class="">{{
                    "connect.attachment_sharings_list.date" | translate
                  }}</span>
                </th>
                <td
                  mat-cell
                  *matCellDef="let sharing"
                  class="actions-td"
                  nowrap="nowrap"
                >
                  <span class="">{{
                    sharing.created_at | date : "MMM d, y, h:mm"
                  }}</span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                [@rowsAnimation]=""
                *matRowDef="let row; columns: displayedColumns"
              ></tr>
            </table>
            <h2
              *ngIf="
                dataSourceReciepent && dataSourceReciepent.data.length === 0
              "
              class="no-filter-data"
            >
              {{ "connect.appointment_table.no_result" | translate }}
            </h2>
            <div class="text-right mt-3">
              <button
                mat-raised-button
                color="primary"
                class="ml-auto mr-1"
                [disabled]="previousInboxDisable"
                (click)="paginatorInboxAttachmentRecords('previous')"
              >
                {{ "connect.globals.previous_button_text" | translate }}
              </button>
              <span class="pagination-number">{{
                listInboxParams.pageNumber
              }}</span>
              <button
                mat-raised-button
                color="primary"
                class="ml-auto"
                [disabled]="nextInboxDisable === true"
                (click)="paginatorInboxAttachmentRecords('next')"
              >
                {{ "connect.globals.next_button_text" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-4" *ngIf="!isInboxTab">
          <div class="col-md-12">
            <table
              mat-table
              [dataSource]="dataSourceSender"
              matSort
              class="mat-elevation-z1"
              class="patient-table responsive-table sharing_table"
            >
              <ng-container matColumnDef="patient">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.patient" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing">
                  <span>
                    {{ sharing.patient_first_name }}
                    {{ sharing.patient_last_name }}<br />
                    {{ sharing.patient_date_of_birth }}<br />
                    {{ sharing.patient_phone_number }}<br />
                    {{ sharing.patient_email }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="document">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.document" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing" class="note-images">
                  <div class="attachments-list-td">
                    <div class="attachments-list-div">
                      <div
                        *ngFor="let event of sharing.attachments"
                        class="note-image-box"
                      >
                        <app-attachment-files
                          [event]="event"
                        ></app-attachment-files>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="">{{
                    "connect.attachment_sharings_list.recipient" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let sharing">
                  <span *ngIf="sharing.recipient_doctor">
                    {{ sharing.recipient_doctor.first_name }}
                    {{ sharing.recipient_doctor.last_name }}
                    <br />
                    <p class="ellipses-box">
                      {{ sharing.recipient_doctor.specialities }}
                    </p>
                    <br />
                    {{ sharing.recipient_doctor.city }} ({{
                      sharing.recipient_doctor.phone_number
                    }})
                  </span>
                  <span class="" *ngIf="!sharing.recipient_doctor">
                    {{ sharing.recipient_first_name }}
                    {{ sharing.recipient_last_name }}
                    <br />
                    {{ sharing.recipient_email }}
                    <br />
                    {{ sharing.recipient_phone_number }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="actions-th">
                  <span class="">{{
                    "connect.attachment_sharings_list.date" | translate
                  }}</span>
                </th>
                <td
                  mat-cell
                  *matCellDef="let sharing"
                  class="actions-td"
                  nowrap="nowrap"
                >
                  <span class="">{{
                    sharing.created_at | date : "MMM d, y, h:mm"
                  }}</span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                [@rowsAnimation]=""
                *matRowDef="let row; columns: displayedColumns"
              ></tr>
            </table>
            <h2
              *ngIf="dataSourceSender && dataSourceSender.data.length === 0"
              class="no-filter-data"
            >
              {{ "connect.appointment_table.no_result" | translate }}
            </h2>
            <div class="text-right mt-3">
              <button
                mat-raised-button
                color="primary"
                class="ml-auto mr-1"
                [disabled]="previousSentDisable"
                (click)="paginatorSentAttachmentRecords('previous')"
              >
                {{ "connect.globals.previous_button_text" | translate }}
              </button>
              <span class="pagination-number">{{
                listSentParams.pageNumber
              }}</span>
              <button
                mat-raised-button
                color="primary"
                class="ml-auto"
                [disabled]="nextSentDisable === true"
                (click)="paginatorSentAttachmentRecords('next')"
              >
                {{ "connect.globals.next_button_text" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
