import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
import { Department } from "../models/department";

@Injectable({
  providedIn: "root",
})
export class DepartmentsService {
  private departmentEntityUrl: string = "/departments";
  private clinicResourceEntityUrl: string = "/clinic_resources";

  constructor(private http: HttpWrapper) {}

  getDepartments(filters?: string) {
    let url = this.departmentEntityUrl;
    if (filters) {
      url += "?" + filters;
    }
    return this.http.get(url);
  }

  getDepartment(departmentId: string) {
    return this.http.get(`${this.departmentEntityUrl}/${departmentId}`);
  }
  
  getDepartmentResources(departmentId: string) {
    return this.http.get(`${this.departmentEntityUrl}/${departmentId}${this.clinicResourceEntityUrl}`);
  }

  // updateDepartment(departmentId: string, department: any) {
  //   return this.http.put(`${this.departmentEntityUrl}/${departmentId}`, department);
  // }
}
