import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http'
import Swal from 'sweetalert2'
import { Doctor } from 'src/app/models/doctor'
import { Invoice } from 'src/app/models/invoices';
import { Plan } from 'src/app/models/plan';
import { DoctorService } from 'src/app/services/doctor.service';
import { PaymentService } from 'src/app/services/payment.service';
import { GlobalService } from 'src/app/services/global.service';
import { PlanTypeFilter } from 'src/app/enums/planTypeFilter.enum';
import { PlanType } from 'src/app/enums/planType.enum';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PaymentComponent implements OnInit {
  [x: string]: any
  plans: Plan[]
  isHidden = true;
  chosenPlan: Plan
  currentDoctor: Doctor
  shouldFatouratiDisplay=false;
  shouldDisplayConnectPlan=true;
  isLoading=true;
  invoice :Invoice
  plan : Plan
  cmis: any
  keys: any
  binga: any

  invoiceForm = new FormGroup({
    plan_id:new FormControl(),
    type: new FormControl(),
    first_name: new FormControl(),
    last_name: new FormControl(),
    email:new FormControl(),
    phone_number:new FormControl(),
    address:new FormControl(),
    payment_method:new FormControl(),
    cgv:new FormControl(),
  });
  constructor(
    private translate: TranslateService,
    private doctorService: DoctorService,
    private paymentService: PaymentService,
    private globalService: GlobalService,
    private http: HttpClient
  ){
  }

  ngOnInit(): void {
    this.getPlans()
  }
  getPlans() {
    this.paymentService.getPlans(PlanTypeFilter.SUBSCRIPTION)
      .subscribe((plans: Plan[]) => {
          this.plans = plans
          this.getDoctorInformation()
        }
      )
  }

  getPlan(position)
  {    
    return this.plans.find(plan => {
      return plan.position == position
    })
  }

  getDoctorInformation() {
    this.doctorService.currentDoctor$
      .subscribe((currentDoctor) => {
          if (currentDoctor) {
            this.currentDoctor = currentDoctor
            this.shouldDisplayConnectPlan = currentDoctor.plan_type == PlanType.CONNECT
            this.isLoading=false
          }
        }
      )
  }

  initForm(){
    this.invoiceForm = new FormGroup({
      plan_id:new FormControl(this.chosenPlan['id'],[Validators.required]),
      type: new FormControl(this.chosenPlan['type'],[Validators.required]),
      first_name: new FormControl(this.currentDoctor.first_name,[Validators.required]),
      last_name: new FormControl(this.currentDoctor.last_name,[Validators.required]),
      email:new FormControl(this.currentDoctor.email,[Validators.required,Validators.email]),
      phone_number:new FormControl(this.currentDoctor.phone_number,[Validators.required]),
      address:new FormControl(this.currentDoctor.address,[Validators.required]),
      payment_method:new FormControl('MTC',Validators.required),
      cgv:new FormControl('',Validators.required),
    });
  }

  submitPlan(){
    if (this.invoiceForm.valid) {
      this.paymentService.create(this.invoiceForm.value)
        .subscribe(
          (data: any) => {
            this.submitForm(data)
          }
    )
    } else {
      Swal.fire({
        title: this.translate.instant('connect.payments.invalid_form_title'),
        text: this.translate.instant('connect.payments.invalid_form_text'),
        icon: 'info',
      })
    }
  }

  submitForm(data) {
    if (data.invoice?.payment_method == 'MTC') {
      this.cmis = data
      this.keys = Object.keys(data.params_hash)
    }else if (data.invoice?.payment_method == 'Fatourati'){
      this.shouldFatouratiDisplay=true;
      this.invoice=data.invoice;
       this.plan=data.plan
      return 
    
    }else {
      this.keys = Object.keys(data)
      this.binga = data,
      ['invoice','privateKey','actionUrl'].forEach(function (type) {
        const index = this.keys.indexOf(type)
        this.keys.splice(index, 1)
      }, this)
    }    setTimeout(() => {
      this.globalService.hideSpinner()
      document.forms["paymentForm"].submit()
    }, 1000)
  }

  selectPlan(plan: Plan): void {
    this.chosenPlan = plan
    this.initForm()
    this.isHidden = !this.isHidden;
  }
  backToPlans(){
    this.isHidden = !this.isHidden;
  }

}
