import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { TranslateService } from "@ngx-translate/core";
import { PrescriptionsService } from "src/app/services/prescriptions.service";
import { GlobalService } from "src/app/services/global.service";

// @ts-ignore
@Component({
  templateUrl: "./prescription-details.component.html",
  styleUrls: ["./prescription-details.component.scss"],
})
export class PrescriptionDetailsComponent implements OnInit, OnDestroy {
  PrescriptionID: string = this.route.snapshot.params.id.toString();
  prescriptionDetail: string;
  currentDoctor: Doctor;
  disabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private prescriptionsService: PrescriptionsService,
    private snackBar: SnackBarService,
    private doctorService: DoctorService,
    private translate: TranslateService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.hideHeader();
    this.getPrescriptionDetails();
    this.getDoctorInfo();
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      this.currentDoctor = currentDoctor;
    });
  }

  getPrescriptionDetails() {
    return this.prescriptionsService
      .getPrescriptionDetails(this.PrescriptionID)
      .subscribe(
        (prescriptionData: string) => {
          this.prescriptionDetail = prescriptionData;
        },
        (error) =>
          this.snackBar.open(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }

  ngOnDestroy() {
    this.globalService.showHeader();
  }
}
