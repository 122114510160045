<div class="row invoice-record-table">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.invoices.your_invoices" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar panel-heading-buttons">
          <a
            href="https://res.cloudinary.com/hh9gnin1v/image/upload/v1711378403/240325_Contrat_SaaS_DabaDoc.pdf"
            class="create-btn mr-1 contract-btn"
            mat-button
            target="_blank"
            download
          >
            <span>{{ "connect.invoices.your_contract" | translate }}</span>
          </a>
          <a
            href="/payment"
            class="create-btn btn renew-btn"
            mat-button
          >
            <span>{{
              "connect.invoices.renew_your_subscription" | translate
            }}</span>
          </a>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row">
          <div class="col-md-3">
            <div class="panel panel-primary sidebarpanel subscription-details">
              <div class="panel-heading">
                <b>
                  {{ "connect.invoices.subscription_details" | translate }}
                </b>
              </div>
              <div class="panel-body">
                <ul class="sidebarnav list-group">
                  <li class="list-group-item list-group-item-info">
                    <span class="NoRecordMsg">
                      {{ "connect.invoices.plan" | translate }} :
                      <b *ngIf="currentDoctor">
                        {{ currentDoctor.plan_name }}
                      </b>
                    </span>
                  </li>
                  <li class="list-group-item list-group-item-info">
                    <span class="NoRecordMsg">
                      {{ "connect.invoices.days_remaining" | translate }} :
                      <b
                        *ngIf="
                          currentDoctor && currentDoctor.premium_expiration_date
                        "
                      >
                        {{ datePremiumExpiration }}
                        {{ "connect.invoices.days" | translate }}
                      </b>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="mat-elevation-z1"
              class="invoice-table responsive-table"
            >
              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="mr-10">{{
                    "connect.invoices.creation_date" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let invoice">
                  <span class="mr-10">{{
                    invoice.created_at | date : "dd-MM-yyyy"
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="mr-10">{{
                    "connect.invoices.description" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let invoice">
                  <span class="mr-10">{{ invoice.description }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="total_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="mr-10">{{
                    "connect.invoices.total_amount" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let invoice">
                  <span class="mr-10"
                    >{{ invoice.total_amount }} {{ invoice.currency }}</span
                  >
                </td>
              </ng-container>

              <ng-container matColumnDef="payment_method">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="mr-10">{{
                    "connect.invoices.method" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let invoice">
                  <span class="mr-10">{{ invoice.payment_method }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span class="mr-10">{{
                    "connect.invoices.state" | translate
                  }}</span>
                </th>
                <td mat-cell *matCellDef="let invoice">
                  <span class="mr-10">{{
                    "connect.invoices." + invoice.state | translate
                  }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let invoice" class="text-center">
                  <button
                    (click)="showInvoiceDetails(invoice.id)"
                    class="create-btn create-display-btn"
                    mat-button
                  >
                    <span>{{ "connect.invoices.display" | translate }}</span>
                  </button>
                  <span class="mob-display-btn">
                    <i
                      (click)="showInvoiceDetails(invoice.id)"
                      class="fa fa-eye"
                    ></i>
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                [@rowsAnimation]=""
                *matRowDef="let row; columns: displayedColumns"
              ></tr>
            </table>
            <h2
              *ngIf="dataSource && dataSource.data.length === 0"
              class="no-filter-data"
            >
              {{ "connect.appointment_table.no_result" | translate }}
            </h2>
            <div class="text-right mt-3">
              <button
                mat-raised-button
                color="primary"
                class="ml-auto mr-1"
                [disabled]="previousDisable"
                (click)="paginatorInvoiceRecords('previous')"
              >
                {{ "connect.globals.previous_button_text" | translate }}
              </button>
              <span class="pagination-number">{{ listParams.pageNumber }}</span>
              <button
                mat-raised-button
                color="primary"
                class="ml-auto"
                [disabled]="nextDisable === true"
                (click)="paginatorInvoiceRecords('next')"
              >
                {{ "connect.globals.next_button_text" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
