import * as Sentry from "@sentry/angular-ivy";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment as appConfig } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from './snack-bar.service'
import { TranslateService } from '@ngx-translate/core'
import { DoctorService } from "./doctor.service";
import { Doctor } from "../models/doctor";
Sentry.init({
  dsn: appConfig.sentry_url,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {

  constructor(private snackBar: SnackBarService, private translate: TranslateService, private doctorService: DoctorService)
  {
    this.doctorService.currentDoctor$.subscribe((doctor: Doctor) => {
      if (doctor) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: doctor.id.toString(),
            email: doctor.email,
          });
        });
      }
    });
  }

  extractError(error) {
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }
  
    if (typeof error === "string") {
      return new Error(error);
    }
    if (error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse)
    {
      const httpError = new Error(`HTTP Error: ${error.status} - ${error.statusText}`);
      httpError.name = `HttpError ${error.status}`;
      httpError.message = error.message || `Server returned code ${error.status}`;
      httpError.stack = error.error ? JSON.stringify(error.error) : `No stack trace available.`;
      httpError['headers'] = error.headers;
      return httpError;
    }
  
    const unknownError = new Error("Unknown error occurred.");
    unknownError.name = "UnknownError";
    unknownError.message = JSON.stringify(error);
    unknownError.stack = `Details: ${JSON.stringify(error)}`;
  
    return unknownError;
  }
  
  handleError(error) {
    let extractedError = this.extractError(error);

    if (error instanceof HttpErrorResponse && error.status == 0 && error.statusText == "Unknown Error")
    {
      this.snackBar.open(this.translate.instant('connect.globals.internet_connection_warning'));
    }
    else
    {
      if (!(extractedError instanceof Error))
      {
        extractedError = new Error(`Handled unknown error: ${JSON.stringify(extractedError)}`);
      }
      Sentry.captureException(extractedError);
      console.error(extractedError);
    }
  }
}
