<div class="quill-editor">
  <app-editor
    [patientDetails]="patientDetails"
    [content]="editorContent"
    [template]="template"
    (editorContentData)="getEditorContent($event)"
  ></app-editor>
  <div class="text-right">
    <button
      (click)="saveContent()"
      [ngClass]="
        isEditorBtnCLicked === true
          ? 'btn grey-btn mt-3'
          : 'btn editorBtn max-100 save-btn save-btn-note mt-3'
      "
    >
      {{ "connect.editor.register_note" | translate }}
    </button>
    <button
      class="btn mr-3 btn-outline-cancel mt-3 margin-right cancel-btn-right"
      (click)="resetForm()"
      *ngIf="isCancel"
    >
      {{ "connect.editor.cancel" | translate }}
    </button>
  </div>
</div>
