<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.prescription-table.prescription_record" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <button
            class="medical-record-button"
            (click)="showPrescriptionHeader()"
            mat-button
          >
            <span>{{
              "connect.prescription-table.change_header" | translate
            }}</span>
          </button>
        </div>
      </div>
      <div class="kt-portlet__body">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="mat-elevation-z1"
          class="patient-table responsive-table patient-record-table mt-3"
        >
          <ng-container matColumnDef="patient_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mr-10">{{
                "connect.prescription-table.patient" | translate
              }}</span>
            </th>
            <td
              mat-cell
              *matCellDef="let prescription"
              class="clickable-text"
              nowrap="nowrap"
            >
              {{ prescription.patient_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="created_at">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              nowrap="nowrap"
            >
              <span class="mr-10"
                >{{ "connect.prescription-table.creation_date" | translate }}
              </span>
            </th>
            <td mat-cell *matCellDef="let prescription" nowrap="nowrap">
              <span class="mr-10">
                {{ prescription.created_at | date : "dd-MM-yyyy" }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="medication">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mr-10">{{
                "connect.prescription-table.medication" | translate
              }}</span>
            </th>
            <td mat-cell *matCellDef="let prescription">
              <span
                class="mr-10 medicine-row"
                *ngFor="let medicine of prescription.posologies"
              >
                <p *ngIf="medicine">{{ medicine.medicine_name }}</p>
                <p></p
              ></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef nowrap="nowrap">
              <span class="mr-10"></span>
            </th>
            <td mat-cell *matCellDef="let prescription" nowrap="nowrap">
              <button
                (click)="showMedicineDetails(prescription.id)"
                class="btn add-prescription-btn btn-icon"
              >
                <i class="fas fa-file-alt"></i>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [@rowsAnimation]=""
            (click)="showMedicineDetails(row.id)"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        <h2
          class="no-filter-data"
          *ngIf="dataSource && dataSource.data.length === 0"
        >
          {{ "connect.prescription-table.no_result" | translate }}
        </h2>
      </div>
    </div>
  </div>
</div>
