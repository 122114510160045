import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AttachmentHelper {
  constructor(private translate: TranslateService) {}

  get getDeleteOptionConfig(): SweetAlertOptions {
    return {
      title: this.translate.instant('connect.globals.confirmation_box_title'),
      text: this.translate.instant('connect.globals.confirmation_box_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#808285',
      confirmButtonText: this.translate.instant('connect.globals.confirmation_box_delete_it'),
      cancelButtonText: this.translate.instant('connect.globals.cancel')
    };
  }

  getUrls(attachments: any) {
    return Array.isArray(attachments) ? attachments.map(attachment => attachment.url || attachment.key) : [attachments.url || attachments.key];
  }

  addUrlKey(_this) {
    return _this.previews.map((item) => {
      return {
        ...item,
        url: item.key
      }
    });
  }

  processFiles(oldFiles: any, newFiles: any) {
    if (newFiles.length > 0 && newFiles[0] === "") newFiles.shift();
    return oldFiles.length > 0 ? [...oldFiles, ...newFiles] : newFiles;
  }
}
