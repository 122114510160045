<div class="row mt-5 video-invoice">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.video_invoices.video_invoices" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <div class="video-invoice-wrapper row">
          <span class="video-title p-1">{{
            "connect.video_invoices.video_for_month" | translate
          }}</span>
          <span class="select-date-box row">
            <input
              matInput
              [(ngModel)]="startDate"
              [matDatepicker]="picker"
              placeholder="Choose a date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              (monthSelected)="monthSelectedHandler($event, picker)"
              startView="multi-year"
            ></mat-datepicker>
          </span>
          <button
            mat-raised-button
            color="primary"
            class="create-btn"
            (click)="getInvoices()"
          >
            {{ "connect.video_invoices.display" | translate }}
          </button>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <table
              class="table table-bordered"
              *ngIf="invoiceDetail && invoiceDetail.length > 0"
            >
              <thead>
                <tr>
                  <th>
                    {{ "connect.video_invoices.payment_date" | translate }}
                  </th>
                  <th>
                    {{ "connect.video_invoices.patient" | translate }}
                  </th>
                  <th>
                    {{ "connect.video_invoices.payroll_amount" | translate }}
                  </th>
                  <th>
                    {{ "connect.video_invoices.net" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let booking of invoiceDetail">
                  <td>
                    {{ booking.created_at | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    {{ booking.user_full_name }}
                  </td>
                  <td>
                    {{ booking.total_amount | number : "1.2-2" }}
                    {{ booking.currency }}
                  </td>
                  <td>
                    {{ booking.commission | number : "1.2-2" }}
                    {{ booking.currency }}
                  </td>
                </tr>
                <tr class="total-row">
                  <td></td>
                  <td class="text-right">
                    {{ "connect.video_invoices.total" | translate }}
                  </td>
                  <td>{{ amount_net | number : "1.2-2" }} MAD</td>
                  <td>{{ commission_net | number : "1.2-2" }} MAD</td>
                </tr>
              </tbody>
            </table>
            <h2
              *ngIf="invoiceDetail && invoiceDetail.length === 0"
              class="no-filter-data"
            >
              {{
                "connect.video_invoices.not_have_video_appointments" | translate
              }}
            </h2>
            <div *ngIf="invoiceDetail && invoiceDetail.length > 0">
              <ul>
                <li>{{ "connect.video_invoices.amount_paid" | translate }}</li>
                <li>{{ "connect.video_invoices.net_paid" | translate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
