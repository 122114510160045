import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
import { BookingSlot } from "src/app/models/booking-slot";

@Injectable({
  providedIn: "root",
})
export class PrescriptionsService {
  private prescriptionsUrl: string = "/prescriptions";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.prescriptionsUrl);
  }

  getPrescriptionDetails(prescriptionId: string) {
    return this.http.get(`${this.prescriptionsUrl}/${prescriptionId}`);
  }

  create(prescriptions: [], medicalRecordId: string) {
    return this.http.post(this.prescriptionsUrl, {
      prescriptions,
      medicalRecordId,
    });
  }

  update(prescription_data, prescription_id) {
    return this.http.put(`${this.prescriptionsUrl}/${prescription_id}`, {prescriptions:prescription_data});
  }

  delete(prescriptionId: string) {
    return this.http.delete(`${this.prescriptionsUrl}/${prescriptionId}`);
  }
}
