<div class="row">
  <div class="col-md-12 planning-wrapper">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__body">
        <div class="top-link">
          <ul>
            <li>
              <a (click)="navigateAgenda()" class="cursor-pointer">{{
                "connect.booking_slot_availabilities.my_agenda" | translate
                }}</a>
            </li>
            <li>
              <a (click)="navigateEdit()" [ngClass]="{ active: activeEdit }" class="cursor-pointer">{{
                "connect.booking_slot_availabilities.configuration_planning"
                | translate
                }}</a>
            </li>
            <li>
              <a (click)="navigateEdit()" class="cursor-pointer">{{
                "connect.booking_slot_availabilities.your_current_planning"
                | translate
                }}</a>
            </li>
          </ul>
        </div>
        <h2>
          {{
          "connect.booking_slot_availabilities.configuration_planning"
          | translate
          }}
        </h2>
        <div class="note-bx">
          {{
          "connect.booking_slot_availabilities.already_created_your_schedule"
          | translate
          }}
        </div>
        <ng-container *ngIf="currentDoctor">
          <div *ngIf="currentDoctor.clinic" class="p-3 pb-0">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'connect.booking_slot_availabilities.please_select_doctor' | translate }}</mat-label>
              <mat-select
                [(value)]="clinic_resource_id"

              >
                  <mat-option [value]="resource._id" *ngFor="
                  let resource of currentDoctor.clinic_resources;
                  let ind = index
                ">{{ resource.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column">
              <ul class="availabilities_list">
                <li class="availabilities_list_row">
                  <div class="row w-full">
                    <div class="col-3 d-xs-none">

                    </div>
                    <div class="col-5 col-sm-4">
                      {{"connect.booking_slot_availabilities.from" | translate}}
                    </div>
                    <div class="col-5 col-sm-4">
                      {{"connect.booking_slot_availabilities.to" | translate}}
                    </div>
                  </div>
                </li>
                <ng-container *ngFor="let day of booking_availabilities_planner | keyvalue">
                  <li class="availabilities_list_row">
                    <div class="row w-full">
                      <div class="col-12 col-sm-3 d-flex flex-column justify-content-center">
                        <mat-checkbox [(ngModel)]="day.value.day_on" class="example-margin">{{ "connect.booking_slot_availabilities."+days[day.key - 1] | translate}}</mat-checkbox>
                      </div>
                      <div class="col-5 col-sm-4">
                        <mat-form-field class="w-full">
                          <input type="time" 
                            matInput
                            [(ngModel)]="day.value.starts_at"
                            [matAutocomplete]="auto"
                            [disabled]="!day.value.day_on">
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of times" [value]="option.value">
                              {{option.label}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="col-5 col-sm-4">
                        <mat-form-field class="w-full">
                          <input type="time" 
                            matInput 
                            [min]="day.value.starts_at"
                            [(ngModel)]="day.value.ends_at"
                            [matAutocomplete]="auto" 
                            [disabled]="!day.value.day_on">
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of times" [value]="option.value">
                              {{option.label}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
          </div>
        </div>
        <div class="row date-format order-lg-3 order-md-3 order-sm-3 order-1">
          <div class="col-sm-3 col-6">
            <label>{{
              "connect.booking_slot_availabilities.average_length_appointment"
              | translate
              }}</label>
          </div>
          <div class="col-sm-1 col-3">
            <select class="form-control" [(ngModel)]="appointment_length">
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="35">35</option>
              <option value="40">40</option>
              <option value="45">45</option>
              <option value="50">50</option>
              <option value="55">55</option>
              <option value="60">60</option>
              <option value="90">90</option>
            </select>
          </div>
          <div class="col-sm-1 col-3">
            <label>{{
              "connect.booking_slot_availabilities.minutes" | translate
              }}</label>
          </div>
        </div>
        <div class="row order-lg-4 order-md-4 order-sm-4 order-4 mt-3">
          <div class="col-md-2">
            <button mat-raised-button color="primary" (click)="submitForm()" class="mr-auto">
              {{ "connect.booking_slot_availabilities.save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
