<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template medical-record payment-sms">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label"></div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <div class="row justify-content-center">
          <div class="col-md-12 paymentprocess">
            <div id="payment-info-form mb-2" *ngIf="invoiceForm">
              <form [formGroup]="invoiceForm">
                <h1 class="text-center">
                  <strong>
                    {{ "connect.payment_sms.tariff_plans" | translate }}
                  </strong>
                </h1>
                <div class="row payment-sms justify-content-center">
                  <div class="col-md-8 col-md-offset-2">
                    <div class="row">
                      <div
                        class="col-md-3 col-sm-3 col-6"
                        *ngFor="let plan of plans"
                      >
                        <div class="offer">
                          <div class="price">
                            <h2>&nbsp;{{ plan.sms_balance }} SMS</h2>
                          </div>
                          <div class="price2">
                            <p>&nbsp;{{ plan.price }}&nbsp; DHS</p>
                            <h6>
                              <input
                                class="dabadoc-radio"
                                type="radio"
                                formControlName="plan_id"
                                [value]="plan.id"
                              />
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-8 col-sm-offset-2">
                    <h3 class="mb-5">
                      {{
                        "connect.payment_sms.provide_payment_information"
                          | translate
                      }}
                    </h3>
                    <div class="row">
                      <div class="col-sm-6">
                        <mat-form-field>
                          <input
                            matInput
                            placeholder="{{
                              'connect.payment_sms.first_name' | translate
                            }}"
                            formControlName="first_name"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                        <mat-form-field>
                          <input
                            matInput
                            placeholder="{{
                              'connect.payment_sms.last_name' | translate
                            }}"
                            formControlName="last_name"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <mat-form-field>
                          <input
                            matInput
                            placeholder="{{
                              'connect.payment_sms.email' | translate
                            }}"
                            formControlName="email"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group edit-profile-phone">
                          <app-phone-number
                            controlName="phone_number"
                          ></app-phone-number>
                          <mat-hint
                            *ngIf="invoiceForm.get('phone_number').errors"
                            class="phone-error"
                          >
                            <mat-error>{{
                              "connect.user_profiles.errors.phone_number"
                                | translate
                            }}</mat-error>
                          </mat-hint>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <mat-form-field>
                          <input
                            matInput
                            placeholder="{{
                              'connect.payment_sms.address' | translate
                            }}"
                            formControlName="address"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="paymentmethod">
                        <label>{{
                          "connect.payment_sms.method_of_payment" | translate
                        }}</label>
                      </div>
                      <div class="paymentmethod text-center">
                        <div class="row align-items-center">
                          <input
                            [checked]="paymentMethod"
                            class="dabadoc-radio"
                            type="radio"
                            (click)="changePaymentMethod('MTC')"
                          />
                          <img src="../../assets/images/mtc.jpg" alt="image" />
                        </div>
                        <h5 class="row align-items-center">
                          {{ "connect.payment_sms.bank_card" | translate }}
                        </h5>
                      </div>
                      <div class="paymentmethod text-center">
                        <div class="row align-items-center">
                          <input
                            [checked]="!paymentMethod"
                            class="dabadoc-radio"
                            method_id="Binga"
                            type="radio"
                            (click)="changePaymentMethod('Binga')"
                          />
                          <img
                            src="../../assets/images/wafacash.jpg"
                            alt="image"
                          />
                        </div>
                        <h5 class="row">
                          {{ "connect.payment_sms.wafa_cash" | translate }}
                        </h5>
                      </div>
                      <input
                        value="MTC"
                        type="hidden"
                        name="invoice[payment_method]"
                        id="invoice_payment_method"
                      />
                      <input
                        value="sms"
                        type="hidden"
                        name="invoice[type]"
                        id="invoice_type"
                      />
                    </div>
                    <div class="form-group mt-3">
                      <label for="cgv" class="d-flex flex-row">
                        <input
                          formControlName="cgv"
                          type="checkbox"
                          style="width: auto"
                          required
                        />
                        <span
                          >{{
                            "connect.payment_sms.read_and_accept" | translate
                          }}
                          <a
                            target="_blank"
                            href="/conditions-generales-de-vente"
                            >{{
                              "connect.payment_sms.general_conditions"
                                | translate
                            }}</a
                          ></span
                        >
                      </label>
                    </div>
                    <div class="form-group">
                      <button
                        type="button"
                        class="btn mr-3 save-btn"
                        [disabled]="!invoiceForm.valid"
                        (click)="createInvoice()"
                      >
                        {{ "connect.payment_sms.validate" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-12 text-center paymentinfologo">
              <a
                href="https://seal.verisign.com/splash?form_file=fdf/splash.fdf&amp;dn=csp-front.maroccommerce.com&amp;lang=en"
                target="_blank"
              >
                <img
                  alt="Verisign"
                  border="0"
                  src="/assets/cmi/verisignfr_petit-f8808bbab20bad84ab79c398463e60fbb91183c50f39010a37369f4bb0be9fd8.gif"
                />
              </a>
              <a href="http://www.maroctelecommerce.com" target="_blank">
                <img
                  alt="Maroc Telecommerce"
                  border="0"
                  src="/images/cmi/logomtc_petit.gif"
                />
              </a>
              <a href="http://www.cmi.co.ma" target="_blank">
                <img
                  alt="Centre Monétique Interbancaire"
                  border="0"
                  src="/images/cmi/logo-CMI-petit.gif"
                />
              </a>
              <a
                href="http://www.visacemea.com/uv/online_security.jsp"
                target="_blank"
              >
                <img
                  alt="Verified by Visa"
                  border="0"
                  src="/assets/cmi/tn_verified_by_visa_petit-f2f2644253ea18d5255d8214d79128d76a9fae403af93e6b26b4b0a8e69e5ecf.gif"
                />
              </a>
              <a
                href="http://www.mastercardbusiness.com/mcbiz/index.jsp?template=/orphans&amp;content=securecodepopup"
                target="_blank"
              >
                <img
                  alt="MasterCard SecureCode"
                  border="0"
                  src="/assets/cmi/secure_code_logo_petit-56b47586ca1581372d5bf4425ccd10576d153d890c370f22775c685c34f0dac7.gif"
                />
              </a>
            </div>
            <form
              id="paymentForm"
              action="{{ cmis.actionSLK }}"
              method="post"
              name="paymentForm"
              *ngIf="cmis"
            >
              <input
                name="{{ key }}"
                type="hidden"
                value="{{ cmis.params_hash[key] }}"
                *ngFor="let key of keys; let i = index"
              />
              <input
                name="HASH"
                type="hidden"
                value="{{ cmis.transaction_hash }}"
              />
            </form>
            <form
              id="paymentForm"
              action="{{ binga.actionUrl }}"
              method="post"
              name="paymentForm"
              *ngIf="binga"
            >
              <input name="apiVersion" type="hidden" value="1.1" />
              <input
                name="{{ key }}"
                type="hidden"
                value="{{ binga[key] }}"
                *ngFor="let key of keys; let i = index"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
