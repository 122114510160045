import { Component, ViewChild, OnInit, ViewEncapsulation } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SmsLogsService } from "src/app/services/sms-logs.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { SmsLog } from "src/app/models/smsLog.model";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sms-details",
  templateUrl: "./sms-details.component.html",
  styleUrls: ["./sms-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SmsDetailsComponent implements OnInit {
  displayedColumns: string[] = ["date", "sms_contents", "phone_number", "user"];
  dataSource;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  nextDisable: boolean = false;
  previousDisable: boolean = false;
  listParams = {
    pageNumber: 1,
  };
  constructor(
    private smsLogsService: SmsLogsService,
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler
  ) {}

  getSmsLogs(params) {
    this.listParams.pageNumber == 1
      ? (this.previousDisable = true)
      : (this.previousDisable = false);
    const filter = "page=" + params;
    this.smsLogsService.get(filter).subscribe(
      (data: SmsLog[]) => {
        data.length < 10
          ? (this.nextDisable = true)
          : (this.nextDisable = false);
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  paginatorSmsLogs(key: string) {
    key == "previous"
      ? this.listParams.pageNumber--
      : this.listParams.pageNumber++;
    this.getSmsLogs(this.listParams.pageNumber);
  }

  ngOnInit() {
    this.getSmsLogs((this.listParams.pageNumber = 1));
  }
}
