import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
import { BookingSlot } from "src/app/models/booking-slot";

@Injectable({
  providedIn: "root",
})
export class MedicinesService {
  private medicinesUrl: string = "/medicines";

  constructor(private http: HttpWrapper) {}

  get(name: string) {
    return this.http.get(this.medicinesUrl + "?name=" + name);
  }
}
