import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class NotificationServices {
  private subscribeToNotification: string = "/notification/subscribe";
  private getNotitfication: string = "/notification/push";

  constructor(private http: HttpWrapper) {}

  subscibeUser(body: any) {
    return this.http.post(this.subscribeToNotification, body);
  }

  pushNotifictaionUrl() {
    return this.http.get(this.getNotitfication);
  }
}
