<div class="medical-record-table">
  <form [formGroup]="medical_record_form">
    <div class="search-container">
      <div class="row">
        <div class="col-md-12 p-0 search-top-bar">
          <div
            class="top-toggle-bar"
            [ngClass]="[this.event ? 'show-bar' : '']"
            (click)="toggleBar()"
          >
            <h1>{{ "connect.globals.search" | translate }}</h1>
            <i class="fa fa-angle-down"></i>
          </div>
        </div>
      </div>
      <div
        class="row my-filter-1"
        id="search-filters"
        [ngClass]="[this.event ? 'show-filter' : '']"
      >
        <div class="col">
          <h4 class="heading-1">{{ 'connect.medical_record.general_info' | translate }}</h4>
          <div class="input-group no-wrap">
            <input class="hourly_field form-control" placeholder="{{ 'connect.medical_record.ref_id' | translate }}" type="text" name="" formControlName="ref_id">
          </div>
          <div class="input-group no-wrap">
            <input class="hourly_field form-control" placeholder="{{ 'connect.medical_record.cell_phone' | translate }}" type="number" name="" formControlName="phone_number">
          </div>
        </div>
        <div class="col-md-3">
          <h4 class="heading-1">
            {{ "connect.medical_record.date_created" | translate }}
          </h4>
          <div class="input-group no-wrap">
            <input
              class="hourly_field form-control"
              type="text"
              placeholder="{{ 'connect.medical_record.from' | translate }}"
              readonly
              [matDatepicker]="from_date_picker"
              name=""
              formControlName="medical_record_from"
            />
            <div class="input-group-append">
              <mat-datepicker-toggle
                matSuffix
                [for]="from_date_picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #from_date_picker></mat-datepicker>
            </div>
          </div>
          <div class="input-group no-wrap mt-10">
            <input
              class="hourly_field form-control"
              type="text"
              placeholder="{{ 'connect.medical_record.to' | translate }}"
              readonly
              [matDatepicker]="to_date_picker"
              name=""
              formControlName="medical_record_to"
            />
            <div class="input-group-append">
              <mat-datepicker-toggle
                matSuffix
                [for]="to_date_picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #to_date_picker></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="col">
          <h4 class="heading-1">
            {{ "connect.medical_record.age" | translate }}
          </h4>
          <div class="input-group no-wrap">
            <input
              class="hourly_field form-control"
              type="number"
              placeholder="{{ 'connect.medical_record.from' | translate }}"
              name=""
              formControlName="from_age"
            />
          </div>
          <div class="input-group no-wrap">
            <input
              class="hourly_field form-control"
              type="number"
              placeholder="{{ 'connect.medical_record.to' | translate }}"
              name=""
              formControlName="to_age"
            />
          </div>
        </div>
        <div class="col">
          <h4 class="heading-1">
            {{ "connect.medical_record.patient_name" | translate }}
          </h4>
          <div class="input-group no-wrap">
            <input
              class="hourly_field form-control"
              type="text"
              placeholder="{{
                'connect.medical_record.first_name' | translate
              }}"
              name=""
              formControlName="first_name"
            />
          </div>
          <div class="input-group no-wrap">
            <input
              class="hourly_field form-control"
              type="text"
              placeholder="{{ 'connect.medical_record.last_name' | translate }}"
              name=""
              formControlName="last_name"
            />
          </div>
        </div>
        <div class="col">
          <h4 class="heading-1">
            {{ "connect.appointment_table.appointment_actions" | translate }}
          </h4>
          <div class="btn-group inner-action-buttons col">
            <button
              class="btn btn-brand btn-block"
              type="button"
              (click)="filterBookings(this.listParams.pageNumber)"
            >
              <i class="fa fa-search"></i><br />
            </button>
            <button
              type="button"
              class="btn btn-light btn-block border text-center reset-btn col"
              (click)="resetData()"
            >
              <i class="fa fa-times"></i><br />
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hr />
  <section class="content-wrapper appointments-container">
    <div class="appointment-table table mat-elevation-z0 desktop-filter-table">
      <mat-table
        #table
        [dataSource]="dataSource"
        matSort
        class="table-cell-block flex-mat-table"
      >
        <ng-container matColumnDef="ref_id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <h6 class="table-heading">{{ 'connect.medical_record.ref_id' | translate }}</h6>
          </mat-header-cell>
          <mat-cell *matCellDef="let patient" class="mat-block mat-border-right">
            <b>{{patient.ref_id}}</b>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="first_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <h6 class="table-heading">
              {{ "connect.medical_record.patient" | translate }}
            </h6>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let patient"
            class="mat-block mat-border-right"
          >
            <a
              (click)="showMedicalRecodsDetails(patient.id)"
              class="clickable-text mr-10"
              >{{ patient.first_name }} {{ patient.last_name }}</a
            >
            <div
              class="multi-clr-btns-box multi-clr-btn-intable"
              *ngIf="patient"
            >
              <ul>
                <li *ngFor="let label of patient.patient_labels">
                  <button
                    class="multi-clr-btn btn-white-color"
                    [style.backgroundColor]="label.color"
                  >
                    {{ label.name }}
                  </button>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gender">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <h6 class="table-heading">
              {{ "connect.medical_record.gender" | translate }} /
              {{ "connect.medical_record.age" | translate }}
            </h6>
          </mat-header-cell>
          <mat-cell *matCellDef="let patient" class="mat-block">
            <span
              class="btn icons confirm-btn btn-icon pointer"
              (click)="showGenderInformation(patient)"
            >
              <i class="fa fa-info-circle"></i>
            </span>
            <span class="mr-10 hide-mobile">
              <span *ngIf="patient.gender">{{
                "connect.medical_record." + patient.gender | translate
              }}</span>
              <span *ngIf="!patient.gender">-</span>
              <span *ngIf="patient.date_of_birth">
                / {{ ageFrom(patient.date_of_birth) }}
                {{ "connect.medical_record.years" | translate }} ({{
                  patient.date_of_birth | date : "dd/MM/yyyy"
                }})
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone_number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <h6 class="table-heading">
              {{ "connect.medical_record.contact_information" | translate }}
            </h6>
          </mat-header-cell>
          <mat-cell *matCellDef="let patient" class="mat-block">
            <span class="btn icons contact-btn btn-icon pointer">
              <a href="tel:{{ patient.phone_number }}">
                <i class="fa fa-phone-square"></i>
              </a>
            </span>
            <span class="mr-10 hide-mobile">
              <p class="mr-10" *ngIf="!patient.phone_number && !patient.email">
                -
              </p>
              <p
                class="mr-10"
                *ngIf="(patient && patient.phone_number) || patient.email"
              >
                <a href="tel:{{ patient.phone_number }}">{{
                  patient.phone_number
                }}</a>
                <br />
                <span *ngIf="patient && !patient.phone_number && !patient.email"
                  >/</span
                >
                <a href="mailto:{{ patient.email }}">{{ patient.email }}</a>
              </p>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="actions-btns">
            <h6 class="table-heading text-center">
              {{ "connect.medical_record.actions" | translate }}
            </h6>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let patient"
            class="actions-btns mat-block centralized-btns"
          >
            <span
              class="mr-10 btn cancel-btn btn-icon pointer"
              (click)="deleteMedicalRecord(patient.id)"
            >
              <i class="fas fa-trash"></i>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="groupHeader" class="start_date">
          <mat-cell class="mat-group-cell" *matCellDef="let group"
            ><b>{{ group.created_at | customDate }} </b></mat-cell
          >
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          matRipple
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        >
        </mat-row>
        <mat-row
          class="mat-group-row start_date"
          *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
        >
        </mat-row>
      </mat-table>

      <ng-container *ngIf="dataSource && dataSource.data.length === 0">
        <h2 class="no-filter-data">
          {{ "connect.appointment_table.no_result" | translate }}
        </h2>
      </ng-container>
      <div class="text-right mt-2">
        <button
          mat-raised-button
          color="primary"
          class="ml-auto mr-1"
          [disabled]="previousDisable"
          (click)="paginatorMedicalRecords('previous')"
        >
          {{ "connect.globals.previous_button_text" | translate }}
        </button>
        <span class="pagination-number">{{ listParams.pageNumber }}</span>
        <button
          mat-raised-button
          color="primary"
          class="ml-auto"
          [disabled]="nextDisable === true"
          (click)="paginatorMedicalRecords('next')"
        >
          {{ "connect.globals.next_button_text" | translate }}
        </button>
      </div>
    </div>
  </section>
</div>
