import { Component, Input, ViewEncapsulation } from "@angular/core";
import { PaymentState } from "src/app/enums/payment.enum";
import { Doctor } from "src/app/models/doctor";
import { environment as appConfig } from "../../../environments/environment";

@Component({
  selector: "doctor-info",
  templateUrl: "./doctor-info.component.html",
  styleUrls: ["./doctor-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorInfoComponent {
  paymentState = PaymentState;
  isASubscrible: boolean = true;
  isAPayingSubscriber: boolean = false;
  @Input() doctor: Doctor;
  // TO ADD Nums For Morocco
  customerSupportNumber: string = "06-08-08-56-56";
  customerSupportEmail: string = "contact@dabadoc.com";
  doctorProfile: string = "/doctor/profile/edit";
  isCopied: boolean = false;

  constructor(){}

  ngOnInit() {
    if (
      appConfig.paid_countries.some(
        (country) => country === this.doctor.country
      )
    ) {
      this.isAPayingSubscriber = true;
    }
    const Difference_In_Time =
      new Date(this.doctor.premium_expiration_date).getTime() -
      new Date().getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Number(Difference_In_Days.toFixed()) < 30) {
      this.isASubscrible = false;
    }
    this.setSupportInformation()
  }

  copyToClipboard(copyRef: HTMLInputElement) {
    const input = copyRef.querySelector('input');
    input.select();
    document.execCommand('copy');
    input.blur();
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1500);
  }

  setSupportInformation() {
    const supportDetails = {
      'CI': {
        number: "0808 - 0707",
        email: "orangebusiness.oci@orange.com"
      },
      'MA': {
        number: "06-08-08-56-56",
        email: "contact@dabadoc.com"
      }
    };
  
    const details = supportDetails[this.doctor.country];
    if (details) {
      this.customerSupportNumber = details.number;
      this.customerSupportEmail = details.email;
    }
  }
}
