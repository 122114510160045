import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { PartnerService } from 'src/app/services/partner.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Partner } from 'src/app/models/partner.model';
@Component({
  selector: 'app-partners',
  templateUrl: "./partners.component.html",
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  currentDoctorPartners: Partner[] = [];
  currentDoctorConditions: string;
  partners: Partner[] = [];
  partnerForm: FormGroup;
  saving = false;

  constructor(
    private partnerService: PartnerService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: SnackBarService
  ) {}

  ngOnInit()
  {
    this.partnerForm = this.fb.group({});
    this.loadPartners();
  }

  initFormControls()
  {
    this.partners.forEach(partner => {
      const control = new FormControl(this.checkedPartners(partner._id));
      control['isCheckbox'] = true;
      this.partnerForm.addControl(partner._id, control);
    });
    this.partnerForm.addControl('conditions', new FormControl(this.currentDoctorConditions));
  }

  loadPartners()
  {
    this.partnerService.partners().subscribe({
      next: (data: any) => {
        this.partners = data.partners;
        this.currentDoctorPartners = data.current_doctor_partners
        this.currentDoctorConditions = data.current_doctor_conditions;
        this.initFormControls(); 
      },
      error: (err) => {
        this.snackBar.open(this.translate.instant('connect.partners.error_loading_partners'));
      }
    });
  }

  savePartners()
  {
    this.saving = true;
    const selectedPartnerIds = Object.keys(this.partnerForm.controls)
    .filter(key => this.partnerForm.controls[key].value && this.partnerForm.controls[key]['isCheckbox']);

    this.partnerService.updateDoctorPartners(selectedPartnerIds, this.currentDoctorConditions).subscribe({
      next: (data: any) => {
        this.snackBar.open(this.translate.instant('connect.partners.updated_successfully'));
      },
      error: (err) => {
        this.snackBar.open(this.translate.instant('connect.partners.error_upadting_partners'));
      }
    }).add(() => 
    {
      this.saving = false;
    });
  }

  checkedPartners(partnerId: string)
  {
    return this.currentDoctorPartners.some(p => p._id == partnerId);
  }

  togglePartnerSelection(event, partnerId)
  {
    const control = this.partnerForm.get(partnerId);
    if (control) {
      control.setValue(event.target.checked);
    }
  }

  updateConditions(event)
  {
    this.currentDoctorConditions = event.target.value;
  }

}
