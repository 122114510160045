<h1 mat-dialog-title *ngIf="data.actionType == 'create'">{{ 'connect.patient_labels.create_patient_label' | translate }}</h1>
<h1 mat-dialog-title *ngIf="data.actionType == 'edit'">{{ 'connect.patient_labels.edit_patient_label' | translate }}</h1>
<form [formGroup]="formData" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="standard" class="w-100">
                    <mat-label>{{ 'connect.patient_labels.patient_label' | translate }}</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="null" class="w-100">
                    <mat-label>{{ "connect.patient_labels.select_color" | translate }}</mat-label>
                    <input type="color" matInput formControlName="color" style="height: 35px;" required>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-end">
        <button mat-button mat-dialog-close type="button">{{ 'connect.patient_labels.cancel' | translate}}</button>
        <button mat-flat-button color="primary" type="submit">{{ "connect.patient_labels.save_patient_label" | translate }}</button>
    </div>
</form>