<div class="doctor-stats-card">
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <div class="stats-icon">
        <i [class]="icon"></i>
      </div>
      <span class="stats-info">
        <h1>{{ stats }}</h1>
      </span>
      <span class="stats-title">
        <p>{{ statsText }}</p>
      </span>
    </mat-card-content>
  </mat-card>
</div>
