import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";
import { PlanTypeFilter } from "../enums/planTypeFilter.enum";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  [x: string]: any;
  private PaymentUrl: string = "/payment";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.PaymentUrl);
  }

  getPlans(type: PlanTypeFilter) {
    return this.http.get(`${this.PaymentUrl}/plans?type=${type}`)
  }
  create(invoice: any) {
    return this.http.post(this.PaymentUrl, { invoice: invoice });
  }
}
