import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CommentsService } from "src/app/services/comments.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./comment-details.component.html",
  styleUrls: ["./comment-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommentDetailsComponent implements OnInit {
  review: string[] = [];
  CommentID: string = this.route.snapshot.params.id.toString();
  doctorComment: any = {};
  @Input() currentDoctor;

  constructor(
    private route: ActivatedRoute,
    private commentsService: CommentsService,
    private snackBar: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getCommentDetails();
  }

  getCommentDetails() {
    return this.commentsService.getComment(this.CommentID).subscribe(
      (data: string[]) => {
        this.review = data;
      },
      (error) => this.snackBar.open("An error occurd")
    );
  }

  updateComment() {
    return this.commentsService
      .update(this.CommentID, this.doctorComment)
      .subscribe(
        (data) => {
          this.snackBar.open(
            this.translate.instant("connect.doctor_comments.comment_created")
          );
        },
        (error) => this.snackBar.open("An error occurd")
      );
  }
}
