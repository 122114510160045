<div class="dashboard doctor-info-dashboard">
  <div class="container" *ngIf="currentDoctor">
    <div class="row equal mt-md-5">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 m-0 p-2">
        <doctor-info [doctor]="currentDoctor"></doctor-info>
      </div>
      <div
        *ngIf="currentDoctorStats.days_left <= 30"
        class="col-lg-6 col-md-12 col-sm-12 col-12 m-0 p-2"
      >
        <doctor-stats
          [stats]="currentDoctorStats.days_left"
          icon="fa fa-bell fa-2x "
          statsText="{{ 'connect.dashboard.days_left' | translate }}"
        ></doctor-stats>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="currentDoctorStats.today_booking_slot"
          icon="fa  fa-calendar"
          statsText="{{ 'connect.dashboard.today_booking_slot' | translate }}"
        ></doctor-stats>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="currentDoctorStats.week_booking_slot"
          icon="fa  fa-calendar"
          statsText="{{ 'connect.dashboard.week_booking_slot' | translate }}"
        ></doctor-stats>
      </div>

      <div class="header-inner container h4 pt-5">
        {{ "connect.dashboard.stats_since_creation" | translate }}
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="currentDoctorStats.booking_slots_count"
          icon="fa fa-info-circle"
          statsText="{{ 'connect.dashboard.booking_slots' | translate }}"
        ></doctor-stats>
      </div>
      <div
        *ngIf="currentDoctorStats.video_booking_slots_count"
        class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2"
      >
        <doctor-stats
          [stats]="currentDoctorStats.video_booking_slots_count"
          icon="fa fa-video-camera"
          statsText="{{ 'connect.dashboard.video_booking_slots' | translate }}"
        ></doctor-stats>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="currentDoctorStats.sms_sent"
          icon="fa fa-comments-o"
          statsText="{{ 'connect.dashboard.sms_sent' | translate }}"
        ></doctor-stats>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="currentDoctorStats.total_profile_visits"
          icon="fa fa-eye"
          statsText="{{ 'connect.dashboard.profile_visits' | translate }}"
        ></doctor-stats>
      </div>
    </div>

    <!-- table -->
    <div class="row mt-4 mb-3">
      <div class="col-md-6">
        <h3 class="table-heading">
          {{ "connect.dashboard.my_meetings_today" | translate }}
        </h3>
      </div>
      <div class="col-md-6 mt-2 mb-2 mt-md-0 mb-md-0">
        <app-print-button></app-print-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table *ngIf="currentDoctor"></app-table>
      </div>
    </div>
  </div>
</div>
