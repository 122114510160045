import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { MedicalRecordNote } from "src/app/models/medicalRecordNote.model";
import sort from "src/app/helpers/sort";
import { UppyDialogComponent } from "../../../components/uppy-dialog/uppy-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { BookingSlot } from "src/app/models/booking-slot";
import { TranslateService } from "@ngx-translate/core";
import { PreviewImageHelper } from "src/app/helpers/preview_image_helper";
import { AttachmentHelper } from "src/app/helpers/attachment_helper";
import Swal from 'sweetalert2'

@Component({
  templateUrl: "./patient-details.component.html",
  styleUrls: ["./patient-details.component.scss"],
})
export class PatientDetailsComponent implements OnInit {
  patientDetails: string[] = [];
  patientEvents: MedicalRecordNote[] = [];

  MedicalRecordID: string = this.route.snapshot.params.id.toString();

  patientNoteContent: string = "";
  doctorId: string;
  attachments: any[] = [];
  previews: any[] = [];
  isToggled: boolean = true;
  listParams = {
    pageNumber: 0,
  };
  isPatient: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private medicalRecordService: MedicalRecordService,
    private snackBar: SnackBarService,
    private doctorService: DoctorService,
    public dialog: MatDialog,
    private attachmentHelper: AttachmentHelper,
    private translate: TranslateService
  ) {}

  getPatientDetails() {
    return this.medicalRecordService.get(this.MedicalRecordID).subscribe(
      (patientData: string[]) => {
        this.patientDetails = patientData;
        this.isPatient = true;
      },
      (error) => this.snackBar.open("An error occurd")
    );
  }

  openDeletePreviewDialog(previews)
  {
    var _this = this;
    const urls = this.attachmentHelper.getUrls(previews);
    Swal.fire(this.attachmentHelper.getDeleteOptionConfig).then((result) => {
      if (result.value) {
        this.medicalRecordService.deleteAttachments(urls, this.MedicalRecordID, -1).subscribe(
          (success:any) => {
            this.snackBar.open(this.translate.instant('connect.templates.attachment(s)_deleted'))
            _this.previews = _this.previews.filter(_preview => !urls.includes(_preview.url));
            if (this.attachments.length > 0 && this.attachments[0] == "") this.attachments.shift()
            this.attachments = this.attachments.filter(attachment => !urls.includes(attachment.url || attachment.key));
          },
          error =>
          {
            this.snackBar.open(this.translate.instant('connect.globals.try_again'))
          }
        );
      }
    });
  }

  uppyOpenDialog(): void {
    var _this = this;
    const dialogRef = this.dialog.open(UppyDialogComponent, {
      width: "400px",
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.medicalRecordId = this.MedicalRecordID;
    dialogRef.componentInstance.doctorId = this.doctorId;
    if (dialogRef.componentInstance) dialogRef.componentInstance.attachments = [""];
    if (dialogRef.componentInstance) dialogRef.componentInstance.previews = [""];
    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !_this) return;
      _this.attachments = this.attachmentHelper.processFiles(_this.attachments, result['attachments']);
      _this.previews = this.attachmentHelper.processFiles(_this.previews, result['previews']);
      if (result['previews']?.length > 0)
      {
        _this.previews = this.attachmentHelper.addUrlKey(_this);
      }
    });
  }

  toggleContent(evt) {
    this.isToggled = !this.isToggled;
  }

  getTimelineEvents(page): any {
    if (page == 0) {
      this.patientEvents = [];
    }
    this.medicalRecordService.getEvents(this.MedicalRecordID, page).subscribe(
      (patientNotesData: MedicalRecordNote[]) => {
        patientNotesData.sort((a, b) =>
          b.created_at.localeCompare(a.created_at)
        );
        this.patientEvents.push(...patientNotesData);
        return this.patientEvents;
      },
      (error) =>
        this.snackBar.open(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  previewImg(preview): string {
    return PreviewImageHelper.getImagePreview(preview);
  }

  deleteMedicalRecord() {
    this.medicalRecordService.delete(this.MedicalRecordID).subscribe(
      (data) => data,
      (error) =>
        this.snackBar.open(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  refreshAfterSuccess() {
    return this.getTimelineEvents((this.listParams.pageNumber = 0));
  }

  getEditorContent(evt) {
    this.patientNoteContent = evt;
    return this.medicalRecordService
      .AddNote(this.MedicalRecordID, {
        content: this.patientNoteContent,
        attachments: this.attachments,
      })
      .subscribe(
        (data) => {
          this.previews = [];
          this.attachments = [];
          this.snackBar.open(
            this.translate.instant(
              "connect.patient_details.note_added_successffully"
            )
          );
          return this.refreshAfterSuccess();
        },
        (error) =>
          this.snackBar.open(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }

  ngOnInit() {
    if (history.state.data === "prescription") {
      this.isToggled = false;
    }
    this.getPatientDetails();
    this.getTimelineEvents(this.listParams.pageNumber);
    this.doctorService.currentDoctor$.subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.doctorId = currentDoctor.id;
      }
    });
  }
}
