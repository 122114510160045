<div>
  <div class="booking-modal-header modal-header">
    <div>
      <h4>{{ "connect.medical-dailog.new_patient_record" | translate }}</h4>
    </div>
    <div>
      <button type="button" class="close" (click)="onNoClick()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div [formGroup]="patientForm">
      <div class="form-group">
        <label class="mr-5"
          >{{ "connect.medical-dailog.sex" | translate }}:</label
        >
        <mat-radio-group formControlName="gender">
          <mat-radio-button class="mr-5" value="female">{{
            "connect.medical-dailog.female" | translate
          }}</mat-radio-button>
          <mat-radio-button value="male">{{
            "connect.medical-dailog.male" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-group">
        <label for=""
          >{{ "connect.medical-dailog.first_name" | translate }}:</label
        >
        <mat-form-field class="example-full-width">
          <input
            formControlName="last_name"
            matInput
            placeholder="{{
              'connect.medical-dailog.add_patient_first_name' | translate
            }}"
          />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for=""
          >{{ "connect.medical-dailog.last_name" | translate }}:</label
        >
        <mat-form-field class="example-full-width">
          <input
            formControlName="first_name"
            matInput
            placeholder="{{
              'connect.medical-dailog.add_patient_last_name' | translate
            }}"
          />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label>{{ "connect.medical-dailog.date_of_birth" | translate }}:</label>
        <mat-form-field class="example-full-width">
          <input
            formControlName="date_of_birth"
            matInput
            [matDatepicker]="picker"
            placeholder="{{
              'connect.medical-dailog.use_format' | translate
            }} DD/MM/YYYY"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div>
      <button
        [disabled]="patientForm.invalid"
        (click)="createNewMedicalRecord()"
        class="save-btn btn-block"
        mat-button
        cdkFocusInitial
      >
        {{ "connect.medical-dailog.add" | translate }}
      </button>
    </div>
  </div>
</div>
