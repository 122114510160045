<div class="container">
  <div class="row">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__body doctor-info" *ngIf="prescriptionDetail">
        <app-editor
          [content]="prescriptionDetail.content"
          [disabled]="disabled"
        ></app-editor>
      </div>
    </div>
  </div>
</div>
