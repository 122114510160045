import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-print-button",
  templateUrl: "./print-button.component.html",
  styleUrls: ["./print-button.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PrintButtonComponent {
  printTable() {
    window.print();
    return false;
  }
}
