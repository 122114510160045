import {
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { BookingAvailabilitiesService } from "src/app/services/booking-availabilities.service";
import { Doctor } from "src/app/models/doctor";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as moment from "moment";

@Component({
  templateUrl: "./availabilities-new-planner.component.html",
  styleUrls: ["./availabilities-new-planner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AvailabilitiesNewPlannerComponent implements OnInit {
  spinners = false;
  activeEdit: boolean = true;
  appointment_length: Number;
  weekDates: Array<any> = [];
  currentDoctor: Doctor;
  clinic_resource_id: String|null;
  days = [
    "monday",
    "tuesday",
    "wendnesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  times: Array<string> = [];
  booking_availabilities_planner: any = {
    1:{starts_at:'09:00', ends_at:'17:00', day_on:true},
    2:{starts_at:'09:00', ends_at:'17:00', day_on:true},
    3:{starts_at:'09:00', ends_at:'17:00', day_on:true},
    4:{starts_at:'09:00', ends_at:'17:00', day_on:true},
    5:{starts_at:'09:00', ends_at:'17:00', day_on:true},
    6:{starts_at:'09:00', ends_at:'13:00', day_on:true},
    7:{starts_at:'09:00', ends_at:'13:00', day_on:false},
  };

  

  toggleSpinners() {
    this.spinners = !this.spinners;
  }
  constructor(
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private router: Router,
    private errorHandler: ErrorsHandler,
    private doctorService: DoctorService
  ) {
    this.times = this.generateTimeArray()
  }

  generateTimeArray() {
    const times = [];
    const startTime = 1;
    const endTime = 24;

    for (let index = startTime; index <= endTime; index++) {
      times.push({
        value:moment(`${index}:00`, 'HH:mm').format('HH:mm'), 
        label:moment(`${index}:00`, 'HH:mm').format('HH:mm')
      });
    }

    return times;
  }

  ngOnInit(): void {

    this.doctorService.get().subscribe((currentDoctor) => {
          if (currentDoctor) {
            this.currentDoctor = currentDoctor;
            if(this.currentDoctor.clinic &&
              this.clinic_resource_id == null &&
              this.currentDoctor.clinic_resources.length > 0){
                this.clinic_resource_id = this.currentDoctor.clinic_resources[0]._id;
            }
          }
        }
    )

    this.appointment_length = 15;
  }

  navigateEdit() {
    this.activeEdit = !this.activeEdit;
    return this.router.navigate(["/booking-availabilities/edit"]);
  }

  navigateAgenda() {
    return this.router.navigate(["/calendar"]);
  }


  submitForm() {
    let booking_availabilities_planner_payload = {};
    for (const key in this.booking_availabilities_planner) {
      booking_availabilities_planner_payload[key] = (()=>{
        if (!this.booking_availabilities_planner[key].day_on){
          return {starts_at: "00:00", ends_at:"00:00"}
        }
        return this.booking_availabilities_planner[key]
      })()
    }
    booking_availabilities_planner_payload['time'] = this.appointment_length;
    let formHash = {
      clinic_resource_id: this.clinic_resource_id,
      booking_availabilities_planner: booking_availabilities_planner_payload,
    };
    this.doctorService.update_planner(formHash).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant(
            "connect.booking_slot_availabilities.your_schedule_updated"
          )
        ),
          this.navigateEdit();
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }
}
