import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-doctor-availabilities-new",
  templateUrl: "./doctor-availabilities-new.component.html",
  styleUrls: ["./doctor-availabilities-new.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorAvailabilitiesNewComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  editAvailability() {
    return this.router.navigate(["/booking-availabilities/edit"]);
  }

  newPlanner() {
    return this.router.navigate(["/booking-availabilities/new-planner"]);
  }
}
