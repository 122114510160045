import {
  Component,
  Optional,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { PatientDetailsComponent } from "src/app/components/medical-records/patient-details/patient-details.component";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { AttachmentSharingsDialogComponent } from "../attachment-sharings-dialog/attachment-sharings-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { PrescriptionsService } from "src/app/services/prescriptions.service";
import { Doctor } from "src/app/models/doctor";
import { AttachmentHelper } from "src/app/helpers/attachment_helper";
import { UppyDialogComponent } from "../uppy-dialog/uppy-dialog.component";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnInit {
  @Input() patient: { phone_number: any };

  editMode: boolean = false;
  noteContent: string = "";
  noteIndex: any = "";
  disabled: boolean = true;
  editedContent: string;
  doctorId: string
  previews: any[] = [];
  attachments: any[] = [];

  constructor(
    @Optional() private parent: PatientDetailsComponent,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private router: Router,
    public doctorService: DoctorService,
    private medicalRecord: MedicalRecordService,
    private prescriptionService: PrescriptionsService,
    private attachmentHelper: AttachmentHelper,
    private errorHandler: ErrorsHandler,
    public dialog: MatDialog
  ) {}

  MedicalRecordID: string = this.route.snapshot.params.id.toString();

  ngOnInit() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.doctorId = currentDoctor.id
      }
    })
  }

  uppyOpenDialog(): void {
    var _this = this
    const dialogRef = this.dialog.open(UppyDialogComponent,  {
      width: '400px',
      height: '450px'
    });
    dialogRef.disableClose = true
    dialogRef.componentInstance.medicalRecordId = this.MedicalRecordID
    dialogRef.componentInstance.doctorId = this.doctorId
    if (dialogRef.componentInstance) dialogRef.componentInstance.attachments = ['']
    if (dialogRef.componentInstance) dialogRef.componentInstance.previews = ['']
    dialogRef.afterClosed().subscribe(result => {
      if (!result || !_this) return;
      _this.previews = result['previews']
      _this.attachments = this.attachmentHelper.processFiles(_this.attachments, result['attachments']);
      if (result['previews']?.length > 0)
      {
        _this.previews = this.attachmentHelper.addUrlKey(_this);
        _this.previews.forEach((preview) => 
        {
          this.parent.patientEvents[this.noteIndex].attachments.push(preview) // update the previews
        })
      }
    });
  }

  openDeleteDialog(attachments, noteId, index)
  {
    const urls = this.attachmentHelper.getUrls(attachments)
    Swal.fire(this.attachmentHelper.getDeleteOptionConfig).then((result) => {
      if (result.value) {
        this.medicalRecord.deleteAttachments(urls, this.MedicalRecordID, noteId).subscribe(
          (success:any) => {
            this.snackBar.open(this.translate.instant('connect.templates.attachment(s)_deleted'))
            this.parent.patientEvents[index].attachments = this.parent.patientEvents[index].attachments.filter(attachment =>
              !urls.includes(attachment.url || attachment.key)
            );
            this.attachments = this.attachments.filter(attachment =>
              !urls.includes(attachment.url || attachment.key)
            );
          },
          error =>
          {
            this.snackBar.open(this.translate.instant('connect.globals.try_again'))
          }
        );
      }
    });
  }
  

  openSharingDialog(event): void {
    if (this.patient.phone_number) {
      const dialogRef = this.dialog.open(AttachmentSharingsDialogComponent, {
        width: "480px",
        data: {
          event: event,
          patient: this.patient,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    } else {
      Swal.fire({
        title: this.translate.instant(
          "connect.user_profiles.complete_phone_number_before_sharing"
        ),
      });
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  onScrollDown() {
    this.parent.listParams.pageNumber++;
    this.parent.getTimelineEvents(this.parent.listParams.pageNumber);
  }

  printNote(noteId) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        MedicalRecordID: this.MedicalRecordID,
        noteId: noteId,
      },
    };
    this.router.navigate(["/medical-note"], navigationExtras);
  }

  cancelUpdateNote() {
    return this.toggleEditMode();
  }

  deleteNote(noteId) {
    Swal.fire(this.attachmentHelper.getDeleteOptionConfig).then((result) => {
      if (result.value) {
        this.medicalRecord.deleteNote(this.MedicalRecordID, noteId).subscribe(
          (success) => {
            this.snackBar.open(
              this.translate.instant("connect.medical_events.has_deleted")
            );
            return this.parent.getTimelineEvents(
              (this.parent.listParams.pageNumber = 0)
            );
          },
          (error) =>
            this.snackBar.open(
              this.translate.instant("connect.globals.try_again")
            )
        );
      }
    });
  }

  showMedicineDetails(medicine_id) {
    return this.router.navigate(["/prescriptions", medicine_id]);
  }

  toggleEditMode() {
    return (this.editMode = !this.editMode);
  }

  updateSingleNote(index) {
    this.noteIndex = index;
    if (this.noteIndex === index) {
      return this.toggleEditMode();
    }
  }

  getEditorContent(noteID, evt) {
    this.editedContent = evt;
    if (
      this.editedContent === "" ||
      this.editedContent === null ||
      this.editedContent === undefined
    ) {
      this.snackBar.open(
        this.translate.instant("connect.medical_events.empty_note")
      );
      return;
    } else {
      this.medicalRecord
        .updateNote(this.MedicalRecordID, noteID, {
          content: this.editedContent,
          attachments : this.attachments
        })
        .subscribe(
          (success) => {
            this.toggleEditMode();
            this.previews = []
            this.attachments = []
            this.parent.getTimelineEvents(
              (this.parent.listParams.pageNumber = 0)
            );
            this.snackBar.open(
              this.translate.instant("connect.medical_events.note_updated")
            );
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.try_again")
            )
        );
    }
  }

  updatePrescription(prescription, index)
  {
    this.noteIndex = index
    return this.toggleEditMode()
  }

  deletePrescription(prescription, index)
  {
    Swal.fire(this.attachmentHelper.getDeleteOptionConfig).then((result) => {
      if (result.value) {
        this.prescriptionService.delete(prescription.id)
          .subscribe(
            success => {
              this.snackBar.open(this.translate.instant('connect.medical_events.has_deleted'))
              return this.parent.getTimelineEvents(this.parent.listParams.pageNumber=0)
            },
            error => this.snackBar.open(this.translate.instant('connect.globals.try_again'))
          )
      }
    })
  }
}
