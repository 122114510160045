import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "doctor-stats",
  templateUrl: "./doctor-stats.component.html",
  styleUrls: ["./doctor-stats.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorStatsComponent {
  @Input() stats: string = "";
  @Input() icon: string = "";
  @Input() statsText: string = "";
}
