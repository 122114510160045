import { Injectable } from "@angular/core";
import { Doctor } from "src/app/models/doctor";

@Injectable({
  providedIn: "root",
})
export class DoctorHelper {
  fullName(doctor: Doctor): string {
    return doctor.title + " " + doctor.first_name + " " + doctor.last_name;
  }
}
