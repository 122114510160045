import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";
import { DoctorService } from "src/app/services/doctor.service";

@Pipe({
  name: "customDate",
})
export class CustomDatePipe implements PipeTransform {
  locale: string = "fr";
  timezone: string = "Africa/Casablanca";
  constructor(private doctorService: DoctorService) {}

  transform(date: any): any {
    if (this.doctorService.current) {
      this.locale = this.doctorService.current.locale;
      this.timezone = this.doctorService.current.time_zone;
    }
    return formatDate(date, "EEE dd MMM y", this.locale, this.timezone);
  }
}
