import { SlotType } from "src/app/enums/SlotType.enum";
import { Injectable } from "@angular/core";
import { BookingSlot } from "src/app/models/booking-slot";
import { StateType } from "src/app/enums/stateType.enum";
import { TranslateService } from "@ngx-translate/core";
import { BookingType } from "src/app/enums/bookingType.enum";

@Injectable({
  providedIn: "root",
})
export class BookingHelper {
  constructor(private translate: TranslateService) {}

  isCancelable(booking: BookingSlot): boolean {
    return (
      (booking.slot_type == SlotType.ONLINE ||
        booking.slot_type == SlotType.MANUAL) &&
      !(
        booking.state === StateType.CANCELED_DOCTOR ||
        booking.state === StateType.CANCELED_ASSISTANT
      ) &&
      ( (booking.state === StateType.AWAITING_PAYMENT && booking.type=="video") || booking.type!="video" || booking.is_mind) &&
      new Date(booking.starts_at) > new Date()
    );
  }

  getCancelMessage(booking: BookingSlot) {
    let text = this.translate.instant("connect.globals.confirmation_box_text");
    if (booking.type == BookingType.HOME) {
      text = this.translate.instant(
        "connect.globals.home_visit_before_confirming"
      );
    }
    return text;
  }
}
