<div class="container" *ngIf="DoctorStats">
  <div class="row">
    <h3 class="h3">
      {{ "connect.statistics.profile_statistics" | translate }}
    </h3>
  </div>
  <hr />
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="h4">
          <h4 class="kt-portlet__head-title card-title">
            {{ "connect.statistics.total_statistics" | translate }}
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      *ngIf="DoctorStats.booking_slots_count"
      class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2"
    >
      <doctor-stats
        [stats]="DoctorStats.booking_slots_count"
        icon="fa fa-calendar fa-2x"
        statsText="{{ 'connect.statistics.booking_slots_count' | translate }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.manual_booking_slots_count"
        icon="fa fa-calendar-check-o fa-2x"
        statsText="{{
          'connect.statistics.manual_booking_slots_count' | translate
        }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.online_booking_slots_count"
        icon="fa fa-globe fa-2x"
        statsText="{{
          'connect.statistics.online_booking_slots_count' | translate
        }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.video_booking_slots_count"
        icon="fa fa-video fa-2x"
        statsText="{{
          'connect.statistics.video_booking_slots_count' | translate
        }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.total_phone_requests"
        icon="fa fa-phone fa-2x"
        statsText="{{ 'connect.statistics.total_phone_requests' | translate }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.sms_sent"
        icon="fa fa-envelope fa-2x"
        statsText="{{ 'connect.statistics.sms_sent' | translate }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.reviews_count"
        icon="fa fa-comments fa-2x"
        statsText="{{ 'connect.statistics.reviews_count' | translate }}"
      ></doctor-stats>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
      <doctor-stats
        [stats]="DoctorStats.total_profile_visits"
        icon="fa fa-user fa-2x"
        statsText="{{ 'connect.statistics.total_profile_visits' | translate }}"
      ></doctor-stats>
    </div>
  </div>
  <hr />

  <div class="container">
    <div class="row">
      <div class="h4 col-12">
        <h4 class="kt-portlet__head-title card-title">
          {{ "connect.statistics.last_year" | translate }}
        </h4>
      </div>
      <div
        *ngIf="DoctorStats.current_year_booking_count"
        class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2"
      >
        <doctor-stats
          [stats]="DoctorStats.current_year_booking_count"
          icon="fa fa-calendar fa-2x"
          statsText="{{ 'connect.statistics.booking_slots_count' | translate }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.current_year_manual_booking_count"
          icon="fa fa-calendar-check-o fa-2x"
          statsText="{{
            'connect.statistics.manual_booking_slots_count' | translate
          }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.current_year_online_booking_count"
          icon="fa fa-globe fa-2x"
          statsText="{{
            'connect.statistics.online_booking_slots_count' | translate
          }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.current_year_video_booking_count"
          icon="fa fa-video fa-2x"
          statsText="{{
            'connect.statistics.video_booking_slots_count' | translate
          }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.this_year_phone_requests"
          icon="fa fa-phone fa-2x"
          statsText="{{
            'connect.statistics.total_phone_requests' | translate
          }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.current_year_sms_sent"
          icon="fa fa-envelope fa-2x"
          statsText="{{ 'connect.statistics.sms_sent' | translate }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.current_year_reviews_count"
          icon="fa fa-comments fa-2x"
          statsText="{{ 'connect.statistics.reviews_count' | translate }}"
        ></doctor-stats>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-6 m-0 p-2">
        <doctor-stats
          [stats]="DoctorStats.this_year_profile_visits"
          icon="fa fa-user fa-2x"
          statsText="{{
            'connect.statistics.total_profile_visits' | translate
          }}"
        ></doctor-stats>
      </div>
    </div>
  </div>
</div>
