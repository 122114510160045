<div class="row mt-md-5">
  <div class="col-md-3">
    <app-patient-profile [patient]="patientDetails"></app-patient-profile>
  </div>
  <div class="col-md-9">
    <app-line-tabs
      [isToggled]="isToggled"
      (toogleData)="toggleContent($event)"
    ></app-line-tabs>
    <app-note
      (editorContentData)="getEditorContent($event)"
      [patientDetails]="patientDetails"
      *ngIf="isToggled"
    ></app-note>
    <div class="attachment-btn" *ngIf="isToggled">
      <button
        (click)="uppyOpenDialog()"
        color="primary"
        class="btn btn-outline-neutral ml-auto mt-3"
      >
        <i class="fa fa-paperclip mr-2"></i>
        {{ "connect.patient_details.attachments" | translate }}
      </button>
    </div>
    <div
      class="kt-portlet kt-padding-10 prescriptions-txt"
      *ngIf="isToggled === false"
    >
      <div *ngIf="isPatient">
        <app-prescriptions
          [patient]="patientDetails"
          [medicalRecordId]="MedicalRecordID"
        ></app-prescriptions>
      </div>
    </div>
    <div class="img-previews note-images pb-3" *ngIf="previews.length > 0">
      <div class="preview-container">
        <h2>{{ "connect.patient_details.attachments_preview" | translate }}</h2>
        <div class="note-image-box" *ngFor="let preview of previews">
          <div class="files-name">
            <span>
              <img
                src="{{ previewImg(preview) }}"
                alt="image"
                class="doc-image"
              />
              <a
                class="files-name"
                href="{{ preview.key }}"
                download
                target="_blank"
                >{{ preview.name }}</a
              >
              <button class="width-100 btn editorBtn max-100 delete-btn-attachments mt-3" (click)="openDeletePreviewDialog(preview)">{{ 'connect.medical_events.delete' | translate }}</button>
            </span>
          </div>
        </div>
      </div>
      <button *ngIf="previews.length > 1" class="lt-12 width-120 btn editorBtn max-100 delete-btn-attachments mt-3" (click)="openDeletePreviewDialog(previews)">{{ 'connect.medical_events.delete_all' | translate }}</button>
    </div>
    <app-timeline [patient]="patientDetails"></app-timeline>
  </div>
</div>
