<div mat-dialog-content>
  <div class="dashboard"></div>
</div>
<div mat-dialog-actions fxLayout="row" class="text-right">
  <button
    type="button"
    class="uploading-done mat-raised-button mat-button-base mat-primary mt-2 done-btn"
    (click)="close()"
  >
    {{ "connect.uppy.done" | translate }}
  </button>
</div>
