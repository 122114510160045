const isOrangeDomain = window.location.hostname === "connect-sante.orange.ci";
const baseUrl = isOrangeDomain ? "https://connect-sante.orange.ci" : "https://connect.dabadoc.com";
const apiBaseUrl = isOrangeDomain ? "https://sante.orange.ci" : "https://www.dabadoc.com";

export const environment = {
  production: true,
  apiUrl: baseUrl + "/",
  api_base_url : apiBaseUrl,
  auth_url: apiBaseUrl +"/doctors/sign_in",
  doctor_api_url: apiBaseUrl + "/api/v3/doctor",
  base_url: baseUrl + "/",
  main_origin: baseUrl + "/",
  current_locale: "fr",
  current_country: "MA",
  country_enforced: null,
  sentry_url: "https://837bce63b7dd49f688226db531e49b0a@o5904.ingest.sentry.io/11243",
  PUBLIC_KEY_VAPID:
    "BBaltUcf7JcP4SbntifYCzfCoug8gWQy3h2Iemew3hiv-ExiB19wyampkJwLoSGIAgheeu4F10y9ODKYw6uEAvA=",
  arabic_countries: ["MA", "DZ", "TN"],
  average_booking_duration: 30,
  user_context: {
    id: "619b75f9d447ac00d019bf29",
    email: "doctor2@dabadoc.com",
  },
  google_map_script:
    "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCbLOYWg6PlgbNGB3TrtqQI03HxS-wZ99A&libraries=places",
  intltelinput_preferred_countries: [
    "ma",
    "dz",
    "tn",
    "sn",
    "cm",
    "ci",
    "ng",
    "za",
    "ke",
    "pk",
  ],
  is_doctor: true,
  paid_countries: ["MA"],
  searchApiUrl: apiBaseUrl + "/recherche",
  utc_offset: 60,
};
