import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class MassSmsService {
  private MassSmsUrl: string = "/mass_sms";

  constructor(private http: HttpWrapper) {}

  get_moroccan_contacts() {
    return this.http.get("/moroccan_contacts");
  }

  create(message: string) {
    return this.http.post(this.MassSmsUrl, { message: message });
  }
}
