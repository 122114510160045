import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
  selector: "app-patient-information-dialog",
  templateUrl: "./patient-information-dialog.component.html",
})
export class PatientInformationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PatientInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  ageFrom(dateOfBirth: any): number {
    return moment().diff(dateOfBirth, "years");
  }
}
