import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class CommentsService {
  private commentsUrl: string = "/reviews";

  constructor(private http: HttpWrapper) {}

  get(filters: string) {
    return this.http.get(this.commentsUrl + "?" + filters);
  }

  getComment(commentId: string) {
    return this.http.get(`${this.commentsUrl}/${commentId}`);
  }

  update(commentId: string, comments: any) {
    return this.http.put(`${this.commentsUrl}/${commentId}`, comments);
  }
}
