import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActsService } from "src/app/services/acts.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { DoctorService } from "src/app/services/doctor.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "acts",
  templateUrl: "./acts.component.html",
  styleUrls: ["./acts.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ActsComponent implements OnInit {
  acts: any;
  acts_ids: Array<any> = [];
  doctor_acts: any;

  constructor(
    private actsService: ActsService,
    private snackbar: SnackBarService,
    private doctorService: DoctorService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getDoctorInfo();
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.doctor_acts = currentDoctor.act_ids;
        this.acts_ids = this.doctor_acts;
        this.getDoctorActs();
      }
    });
  }

  isChecked(act: { id: any }) {
    return this.doctor_acts.includes(act.id);
  }

  getDoctorActs() {
    this.actsService.get().subscribe(
      (data) => {
        this.acts = data;
      },
      (error) => this.snackbar.open(error.message)
    );
  }

  onCheckboxChange(event: { target: { checked: any; value: any } }) {
    if (event.target.checked) {
      this.acts_ids.push(event.target.value);
    } else {
      const index = this.acts_ids.indexOf(event.target.value);
      if (index > -1) {
        this.acts_ids.splice(index, 1);
      }
    }
  }

  saveActs() {
    this.actsService.create(this.acts_ids).subscribe(
      (data) => {
        this.snackbar.open(
          this.translate.instant("connect.acts.profile_updated")
        );
      },
      (error) => this.snackbar.open(error.message)
    );
  }
}
