import { Component, OnInit, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import Swal from "sweetalert2";
import { Doctor } from "src/app/models/doctor";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Inject } from "@angular/core";

import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  NgForm,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AttachmentSharingsService } from "src/app/services/attachment-sharings.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-video-consultation-modal",
  templateUrl: "./video-consultation-modal.component.html",
  styleUrls: ["./video-consultation-modal.component.scss"],
})
export class VideoConsultationModalComponent implements OnInit {
  currentDoctor: Doctor = new Doctor();
  video_consultation_price!: string;
  videoConsultPriceForm!: UntypedFormGroup;

  constructor(
    private attachmentSharingsService: AttachmentSharingsService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    private errorHandler: ErrorsHandler,
    public dialogRef: MatDialogRef<VideoConsultationModalComponent>,
    private router: Router,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: Doctor
  ) {}

  ngOnInit() {
    if (this.data) {
      this.videoConsultPriceForm = this.formBuilder.group({
        id: this.data.id,
        video_consultation_price: [
          this.data.video_consultation_price,
          [
            Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            Validators.min(41),
            Validators.max(1000),
          ],
        ],
      });
    }
  }

  SaveDetails() {
    this.doctorService
      .updateProfile(this.videoConsultPriceForm.value)
      .subscribe(
        (data) => {
          this.snackBar.open(
            this.translate.instant("connect.profile.save_changes")
          );
          this.dialogRef.close();
          Swal.fire({
            title: this.translate.instant(
              "connect.globals.go_to_availabilities"
            ),
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.translate.instant(
              "connect.booking_slot_availabilities.create_your_schedule"
            ),
            heightAuto: false,
          }).then((result) => {
            if (result.value) {
              this.router.navigate([
                "/booking-availabilities/new-planner",
              ]);
            }
          });
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
