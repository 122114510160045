import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { BookingSlotsService } from "src/app/services/booking-slots.service";

@Component({
  selector: "app-late-dialog",
  templateUrl: "./will-be-late-dialog.component.html",
})
export class WillBeLateDialogComponent implements OnInit {
  delayed_length: string = "15";

  time_slots = [
    { value: "15-30 minutes", viewValue: "15_30_minutes" },
    { value: "30-45 minutes", viewValue: "30_45_minutes" },
    { value: "45-60 minutes", viewValue: "45_60_minutes" },
    { value: "Plus d'une heure", viewValue: "more_than_an_hour" },
  ];

  constructor(
    private medicalRecord: MedicalRecordService,
    public dialogRef: MatDialogRef<WillBeLateDialogComponent>,
    private bookingSlotsService: BookingSlotsService,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private errorHandler: ErrorsHandler,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  appointmentDelayed() {
    const minutes_delayed = this.delayed_length;

    this.bookingSlotsService
      .appointment_delayed(this.data.id, minutes_delayed)
      .subscribe(
        (data) => {
          this.snackBar.open(
            this.translate.instant(
              "connect.delayed_appointment_dailog.mail_sent_successfully"
            )
          );
          return this.onNoClick();
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }
}
