import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class EstablishmentsService {
  private establishmentsUrl: string = "/establishments";

  constructor(private http: HttpWrapper) {}

  get(name: string) {
    return this.http.get(this.establishmentsUrl + "?name=" + name);
  }
}
