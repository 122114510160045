import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class ClinicResourceService {
  private clinicResourceEntityUrl: string = "/clinic_resources";

  constructor(private http: HttpWrapper) {}

  getClinicResources(filters?: string) {
    return this.http.get(this.clinicResourceEntityUrl + "?" + filters);
  }

  getClinicResource(clinic_resource_id: string) {
    return this.http.get(`${this.clinicResourceEntityUrl}/${clinic_resource_id}`);
  }

  updateClinicResourceAvailabilities(clinic_resource_id: string, clinic_resource: any) {
    return this.http.put(`${this.clinicResourceEntityUrl}/${clinic_resource_id}`, clinic_resource);
  }

}
