<div mat-dialog-title class="mb-0">
  <div class="booking-modal-header modal-header align-items-center">
    <div>
      <h4 *ngIf="isNewBooking && isRecord" class="m-0 p-0">
        {{ "connect.online-booking.new_appointment" | translate }}
      </h4>
      <h4 *ngIf="!isNewBooking && !isRecord" class="m-0 p-0">
        {{ "connect.online-booking.modify_appointment" | translate }}
      </h4>
    </div>
    <div>
      <button type="button" class="close" (click)="onNoClick(false)">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-content class="booking-modal">
  <div class="modal-body">
    <form *ngIf="bookingSlotForm" class="needs-validation" [formGroup]="bookingSlotForm">
      <div class="row d-flex ">
        <div class="col-6 " *ngIf="isVideoConsultation">
          <mat-slide-toggle
            class="mb-4"
            formControlName="is_video" [disabled]="isVideoOrMind">
            {{ "connect.online-booking.video" | translate }}
          </mat-slide-toggle>
        </div>
        <div class="col-6" *ngIf="isMindPartner && !clinic">
          <mat-slide-toggle
            class="mb-4"
            formControlName="is_mind" [disabled]="isVideoOrMind">
            {{ "connect.online-booking.mind" | translate }}
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group mb-2">
            <label class="col-form-label">{{
              "connect.online-booking.start" | translate
            }}</label>
            <input
              formControlName="starts_at"
              class="form-control"
              [owlDateTimeTrigger]="dt_starts_at"
              tabindex="1"
              [owlDateTime]="dt_starts_at"
              [selectMode]="'single'"
              (ngModelChange)="calcuateBookingSlotEnds()"
            />
            <owl-date-time #dt_starts_at [stepMinute]="5"></owl-date-time>
            <div *ngIf="bookingSlotForm.get('starts_at').hasError('startsAtInvalid') && bookingSlotForm.get('starts_at').touched" class="text-danger">
                {{"connect.online-booking.start_date_and_time_invalid"| translate}}

            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group mb-2">
            <label class="col-form-label">{{
              "connect.online-booking.end" | translate
            }}</label>
            <input
              formControlName="ends_at"
              class="form-control"
              [min]="timeNow"
              [owlDateTimeTrigger]="dt_ends_at"
              tabindex="2"
              [owlDateTime]="dt_ends_at"
              [selectMode]="'single'"
            />
            <owl-date-time #dt_ends_at [stepMinute]="5"></owl-date-time>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6"  *ngIf="!isBlocking && doctor.booking_slot_types.length > 0">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.Category" | translate }}</label>
            <select
              formControlName="booking_slot_type_id"
              class="custom-select col-12"
              (change)="selectChangeHandler($event)"
              placeholder="Testing"
              tabindex="3"
            >
              <option [ngValue]="null" disabled>
                {{ "connect.online-booking.select_category" | translate }}
              </option>
              <option
                [value]="bookingType.id"
                *ngFor="let bookingType of doctor.booking_slot_types"
                [selected]="bookingType.id == bookingSlot.booking_slot_type_id"
              >
                {{ bookingType.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!isBlocking && clinic">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.clinic_resource" | translate }}</label>
            <select
              class="custom-select col-12"
              (change)="onSpecialityChange($event)"
              aria-label="Default select example"
              formControlName="speciality"
            >
              <option value="" selected>{{ defaultAvailableMessage() | translate }}</option>
              <option
                [value]="clinic_resource._id"
                *ngFor="let clinic_resource of clinic_resources"
                [selected]="data.resourceId == clinic_resource._id || bookingSlot.resourceId == clinic_resource._id"
              >
                {{ clinic_resource.name }}
              </option>
            </select>
          </div>
          <div *ngIf="bookingSlotForm.get('speciality').hasError('required') && bookingSlotForm.get('speciality').touched" class="text-danger">
            {{ "connect.online-booking.speciality_error" | translate }}
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!isBlocking">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.last_name" | translate }}</label>
            <div id="custom-sel-field">
              <mat-select
                #singleSelect
                (selectionChange)="handleSelectChange($event.value)"
                [placeholder]="
                  lastNameValue
                    ? last_name
                    : ('connect.online-booking.last_name' | translate)
                "
                formControlName="user_last_name"
                class="booking-dailog-select"
                panelClass="calendar-booking-mat-select"
                backdropClass="full-width"
                tabindex="4"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="medicalFilterCtrl"
                    placeholderLabel="{{
                      'connect.online-booking.last_name' | translate
                    }}"
                    value="{{'connect.online-booking.last_name' | translate }}"
                    noEntriesFoundLabel="{{
                      'connect.prescriptions.no_matching_found' | translate
                    }}"
                    (change)="onLastNameChange($event)"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let medical_record of filteredMedicalRecords | async"
                  value="{{ medical_record.user_first_name }} && {{
                    medical_record.user_last_name
                  }} && {{ medical_record.user_phone_number }}"
                >
                  {{ medical_record.user_first_name }}
                  {{ medical_record.user_last_name }} --
                  {{ medical_record.user_phone_number }}
                </mat-option>
              </mat-select>
            </div>
            <div *ngIf="bookingSlotForm.get('user_last_name').hasError('required') && bookingSlotForm.get('user_last_name').touched" class="text-danger">
              {{ "connect.online-booking.last_name_error" | translate }}
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!isBlocking">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.first_name" | translate }}</label>
            <input
              formControlName="user_first_name"
              type="text"
              class="form-control"
              placeholder="{{ 'connect.online-booking.first_name' | translate }}"
              tabindex="5"
            />
            <div *ngIf="bookingSlotForm.get('user_first_name').hasError('required') && bookingSlotForm.get('user_first_name').touched" class="text-danger">
              {{ "connect.online-booking.first_name_error" | translate }}
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="clinic && !isBlocking">
          <div class="form-group mb-2">
            <label class="col-form-label">{{  "connect.online-booking.age" | translate }}</label>
            <input
              formControlName="user_age"
              (change)="onAgeChange($event)"
              type="text"
              class="form-control"
              aria-label="Age"
              aria-describedby="basic-addon1"
              placeholder="Age"
            />
            <div *ngIf="bookingSlotForm.get('user_age').hasError('required') && bookingSlotForm.get('user_age').touched" class="text-danger">
              {{ "connect.online-booking.user_age_error" | translate }}
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="(!isPatientInfoEditable && !canBeUnblocked()) && !isBlocking">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.phone" | translate }}</label>
            <input
              formControlName="user_phone_number"
              type="text"
              class="form-control"
              tabindex="6"
            />
            
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!isBlocking && isPatientInfoEditable">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.phone" | translate }}</label>
            <div class="phone-number-input booking-country-phone">
              <div [ngClass]="{ wrapper: !validPhoneNumber && bookingSlotForm.get('user_phone_number')?.touched }">
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [selectedCountryISO]="country"
                  [selectFirstCountry]="false"
                  [phoneValidation]="false"
                  placeholder="phone"
                  formControlName="user_phone_number"
                  tabindex="7"
                  (keyup)="onPhoneNumberChange()"
                >
                </ngx-intl-tel-input>
                <div *ngIf="bookingSlotForm.get('user_phone_number').hasError('required') && bookingSlotForm.get('user_phone_number').touched" class="text-danger">
                  {{ "connect.online-booking.phone_number_error" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!isBlocking && !clinic">
          <div class="form-group mb-2">
            <label class="col-form-label">{{ "connect.online-booking.email" | translate }}</label>
            <input
              formControlName="user_email"
              type="text"
              class="form-control"
              placeholder="{{ 'connect.online-booking.email' | translate }}"
              tabindex="5"
            />
          </div>
        </div>
        <div *ngIf="!isBlocking" class="form-group mb-0 col-sm-12 col-md-6">
          <label class="col-form-label ">{{ 'connect.online-booking.sms_language' | translate }}</label>
          <div class="sms col-sm-8">
            <div class="form-check form-check-inline">
              <input formControlName="user_locale" class="user_locale form-check-input" type="radio" id="fr" value="fr" tabindex="7">
              <label class="form-check-label" for="fr">Français</label>
            </div>
            <div class="form-check form-check-inline">
              <input formControlName="user_locale" class="user_locale form-check-input" type="radio" id="ar" value="ar" tabindex="8">
              <label class="form-check-label" for="ar">العربية</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="footer justify-content-end align-items-center px-4 d-flex pt-2 pb-2">
    <button mat-button
      mat-flat-button color="warn"
      *ngIf="isCancelable && !isVideoBooking"
      (click)="cancelAnAppointment(bookingSlot.id)"
      class="mrx"
    >
      {{ "connect.online-booking.cancel_appointment" | translate }}
    </button>
    <button 
      class="btn btn-sm mr-3 btn-outline-cancel" 
      (click)="createNewMedicalRecord(false)" 
      *ngIf="!isBlocking && bookingSlot?.state?.includes('confirmed') && bookingSlot?.medical_record_id == null">{{ 'connect.online-booking.create_medical_record' | translate }}
    </button>
    <button #absent mat-button
      tooltip="{{ bookingSlot?.patient_didnt_showup ? ('connect.globals.already_not_show_up' | translate) : ('connect.globals.not_show_up' | translate) }}"
      placement="top"
      [delay]="100"
      containerClass="tooltip"
      triggers="mouseenter:mouseleave"
      *ngIf="confirmedDoctorAssistant(bookingSlot.state) && pastDate(bookingSlot.ends_at)"
      [ngClass]="{'flag-btn': bookingSlot.patient_didnt_showup, 'grey-flag-btn': !bookingSlot.patient_didnt_showup}"
      class="btn grey-flag-btn btn-icon pointer mr-3 absent"
      (click)="didntShowUp(bookingSlot.id, absent)">
      <i class="fas fa-ghost ghosted mrx"></i>
    </button>
    <button mat-button
      mat-flat-button color="warn"
      *ngIf="canBeBlocked()"
      (click)="blockSlot()"
      class="mrx"
    >
      {{ "connect.online-booking.block" | translate }}
    </button>
    <button
      *ngIf="canBeUnblocked()"
      (click)="unblockSlot()"
      class="mrx btn btn-success btn-rounded"
    >
      {{ "connect.online-booking.make_available" | translate }}
    </button>
    <button mat-button
      mat-flat-button color="primary"
      *ngIf="!isNewBooking && isConfirmBooking && !isRecord"
      (click)="updateAnAppointment()"
      class="mrx"
    >
      {{ "connect.online-booking.confirm" | translate }}
    </button>
    <button mat-button
      mat-flat-button color="warn"
      *ngIf="
        futureDate(bookingSlot.starts_at) &&
        isModifyBooking &&
        !isRecord &&
        isDeletable &&
        !canBeUnblocked()
      "
      (click)="deleteAnAppointment(bookingSlot.id)"
      class="mrx"
    >
      {{ "connect.online-booking.delete" | translate }}
    </button>
    <a
      class="btn btn-success mrx"
      target="_blank"
      href="{{ bookingSlot.video_url }}"
      *ngIf="!!bookingSlot.video_url"
      >{{ "connect.online-booking.call_video" | translate }}</a
    >
    <button mat-button
      mat-flat-button color="primary"
      *ngIf="futureDate(bookingSlot.starts_at) && isModifyBooking && !isRecord"
      (click)="updateAnAppointment()"
      class="mrx"
    >
      {{ "connect.online-booking.modify" | translate }}
    </button>
    <button mat-button
      mat-flat-button color="primary"
      *ngIf="isNewBooking && isRecord"
      (click)="isMedicalRecord()"
      [disabled]="isAdding"
      class="mrx"
    >
      {{ "connect.online-booking.add" | translate }}
    </button>
  </div>
</div>
