<div class="row">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template p-3">
      <div class="container">
        <h3 class="kt-portlet__head-title">
          {{ "connect.acts.your_acts" | translate }}
        </h3>
        <p class="acts-message h2">
          {{ "connect.acts.select_your_acts" | translate }}
        </p>
      </div>

      <div class="checkbox-group pt-5">
        <div *ngFor="let act of acts; let i = index" class="m-2">
          <label>
            <div class="checkbox">
              <div class="checkbox">
                <label class="checkbox-wrappe">
                  <input
                    class="checkbox-input"
                    type="checkbox"
                    [value]="act.id"
                    (change)="onCheckboxChange($event)"
                    [checked]="isChecked(act) == true"
                  />
                  <span class="checkbox-tile">
                    <span class="checkbox-label">{{ act.name }}</span>
                  </span>
                </label>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn mat-raised-button mat-button-base mat-primary mt-5"
          (click)="saveActs()"
        >
          {{ "connect.acts.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
