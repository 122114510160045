<div class="row">
  <div class="col-md-12 planning-wrapper availabilities-edit">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__body">
        <div class="top-link">
          <ul>
            <li>
              <a class="cursor-pointer" (click)="navigateAgenda()">{{
                "connect.booking_slot_availabilities.my_agenda" | translate
              }}</a>
            </li>
            <li>
              <a (click)="navigatePlanner()" class="cursor-pointer">{{
                "connect.booking_slot_availabilities.configuration_planning"
                  | translate
              }}</a>
            </li>
            <li>
              <a [ngClass]="{ active: activePlanner }" class="cursor-pointer">{{
                "connect.booking_slot_availabilities.your_current_planning"
                  | translate
              }}</a>
            </li>
          </ul>
        </div>
        <div class="card">
          <b class="card-header h3">{{
            "connect.booking_slot_availabilities.your_current_planning"
              | translate
          }}</b>
          <p
            class="card-body h5"
            *ngIf="currentDoctor && currentDoctor.video_consultation_enabled"
          >
            {{
              "connect.booking_slot_availabilities.activate_the_video_consultation"
                | translate
            }}
          </p>

          <ng-container *ngIf="currentDoctor">
            <div *ngIf="currentDoctor.clinic" class="p-3 pb-0 d-flex flex-row">
              <mat-form-field appearance="fill" class="ml-3">
                <mat-label>Select a Doctor</mat-label>
                <mat-select
                  [(value)]="currentSelectedClinicRessourceId"
                  (selectionChange)="onClinicResourceChange()">
                  <mat-optgroup *ngFor="let department of currentDoctor.departments" [label]="department.name">
                    <ng-container *ngFor="
                        let resource of department.clinic_resources;
                        let ind = index
                      ">
                      <mat-option [value]="resource._id">{{ resource.name }}</mat-option>
                    </ng-container>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="currentDoctor && booking_slot_availabilities && booking_slot_availabilities.length > 0; else noDoctorSelected">
            <div class="current_planning_bx mb-2 pb-2" >
              <div
                class="paln-bx card p-1"
                *ngFor="let day of days; let dayIndex = index"
              >
                <div class="well text-center rounded-top">
                  <div>
                    {{ "connect.booking_slot_availabilities." + day | translate }}
                  </div>
                  <div
                    class="checkbox"
                    *ngIf="
                      currentDoctor && currentDoctor.video_consultation_enabled
                    "
                  >
                    <label class="checkbox-wrappe">
                      <input
                        class="checkbox-input"
                        type="checkbox"
                        [(ngModel)]="dayVideoEnabled[dayIndex]"
                        (change)="activateVideo(dayIndex)"
                      />
                      <span class="checkbox-tile">
                        <span class="checkbox-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M16 16c0 1.104-.896 2-2 2h-12c-1.104 0-2-.896-2-2v-8c0-1.104.896-2 2-2h12c1.104 0 2 .896 2 2v8zm8-10l-6 4.223v3.554l6 4.223v-12z"
                            />
                          </svg>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div
                  *ngFor="
                    let slot_availability of booking_slot_availabilities;
                    let lebelIndex = index
                  "
                >
                  <div *ngIf="slot_availability.week_day === dayIndex + 1">
                    <div
                      class="plan-content"
                      *ngIf="weekTimeSlots[lebelIndex + 1 + '_show'] == 'true'"
                      [ngClass]="{
                        'invalid-date':
                          validTimeSlots[lebelIndex + '_hours'] ||
                          validTimeSlots[lebelIndex + '_minutes']
                      }"
                    >
                      <div class="new-div">
                        <div class="timepickerbox smallinput">
                          <div class="timepicker-container">
                            <div class="timepicker-input">
                              <div class="time-input">
                                <input
                                  type="text"
                                  name=""
                                  class="form-control"
                                  placeholder="HH"
                                  [(ngModel)]="
                                    weekTimeSlots[
                                      lebelIndex + 1 + '_starts_at_hours_minutes'
                                    ].hour
                                  "
                                  (ngModelChange)="
                                    validateInputs(
                                      lebelIndex,
                                      $event,
                                      'hours',
                                      slot_availability.week_day
                                    )
                                  "
                                />
                              </div>
                            </div>
                            <div class="spacer-box">:</div>
                            <div class="timepicker-input">
                              <div class="time-input">
                                <input
                                  type="text"
                                  name=""
                                  class="form-control"
                                  placeholder="MM"
                                  [(ngModel)]="
                                    weekTimeSlots[
                                      lebelIndex + 1 + '_starts_at_hours_minutes'
                                    ].minute
                                  "
                                  (ngModelChange)="
                                    validateInputs(
                                      lebelIndex,
                                      $event,
                                      'minutes',
                                      slot_availability.week_day
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="seprator-line">-</div>
                        <div class="timepickerbox smallinput">
                          <div class="timepicker-container">
                            <div class="timepicker-input">
                              <div class="time-input">
                                <input
                                  type="text"
                                  name=""
                                  class="form-control"
                                  placeholder="HH"
                                  [(ngModel)]="
                                    weekTimeSlots[
                                      lebelIndex + 1 + '_ends_at_hours_minutes'
                                    ].hour
                                  "
                                  (ngModelChange)="
                                    validateInputs(
                                      lebelIndex,
                                      $event,
                                      'hours',
                                      slot_availability.week_day
                                    )
                                  "
                                />
                              </div>
                            </div>
                            <div class="spacer-box">:</div>
                            <div class="timepicker-input">
                              <div class="time-input">
                                <input
                                  type="text"
                                  name=""
                                  class="form-control"
                                  placeholder="MM"
                                  [(ngModel)]="
                                    weekTimeSlots[
                                      lebelIndex + 1 + '_ends_at_hours_minutes'
                                    ].minute
                                  "
                                  (ngModelChange)="
                                    validateInputs(
                                      lebelIndex,
                                      $event,
                                      'minutes',
                                      slot_availability.week_day
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="checkbox"
                          *ngIf="
                            currentDoctor &&
                            currentDoctor.video_consultation_enabled
                          "
                        >
                          <label class="checkbox-wrappe">
                            <input
                              class="checkbox-input"
                              type="checkbox"
                              [checked]="slot_availability.type == 1"
                              (change)="checkValue($event, lebelIndex)"
                            />
                            <span class="checkbox-tile">
                              <span class="checkbox-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M16 16c0 1.104-.896 2-2 2h-12c-1.104 0-2-.896-2-2v-8c0-1.104.896-2 2-2h12c1.104 0 2 .896 2 2v8zm8-10l-6 4.223v3.554l6 4.223v-12z"
                                  />
                                </svg>
                              </span>
                            </span>
                          </label>
                        </div>

                      </div>
                      <div class="seprator-line">|</div>
                      <button
                        (click)="deleteSlotAvailability(lebelIndex)"
                        class="min-w-0"
                        mat-flat-button
                        color="warn"
                      >
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="text-center">
                  <button
                    class="btn addmore-btn"
                    (click)="addSlotAvailability(dayIndex + 1)"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #noDoctorSelected>
            <mat-card class="mx-4 mb-4">
              <mat-card-content>
                <div class="d-flex flex-column text-center p-4 text-dimmed text-muted">
                  <span *ngIf="currentDoctor?.clinic" class="h4 m-0">Please Select a doctor</span>
                  <div *ngIf="!currentDoctor?.clinic">
                    <button
                      mat-raised-button
                      color="primary"
                      class="mr-auto"
                      [routerLink]="['/booking-availabilities/new-planner']"
                    >
                      {{ "connect.booking_slot_availabilities.create_your_schedule" | translate }}
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-template>

          <div class="row card-footer bg-white">
            <div class="col-sm-2">
              <button
                [disabled]="isValid"
                mat-raised-button
                color="primary"
                (click)="saveBookingSlotAvailabilities()"
                class="mr-auto"
              >
                {{ "connect.booking_slot_availabilities.save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
