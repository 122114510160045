import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface FormDialogData {
  actionType: 'create' | 'edit',
  element: any
}

@Component({
  selector: 'app-patient-label-form-dialog',
  templateUrl: './patient-label-form-dialog.component.html',
  styleUrls: ['./patient-label-form-dialog.component.scss']
})
export class PatientLabelFormDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PatientLabelFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    switch (data.actionType) {
      case 'edit':
        this.formData.setValue({
          id: data.element.id,
          color: data.element.color,
          name: data.element.name,
        });
        break;

      default:
        break;
    }
  }

  formData: FormGroup = new FormGroup({
    id: new FormControl(),
    color: new FormControl(),
    name: new FormControl(),
  });

  progressForm: any={
    mode: 'determinate',
    value: 0
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.dialogRef.close(this.formData.value)
  }

}
