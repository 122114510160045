import {
  Component,
  Optional,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from "@angular/core";
// import { PatientDetailsComponent } from 'src/app/components/medical-records/patient-details/patient-details.component'

@Component({
  selector: "app-line-tabs",
  templateUrl: "./line-tabs.component.html",
  styleUrls: ["./line-tabs.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LineTabsComponent {
  @Output() toogleData: EventEmitter<boolean> = new EventEmitter();
  @Input() isToggled: boolean;

  switchTabs() {
    this.isToggled = !this.isToggled;
    if (this.toogleData) this.toogleData.emit(this.isToggled);
  }
}
