import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpWrapper } from "./http-wrapper";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private SettingsUrl: string = "/settings";

  constructor(private http: HttpWrapper) {}

  get() {
    return this.http.get(this.SettingsUrl);
  }

  update(settings) {
    return this.http.put(this.SettingsUrl, settings);
  }
}
