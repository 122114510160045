<div class="doctor-info-cards">
  <mat-card class="mat-elevation-z0 mat-card-mob" *ngIf="doctor">
    <mat-card-header class="mobile-header">
      <img src="{{ doctor.photo }}" mat-card-avatar class="card-avatar" />
      <div class="profile-info">
        <div class="profile-info-left">
          <mat-card-title
            >{{ "connect.dashboard.hello" | translate }},
            <span *ngIf="!(doctor.title === 'none')">{{
              doctor.title | titlecase
            }}</span>
            {{ doctor.last_name | titlecase }}</mat-card-title
          >
          <mat-card-subtitle class="doctor-stats"
            >{{ "connect.dashboard.full_profile_at" | translate }}
            {{ doctor.profile_completeness }}%
            <a class="doctor-url" [routerLink]="['profile/edit']">{{
              "connect.dashboard.complete_my_profile" | translate
            }}</a>
          </mat-card-subtitle>
          <mat-card-subtitle class="mt-20px">
            <mat-chip-list>
              <mat-chip class="mb-2 mb-md-0">
                <a href="tel:{{ customerSupportNumber }}">
                  <i class="doctor-phone-icon fas fa-phone-alt"></i>
                  <span class="info-text">{{ customerSupportNumber }}</span>
                </a>
              </mat-chip>
              <mat-chip>
                <a href="mailto:{{ customerSupportEmail }}">
                  <i class="doctor-email-icon fa fa-envelope"></i>
                  <span class="info-text">{{ customerSupportEmail }}</span>
                </a>
              </mat-chip>
              <mat-chip 
                tooltip="{{ 'connect.globals.click_to_copy' | translate }}"
                placement="top"
                [delay]="50"
                container="body"
                containerClass="tooltip"
                triggers="mouseenter:mouseleave"
                #copy class="copyLink" (click)="copyToClipboard(copy)">
                  <button mat-icon-button [class.zoomed]="isCopied" [class.shake]="isCopied">
                    <mat-icon>{{ isCopied ? 'check' : 'content_copy' }}</mat-icon>
                  </button>
                  <input readonly type="text" value="{{ doctor.short_link }}">
              </mat-chip>
            </mat-chip-list>
          </mat-card-subtitle>
        </div>
        <div *ngIf="isAPayingSubscriber">
          <div
            class="doctor-stats-card card-subcribe-content"
            *ngIf="!isASubscrible"
          >
            <div class="mat-card-content">
              <span class="stats-info">
                <p>
                  {{
                    "connect.dashboard." +
                      (doctor.payment_state === paymentState.PREMIUM
                        ? "continue_advantage"
                        : "full_advantage") | translate
                  }}
                </p>
              </span>
              <span class="stats-subtitle">
                <p class="text-center">
                  <a href="/payment" class="btn subscribe-btn">{{
                    "connect.dashboard.subscribe_plan" | translate
                  }}</a>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-card-header>
  </mat-card>
</div>
