<h1 mat-dialog-title *ngIf="data.actionType == 'create'">{{ 'connect.booking_availability.create_appointment_category' | translate }}</h1>
<h1 mat-dialog-title *ngIf="data.actionType == 'edit'">{{ 'connect.booking_availability.edit_appointment_category' | translate }}</h1>
<form [formGroup]="formData" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="standard" class="w-100">
                    <mat-label>{{ 'connect.booking_availability.appointment_label' | translate }}</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="col-9">
                <mat-form-field appearance="standard" class="w-100">
                    <mat-label>{{ 'connect.booking_availability.appointment_length' | translate }}</mat-label>
                    <input type="number"
                           placeholder="Minutes"
                           aria-label="Duration"
                           matInput
                           formControlName="duration"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option value="15">15 min</mat-option>
                        <mat-option value="30">30 min</mat-option>
                        <mat-option value="60">1 hour</mat-option>
                        <mat-option value="120">2 hours</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-9">
                <mat-form-field appearance="standard" class="w-100">
                    <mat-label>{{ 'connect.booking_availability.department' | translate }}</mat-label>
                    <mat-select formControlName="department_id">
                        <!-- Option for no selection -->
                        <mat-option [value]="null">{{ '' }}</mat-option>
                        
                        <!-- Options for departments -->
                        <mat-option *ngFor="let department of departments" [value]="department._id">
                            {{ department.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            

            <div class="col-3">
                <mat-form-field appearance="null" class="w-100">
                    <mat-label>{{ "connect.booking_availability.select_color" | translate }}</mat-label>
                    <input type="color" matInput formControlName="color" style="height: 35px;" required>
                </mat-form-field>
            </div>

            <div class="col-12">
                <mat-form-field appearance="standard" class="w-100">
                    <mat-label>{{ 'connect.booking_availability.price' | translate }}</mat-label>
                    <input matInput type="number" formControlName="price">
                </mat-form-field>
            </div>

            <div class="col-12 pb-3">
                <mat-checkbox formControlName="public" color="primary">{{ 'connect.booking_availability.public' | translate }}</mat-checkbox>
            </div>
        </div>

    </div>
    <div mat-dialog-actions class="justify-content-end">
        <button mat-button mat-dialog-close type="button">{{ 'connect.booking_availability.cancel' | translate}}</button>
        <button mat-flat-button color="primary" type="submit">{{ "connect.booking_availability.save_booking_type" | translate }}</button>
    </div>
</form>
