<div
  class="header-search-field"
  [ngClass]="{ 'toggle-search-on': this.addToggleSearchClass }"
>
  <div class="kt-searchbar">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <span class="kt-svg-icon" (click)="searchMedicalRecord()">
            <i class="fa fa-search"></i>
          </span>
        </span>
      </div>
      <input
        type="text"
        (keydown.enter)="searchMedicalRecord()"
        class="form-control"
        [(ngModel)]="templateEditable.name"
        [placeholder]="'connect.header.search_a_patient_record' | translate"
      />
      <button
        matInput
        class="ml-auto mat-raised-button search-button mat-primary"
        (click)="searchMedicalRecord()"
      >
        {{ "connect.header.search" | translate }}
      </button>
    </div>
  </div>
</div>
