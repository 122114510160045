import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { TranslateService } from "@ngx-translate/core";
import { InvoicesService } from "src/app/services/invoices.service";
import { GlobalService } from "src/app/services/global.service";
import { WordsHelper } from "src/app/helpers/words_helper";

@Component({
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  invoiceID: string = this.route.snapshot.params.id.toString();
  invoiceDetail: string;
  currentDoctor: Doctor;
  disabled: boolean = true;
  numInWords: string;
  constructor(
    private route: ActivatedRoute,
    private invoicesService: InvoicesService,
    private snackBar: SnackBarService,
    private wordsHelper: WordsHelper,
    private doctorService: DoctorService,
    private translate: TranslateService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.hideHeader();
    this.getInvoiceDetails();
  }

  getInvoiceDetails() {
    return this.invoicesService.getInvoiceDetails(this.invoiceID).subscribe(
      (invoiceData: any) => {
        this.invoiceDetail = invoiceData;
        this.numInWords = this.wordsHelper.getAmountInWords(
          this.doctorService.current,
          invoiceData.total_amount
        );
      },
      (error) =>
        this.snackBar.open(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  ngOnDestroy() {
    this.globalService.showHeader();
  }
}
