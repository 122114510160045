<form [formGroup]="templateForm" (submit)="loadTemplate()" *ngIf="template">
  <div class="row mb-4">
    <div class="col-md-8 col-12">
      <div class="row">
        <div class="col-md-4 col-sm-5 col-12 text-md-center">
          <mat-form-field class="width-100">
            <mat-select
              formControlName="template_name"
              [placeholder]="'connect.editor.select_template' | translate"
            >
              <mat-option
                *ngFor="let template of templates"
                value="{{ template.content }}"
              >
                {{ template.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-4 col-6 text-md-center">
          <button
            type="submit"
            class="medical-record-button btn-block"
            mat-button
          >
            <span>{{ "connect.editor.load_template" | translate }}</span>
          </button>
        </div>
        <div class="col-md-3 col-sm-4 col-6">
          <a
            href="/templates"
            class="btn btn-outline-neutral btn-block"
          >
            <span>{{ "connect.editor.edit_template" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</form>

<ckeditor
  [editor]="Editor"
  [config]="EditorConfig"
  [(ngModel)]="content"
  (change)="onChange($event)"
  [disabled]="disabled"
></ckeditor>

<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12" *ngIf="showSpeechContent">
        <div class="rounded-box">
          <div class="row justify-content-center no-gutters p-0">
            <div class="center col-sm-2 col-8 mt-1 pointer">
              <select
                class="form-control languages_selection"
                (change)="updateLanguage($event)"
              >
                <option
                  [value]="i"
                  *ngFor="let language of languages; let i = index"
                >
                  {{ language[0] }}
                </option>
              </select>
            </div>
            <div class="col-sm-2 col-2 mt-1 pointer">
              <img
                src="../../assets/images/mic.gif"
                *ngIf="showMic"
                (click)="startSpeech()"
              />
              <img
                src="../../assets/images/mic-animate.gif"
                *ngIf="showMicAnimate"
                (click)="endSpeech()"
              />
            </div>
          </div>
          <div class="row justify-content-center" id="not_supporting">
            <div class="col-sm-10">
              <div class="mt-4 ml-1 not-supported-error">
                <i
                  class="fa fa-exclamation-triangle mr-5"
                  aria-hidden="true"
                ></i>
                <h2>
                  {{ "connect.editor.works_on_google_chrome" | translate }}
                </h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-12">
              <p id="interim_span" class="interim" #info></p>
            </div>
          </div>
        </div>
      </div>
      <div class="microphone-btn" *ngIf="isDictaphone">
        <button
          (click)="wantToSpeak()"
          color="primary"
          class="btn btn-outline-neutral ml-auto mt-3"
        >
          <i class="fa fa-microphone mr-2"></i>
          {{ "connect.editor.dictaphone" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
