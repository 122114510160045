<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label class="pt-2">{{
        "connect.templates.template_name" | translate
      }}</label>
      <input
        type="text"
        [(ngModel)]="templateEditable.name"
        name="templateName"
        class="form-control col-md-9"
        aria-describedby="emailHelp"
        placeholder="{{ 'connect.templates.enter_template_name' | translate }}"
      />
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 doctor-template-dailog">
    <app-editor (editorContentData)="getEditorContent($event)"></app-editor>
  </div>
</div>
<div class="row">
  <div class="col-md-3 col-12">
    <button class="btn save-btn mt-4" (click)="createTemplate()">
      <span>{{ "connect.templates.save" | translate }}</span>
    </button>
  </div>
</div>
