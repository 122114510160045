import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import Swal from "sweetalert2";
import { EditedPatient } from "src/app/models/EditedPateint.model";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";

import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { BookingDialogComponent } from "../booking-dialog/booking-dialog.component";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  NgForm,
  FormControl,
} from "@angular/forms";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { TranslateService } from "@ngx-translate/core";
import { PatientDetailsComponent } from "src/app/components/medical-records/patient-details/patient-details.component";
import { PatientLabel } from "src/app/models/patient-label";
import * as moment from "moment";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "src/app/helpers/datepicker-helper";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable, map, startWith } from "rxjs";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { PhoneValidationHelper } from "src/app/helpers/phone_validation_helper";

@Component({
  selector: "app-patient-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class PatientProfileComponent implements OnInit {
  @Input() patient;
  @Input() doctor: Doctor;
  profileForm: UntypedFormGroup;
  regexNameValidation: any = '[ a-zA-Z\u0600-\u06FF\u00C0-\u0178\\._-]*'
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Morocco,
    CountryISO.Algeria,
    CountryISO.Tunisia,
  ];
  startDate = new Date(1990, 0, 1);
  country: string = "MA";
  editedPatient = <EditedPatient>{};
  MedicalRecordID: string = this.route.snapshot.params.id.toString();
  isModalVisible: boolean = false;
  phoneIsNotValid: boolean = false;
  emailIsNotValid: boolean = false;
  dateOfBirthIsNotValid: boolean = false;
  nameIsNotValid: boolean = false;
  editable: boolean = false;
  checkValidation: boolean = false;
  isToggled: boolean = false;
  medicalPatientLabels: PatientLabel[] = [];
  showToggled: boolean = false;
  showButtonToggled: boolean = false;

  doctorPatientLabels: PatientLabel[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  labelCtrl = new FormControl('');
  filteredLabels: PatientLabel[];
  patientLabels: PatientLabel[] = [];

  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;

  patientInsurances = [
    { value: "Allianz Assurance Maroc", viewValue: "Allianz Assurance Maroc" },
    { value: "Atlanta", viewValue: "Atlanta" },
    { value: "Axa Assurance Maroc", viewValue: "Axa Assurance Maroc" },
    { value: "Banque Populaire", viewValue: "Banque Populaire" },
    { value: "CMIM", viewValue: "CMIM" },
    { value: "CNOPS", viewValue: "CNOPS" },
    { value: "CNSS", viewValue: "CNSS" },
    { value: "MAMDA", viewValue: "MAMDA" },
    { value: "Marocaine Vie", viewValue: "Marocaine Vie" },
    { value: "MCMA", viewValue: "MCMA" },
    { value: "MGPAP", viewValue: "MGPAP" },
    { value: "RMA Assurance", viewValue: "RMA Assurance" },
    { value: "Saham Assurance", viewValue: "Saham Assurance" },
    { value: "Sanad", viewValue: "Sanad" },
    { value: "Wafa Assurance", viewValue: "Wafa Assurance" },
  ];

  patientBloodTypes = [
    { value: "A+", viewValue: "A+" },
    { value: "A-", viewValue: "A-" },
    { value: "B+", viewValue: "B+" },
    { value: "B-", viewValue: "B-" },
    { value: "AB+", viewValue: "AB+" },
    { value: "AB-", viewValue: "AB-" },
    { value: "O+", viewValue: "O+" },
    { value: "O-", viewValue: "O-" },
  ];

  constructor(
    private parent: PatientDetailsComponent,
    private medicalRecordService: MedicalRecordService,
    private route: ActivatedRoute,
    private router: Router,
    private doctorService: DoctorService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private errorHandlingService: ErrorsHandler,
    private phoneValidationHelper:PhoneValidationHelper,
    private snackBar: SnackBarService,
    public dialog: MatDialog
  ) {

  }

  onLabelCtrlChanges(event): void{
    let filterValue = '';
    if(event){
      filterValue = event.target.value.toLowerCase()
    }
    this.filteredLabels =  this.doctorPatientLabels.filter(label => label.name.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.doctor = currentDoctor;
        this.doctorPatientLabels = this.doctor.patient_labels;
        this.filteredLabels = this.doctorPatientLabels;
        this.getMedicalPatientLabels();
      }
    });
  }

  getMedicalPatientLabels() {
    if (this.patient.patient_labels) {
      this.medicalPatientLabels = this.doctorPatientLabels.filter(({ id }) =>
        this.patient.patient_labels.includes(id)
      );
    }
  }

  addClasses() {
    this.showToggled = !this.showToggled;
    this.showButtonToggled = !this.showButtonToggled;
  }

  updatePatientLabels() {
    this.patient.patient_labels = this.patientLabels.map((item) => item.id);
    this.medicalRecordService
      .update(this.MedicalRecordID, { ...this.patient })
      .subscribe(
        (data: any) => {
          this.medicalPatientLabels = this.doctorPatientLabels.filter(
            ({ id }) => data.patient_labels.includes(id)
          );
        },
        (error) =>
          this.errorHandlingService.handleError(
            this.translate.instant("connect.user_profiles.errors.error")
          )
      );
  }

  removeLabel(id): void {
    const index = this.patientLabels.findIndex(x => x.id == id);
    if (index >= 0) {
      this.patientLabels.splice(index, 1);
    }
    this.updatePatientLabels()
  }

  labelOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const index = this.doctorPatientLabels.findIndex(x => x.id == event.option.value);
    this.patientLabels.push(this.doctorPatientLabels[index]);
    this.labelInput.nativeElement.value = '';
    this.labelCtrl.setValue(null);
    this.onLabelCtrlChanges(null);
    
    this.updatePatientLabels();
  }

  showLabels() {
    this.isToggled = !this.isToggled;
  }

  initProfileForm() {
    if (this.patient.country) {
      this.country = this.patient.country;
    }
    this.editable = true;
    this.profileForm = this.formBuilder.group({
      id: [this.patient.id],
      first_name: [
        this.patient.first_name,
        [Validators.required, Validators.pattern(this.regexNameValidation)],
      ],
      last_name: [
        this.patient.last_name,
        [Validators.required, Validators.pattern(this.regexNameValidation)],
      ],
      phone_number: [this.patient.phone_number],
      email: [this.patient.email, [Validators.email]],
      date_of_birth: [this.patient.date_of_birth, Validators.required],
      insurance: [this.patient.insurance],
      referred_by: [this.patient.referred_by],
      blood_type: [this.patient.blood_type],
      address: [this.patient.address],
      city: [this.patient.city],
      country: [this.patient.country],
      cin: [this.patient.cin],
    });

    const phoneControl = this.profileForm.get('phone_number');
    phoneControl?.valueChanges.subscribe((phoneElement) => {
      const isValid = this.phoneValidationHelper.validatePhoneNumber(phoneControl, phoneElement?.CountryCode);
      this.phoneValidationHelper.setPhoneNumberErrors(phoneControl, isValid);
    });
  }

  resetForm() {
    this.editable = false;
  }

  navigatePatientLabel() {
    this.router.navigate(["/patient-labels"]);
  }

  openDialog(): void {
    if (this.patient.phone_number) {
      var _this = this;
      const dialogRef = this.dialog.open(BookingDialogComponent, {
        width: "450px",
        data: {
          bookingSlot: {
            starts_at: moment().add(1, "day").toISOString(),
            user_first_name: this.patient.first_name,
            user_last_name: this.patient.last_name,
            user_phone_number: this.patient.phone_number,
            user_date_of_birth: this.patient.date_of_birth,
            country: this.patient.country,
            medical_record_id: this.patient.id,
            cin: this.patient.cin,
            patient_profile: true,
          },
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          _this.parent.getTimelineEvents(
            (this.parent.listParams.pageNumber = 0)
          );
        }
      });
    } else {
      Swal.fire({
        title: this.translate.instant(
          "connect.user_profiles.complete_phone_number"
        ),
      });
    }
  }

  updatePatientInfo() {
    this.patient.first_name = this.profileForm.value.first_name;
    this.patient.last_name = this.profileForm.value.last_name;
    if (
      this.profileForm.value.phone_number &&
      this.profileForm.value.phone_number.internationalNumber
    ) {
      this.patient.phone_number =
        this.profileForm.value.phone_number.internationalNumber.replace(
          /-|\s/g,
          ""
        );
      this.patient.country = this.profileForm.value.phone_number.countryCode;
    }
    this.patient.email = this.profileForm.value.email;
    this.patient.date_of_birth = this.profileForm.value.date_of_birth;
    this.patient.insurance = this.profileForm.value.insurance;
    this.patient.referred_by = this.profileForm.value.referred_by;
    this.patient.blood_type = this.profileForm.value.blood_type;
    this.patient.address = this.profileForm.value.address;
    this.patient.city = this.profileForm.value.city;
    this.patient.cin = this.profileForm.value.cin;

    this.medicalRecordService
      .update(this.MedicalRecordID, { ...this.patient })
      .subscribe(
        (data) =>
          this.snackBar.open(
            this.translate.instant("connect.user_profiles.success.update")
          ),
        (error) =>
          this.errorHandlingService.handleError(
            this.translate.instant("connect.user_profiles.errors.error")
          )
      );
    this.editable = false;
  }

  deleteMedicalRecordNotes() {
    Swal.fire({
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      icon: this.translate.instant("connect.globals.confirmation_box_type"),
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.confirmation_box_delete_it"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant("connect.user_profiles.success.delete")
        );
        return this.medicalRecordService.delete(this.MedicalRecordID).subscribe(
          (deleteMedicalRecord) => {
            this.snackBar.open(
              this.translate.instant("connect.user_profiles.success.delete")
            );
            return this.router.navigate(["/medical-records"]);
          },
          (error) =>
            this.errorHandlingService.handleError(
              this.translate.instant("connect.user_profiles.errors.error")
            )
        );
      }
    });
  }

  navigateLabels() {
    return this.router.navigate(["/patient-labels"]);
  }
}
