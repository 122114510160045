<div class="container">
  <h2 class="inner-heading">
    {{
      "connect.booking_slot_availabilities.configure_my_calendar" | translate
    }}
  </h2>
  <div class="row">
    <div class="col-6">
      <div class="kt-portlet setting-data kt-portlet--height-fluid">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title card-title">
              {{
                "connect.booking_slot_availabilities.create_your_schedule"
                  | translate
              }}
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <ul class="info-list">
            <li>
              {{
                "connect.booking_slot_availabilities.select_weekly_consultation_hours"
                  | translate
              }}
            </li>
            <li>
              {{
                "connect.booking_slot_availabilities.select_time_slots"
                  | translate
              }}
            </li>
            <li>
              {{
                "connect.booking_slot_availabilities.choose_the_average_duration"
                  | translate
              }}
            </li>
            <li>
              {{
                "connect.booking_slot_availabilities.reset_your_schedule"
                  | translate
              }}
            </li>
          </ul>
          <button
            mat-raised-button
            color="primary"
            (click)="newPlanner()"
            class="mr-auto"
          >
            {{
              "connect.booking_slot_availabilities.create_your_schedule"
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="kt-portlet setting-data kt-portlet--height-fluid">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title card-title">
              {{
                "connect.booking_slot_availabilities.change_my_availability"
                  | translate
              }}
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <ul class="info-list">
            <li>
              {{
                "connect.booking_slot_availabilities.select_weekly_consultation_hours"
                  | translate
              }}
            </li>
            <li>
              {{
                "connect.booking_slot_availabilities.identify_the_slots"
                  | translate
              }}
            </li>
            <li>
              {{
                "connect.booking_slot_availabilities.manage_your_appointments_section"
                  | translate
              }}
            </li>
          </ul>
          <button
            mat-raised-button
            color="primary"
            (click)="editAvailability()"
            class="mr-auto"
          >
            {{
              "connect.booking_slot_availabilities.change_my_availability"
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
