<div
  mat-dialog-content
  class="booking-modal attachment-sharing-modal"
  [formGroup]="attachementForm"
  *ngIf="attachementForm"
>
  <div class="booking-modal-header modal-header">
    <div>
      <h4>{{ "connect.sharing_dialog.share_attachments" | translate }}</h4>
    </div>
    <div>
      <button type="button" class="close" (click)="onNoClick(false)">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="data">
    <div class="row">
      <div class="col-md-3 col-sm-4">
        {{ "connect.sharing_dialog.patient" | translate }}
      </div>
      <div class="col-md-9 col-sm-8">
        {{ patient.first_name }} {{ patient.last_name }} ({{
          patient.date_of_birth
        }})
        <br />
        {{ patient.phone_number }}
        <br />
        {{ patient.email }}
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-3 col-sm-4">
        {{ "connect.sharing_dialog.reciepient" | translate }}
      </div>
      <div class="col-md-9 col-sm-8">
        <div class="sharing-dialog-link">
          <ul>
            <li>
              <a
                (click)="changeTabs()"
                [ngClass]="{ active: isActive }"
                class="cursor-pointer"
                >{{ "connect.sharing_dialog.dabadoc_doctor" | translate }}</a
              >
            </li>
            <li>
              <a
                (click)="changeTabs()"
                [ngClass]="{ active: !isActive }"
                class="cursor-pointer"
                >{{ "connect.sharing_dialog.other" | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div *ngIf="isDabadocUser">
          <mat-select
            #singleSelect
            (selectionChange)="handleSelectChange($event.value)"
            [placeholder]="'connect.sharing_dialog.search_doctor' | translate"
            formControlName="doctor_name"
            class="sharing-dailog-select"
            panelClass="sharing-mat-select"
            backdropClass="full-width"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="doctorFilterCtrl"
                placeholderLabel="{{
                  'connect.sharing_dialog.search_doctor' | translate
                }}"
                noEntriesFoundLabel="{{
                  'connect.sharing_dialog.no_matching_found' | translate
                }}"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let doctor of filteredDoctors | async"
              value="{{ doctor.id }}/{{ doctor.name }}/ {{
                doctor.speciality
              }}/{{ doctor.city }}"
            >
              <div class="search_list">
                <img class="result-avatar" src="{{ doctor.picture_url }}" />
                <div class="result-details">
                  <div class="result-name">{{ doctor.name }}</div>
                  <div class="result-speciality">{{ doctor.speciality }}</div>
                  <div class="result-city">{{ doctor.city }}</div>
                </div>
              </div>
            </mat-option>
          </mat-select>
          <div *ngIf="selected_values" class="mt-3">
            {{ selected_values[1] | titlecase }} {{ selected_values[2] }}
            {{ selected_values[3] }}
            <br />
            {{ selected_values[4] }}
            <br />
            {{ selected_values[5] }}
            <br />
          </div>
          <div *ngIf="isDoctorSelected" class="mt-3 errors">
            {{ "connect.sharing_dialog.please_select_doctor" | translate }}
          </div>
        </div>
        <div *ngIf="!isDabadocUser">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.sharing_dialog.first_name' | translate
                    }}"
                    formControlName="recipient_first_name"
                    class="text-dark text-font-mid"
                  />
                  <mat-hint
                    *ngIf="attachementForm.get('recipient_first_name').errors"
                  >
                    <mat-error>{{
                      "connect.sharing_dialog.errors.enter_first_name"
                        | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.sharing_dialog.last_name' | translate
                    }}"
                    formControlName="recipient_last_name"
                    class="text-dark text-font-mid"
                  />
                  <mat-hint
                    *ngIf="attachementForm.get('recipient_last_name').errors"
                  >
                    <mat-error>{{
                      "connect.sharing_dialog.errors.enter_last_name"
                        | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.sharing_dialog.email' | translate
                    }}"
                    formControlName="recipient_email"
                    class="text-dark text-font-mid"
                    name="recipient_email"
                  />
                  <mat-hint
                    *ngIf="attachementForm.get('recipient_email').errors"
                  >
                    <mat-error>{{
                      "connect.sharing_dialog.errors.enter_email" | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.sharing_dialog.confirm_email' | translate
                    }}"
                    formControlName="recipient_confirm_email"
                    class="text-dark text-font-mid"
                    name="recipient_confirm_email"
                  />
                  <mat-hint *ngIf="isInvalidEmail">
                    <mat-error>{{
                      "connect.sharing_dialog.errors.confirm_email_invalid"
                        | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>{{
                "connect.sharing_dialog.phone_number" | translate
              }}</label>
              <div
                class="form-group mb-1 phone-selection-modal phone-selection phone-number-container country-phone"
              >
                <app-phone-number
                  controlName="recipient_phone_number"
                ></app-phone-number>
              </div>
            </div>
          </div>
          <div class="row" class="errors" *ngIf="isEmailMatched">
            <div class="col-md-12">
              <p>{{ "connect.sharing_dialog.email_associated" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-3 col-sm-4">
        {{ "connect.sharing_dialog.document" | translate }}
      </div>
      <div class="col-md-9 col-sm-8">
        <div class="upload_files note-images">
          <span *ngFor="let event of events" class="note-image-box">
            <app-attachment-files [event]="event"></app-attachment-files>
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="form-group mt-4 mb-0 footer text-right" mat-dialog-actions>
        <button
          class="btn btn-sm mr-3 btn-outline-cancel"
          (click)="onNoClick(false)"
        >
          {{ "connect.sharing_dialog.cancel" | translate }}
        </button>
        <button
          class="btn add-btn mr-3"
          (click)="shareAttachment()"
          [disabled]="attachementForm.invalid"
        >
          {{ "connect.sharing_dialog.add" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
