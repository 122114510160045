<div>
  <div class="modal-header">
    <div>
      <h4>{{ "connect.medical_record.patient_details" | translate }}</h4>
    </div>
    <div>
      <button type="button" class="close" (click)="close()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <table class="table">
      <tr *ngIf="data.gender">
        <th>{{ "connect.medical_record.gender" | translate }}</th>
        <td>{{ "connect.medical_record." + data.gender | translate }}</td>
      </tr>
      <tr *ngIf="data.date_of_birth">
        <th>{{ "connect.medical_record.date_of_birth" | translate }}</th>
        <td>
          {{ ageFrom(data.date_of_birth) }}
          {{ "connect.medical_record.years" | translate }} ({{
            data.date_of_birth | ToDateFormat
          }})
        </td>
      </tr>
      <tr *ngIf="data.email">
        <th>{{ "connect.medical_record.email" | translate }}</th>
        <td>{{ data.email }}</td>
      </tr>
      <tr *ngIf="data.phone_number">
        <th>{{ "connect.medical_record.phone_number" | translate }}</th>
        <td>{{ data.phone_number }}</td>
      </tr>
    </table>
  </div>
</div>
