import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DoctorStatsService } from "src/app/services/doctor-stats.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  currentDoctor: Doctor;
  currentDoctorStats = {};

  constructor(
    private doctorStats: DoctorStatsService,
    private snackBar: SnackBarService,
    private doctorService: DoctorService
  ) {}

  getDoctorStats() {
    this.doctorStats.get().subscribe(
      (stats) => (this.currentDoctorStats = stats),
      (error) => this.snackBar.open("Something wrong, please try again!")
    );
  }

  getDoctorInfo() {
    this.doctorService.get().subscribe((currentDoctor) => {
      localStorage.setItem("email",currentDoctor.email)
      localStorage.setItem("name",currentDoctor.first_name+" "+currentDoctor.last_name)
      this.currentDoctor = currentDoctor;
    });
  }

  ngOnInit() {
    this.getDoctorInfo();
    this.getDoctorStats();
  }
}
