<div class="files-name">
  <span class="file-span">
    <img class="doc-image" src="{{ previewImg(event) }}" alt="image" />
    <a
      class="files-name"
      href="{{ event.url }}"
      download
      target="_blank"
      title="{{ event.name }}"
      >{{ event.name }}</a
    >
  </span>
</div>
