import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import * as CustomEditor from "../../../assets/plugins/ckeditor5/ckeditor";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TemplatesService } from "src/app/services/templates.service";
import { Template } from "src/app/models/doctorTemplate.model";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Patient } from "src/app/models/medicalRecordPatient.model";
import { DatePipe } from "@angular/common";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
const { webkitSpeechRecognition } = window as any;

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  providers: [DatePipe],
  styleUrls: ["./editor.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit {
  @Input() content: string;
  @Input() patientDetails: Patient;
  @Input() template: boolean;
  @Input() disabled: boolean;

  editorContent: string = "";
  Editor = CustomEditor;
  EditorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "removeFormat",
        "bulletedList",
        "numberedList",
        "todoList",
        "|",
        "alignment",
        "indent",
        "outdent",
        "|",
        "blockQuote",
        "insertTable",
        "link",
        "mediaEmbed",
        "|",
        "undo",
        "redo",
        "|",
        "exportPdf",
      ],
    },
    language: "fr",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
    exportPdf: {
      fileName: "dabadoc-document.pdf",
      converterOptions: {
        format: "A4",
        margin_top: "20mm",
        margin_bottom: "20mm",
        margin_right: "12mm",
        margin_left: "12mm",
      },
    },
  };
  currentDoctor: Doctor;

  @Output() editorContentData: EventEmitter<string> = new EventEmitter();

  templateForm = new UntypedFormGroup({
    template_name: new UntypedFormControl(""),
  });
  templates: Template[] = [];
  recognizing: boolean = false;
  two_line: any = /\n\n/g;
  one_line: any = /\n/g;
  showMic: boolean = true;
  showMicAnimate: boolean = false;
  recognition: any;
  showSpeechContent: boolean = false;
  speakBtn: boolean = true;
  languages = [
    ["Français", ["fr-FR"]],
    ["English", ["en-US"]],
    ["Arabic", ["ar-AR"]],
  ];
  notSupported: boolean = false;
  isDictaphone: boolean = false;

  @ViewChild("info", { static: true }) info: ElementRef;

  constructor(
    private snackBar: SnackBarService,
    private tref: ElementRef,
    private templatesService: TemplatesService,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private errorHandler: ErrorsHandler,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      this.currentDoctor = currentDoctor;
    });
    if (this.patientDetails) this.getTemplates();
    if (this.template) {
      this.isDictaphone = true;
      var _this = this;
      if ("webkitSpeechRecognition" in window) {
        this.recognition = new webkitSpeechRecognition();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;
        this.recognition.lang = "fr-FR";

        this.recognition.onstart = function () {
          _this.recognizing = true;
          _this.showMic = false;
          _this.showMicAnimate = true;
        };

        this.recognition.onerror = function (event) {
          document.getElementById("not_supporting").style.display = "block";
          _this.showMic = true;
          _this.showMicAnimate = false;
          _this.recognizing = false;
          this.recognition.stop();
        };

        this.recognition.onend = function () {
          _this.recognizing = false;
          _this.content += " ";
        };

        this.recognition.onresult = function (event) {
          var interim_transcript = "";
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              _this.content += event.results[i][0].transcript;
              document.getElementById("interim_span").innerHTML = "";
              this.recognition.stop();
            } else {
              interim_transcript += event.results[i][0].transcript;
            }
          }
          if (_this.linebreak(interim_transcript)) {
            document.getElementById("interim_span").innerHTML =
              _this.linebreak(interim_transcript);
          }
        };
      }
    }
  }

  wantToSpeak() {
    this.showSpeechContent = true;
    if (!this.speakBtn) this.showSpeechContent = false;
    this.speakBtn = !this.speakBtn;
  }

  updateLanguage(event) {
    if (this.recognition) {
      this.recognition.lang = this.languages[event.target.value][1][0];
    }
  }

  linebreak(speech) {
    if (speech) {
      return speech
        .replace(this.two_line, "<p></p>")
        .replace(this.one_line, "<br>");
    }
  }

  startSpeech() {
    if (this.recognition) {
      if (!this.recognizing) this.recognition.start();
      this.showMic = false;
      this.showMicAnimate = true;
    } else {
      document.getElementById("not_supporting").style.display = "block";
    }
  }

  endSpeech() {
    this.recognition.stop();
    this.showMic = true;
    this.showMicAnimate = false;
  }

  public onChange({ editor }: ChangeEvent) {
    if (editor) {
      const data = editor.getData();
      if (this.editorContentData) this.editorContentData.emit(this.content);
    }
  }

  getTemplates() {
    this.templatesService.all().subscribe(
      (data: Template[]) => {
        this.templates = data;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  loadTemplate() {
    const patientDetails = this.patientDetails;
    const currentDoctor = this.currentDoctor;
    const contextData = {
      ...patientDetails,
      doctor_name: `${currentDoctor.title} ${currentDoctor.first_name} ${currentDoctor.last_name}`,
      name: `${patientDetails.first_name} ${patientDetails.last_name}`,
      reference_id: patientDetails.ref_id,
      doctor_city: currentDoctor.city,
      date: this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
      birthday: this.datePipe.transform(patientDetails.date_of_birth, 'dd-MM-yyyy')
    };

    const replaceTemplatePlaceholders = (template: string) => {
      return template.replace(/&lt;(\w+)&gt;/g, (match, key) => {
        const value = contextData[key];
        return value === null || value === undefined ? '' : value;
      });
    };

    if (this.templateForm.value.template_name) {
      this.content = replaceTemplatePlaceholders(this.templateForm.value.template_name);
    }
  }
}
