<div class="row assistant-page">
  <div class="col-md-12">
    <div class="kt-portlet doctor-template">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ "connect.assistants.my_assistants" | translate }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar"></div>
      </div>
      <div class="kt-portlet__body">
        <div class="assistant-page-wrapper" *ngIf="!isAssistantForm">
          <span class="assistant-page-title">
            {{ "connect.assistants.assign_new_assistant" | translate }}
          </span>
          <div class="input-filed-group">
            <span class="assistant-page-email">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    class="form-control"
                    matInput
                    placeholder="{{ 'connect.assistants.email' | translate }}"
                    [(ngModel)]="email"
                    class="text-dark text-font-mid"
                  />
                </mat-form-field>
                <div class="error-msg" *ngIf="!isInvalidPattern">
                  <span class="error-msg-arrow"></span>
                  {{ "connect.assistants.email_not_valid" | translate }}
                </div>
                <div class="error-msg" *ngIf="isEmailError">
                  <span class="error-msg-arrow"></span>
                  {{
                    "connect.assistants.email_already_associated" | translate
                  }}
                </div>
              </div>
            </span>
            <button
              mat-raised-button
              color="primary"
              class="create-btn"
              (click)="getAssistant()"
            >
              {{ "connect.assistants.next" | translate }}
            </button>
          </div>
        </div>
        <div
          [formGroup]="assistantsForm"
          *ngIf="isAssistantForm"
          class="assistant-page-wrapper"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-md-12 text-center">
              <p class="assistant-page-title">
                {{ "connect.assistants.email" | translate }}: {{ email }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    class="form-control"
                    matInput
                    placeholder="{{
                      'connect.assistants.first_name' | translate
                    }}"
                    formControlName="first_name"
                    class="text-dark text-font-mid"
                  />
                  <mat-hint *ngIf="assistantsForm.get('first_name').errors">
                    <mat-error>{{
                      "connect.assistants.required_field" | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.assistants.last_name' | translate
                    }}"
                    formControlName="last_name"
                    class="text-dark text-font-mid"
                  />
                  <mat-hint *ngIf="assistantsForm.get('last_name').errors">
                    <mat-error>{{
                      "connect.assistants.required_field" | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!isEmailAvailable">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.assistants.password' | translate
                    }}"
                    formControlName="password"
                    class="text-dark text-font-mid"
                    name="recipient_email"
                  />
                  <mat-hint *ngIf="assistantsForm.get('password').errors">
                    <mat-error>{{
                      "connect.assistants.required_field" | translate
                    }}</mat-error>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!isEmailAvailable">
              <div class="form-group mb-1">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{
                      'connect.assistants.password_confirmation' | translate
                    }}"
                    formControlName="password_confirmation"
                    class="text-dark text-font-mid"
                    name="recipient_confirm_email"
                  />
                </mat-form-field>
                <div
                  class="mismatch-password"
                  *ngIf="
                    submitted &&
                    assistantsForm
                      .get('password_confirmation')
                      .hasError('mustMatch')
                  "
                >
                  {{ "connect.assistants.does_not_match" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="backBtn-container pointer">
            <span class="back-btn" (click)="backToEmail()">
              <i class="fa fa-arrow-left"> </i>
            </span>
            <button class="btn add-btn mr-3" (click)="createAssistant()">
              {{ "connect.assistants.add" | translate }}
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    {{ "connect.assistants.last_name" | translate }}
                  </th>
                  <th>
                    {{ "connect.assistants.email" | translate }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let assistant of assistants">
                  <td>
                    {{ assistant.last_name }}
                    {{ assistant.first_name }}
                  </td>
                  <td>
                    {{ assistant.email }}
                  </td>
                  <td>
                    <span
                      class="mr-10 pointer"
                      (click)="deleteAssistant(assistant.id)"
                      ><i class="fas fa-trash"></i
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
