<div
  class="kt-portlet kt-align-center kt-padding-10"
  style="margin-top: 15% !important"
>
  <h4 class="kt-padding-t-10">
    Cette fonctionnalité est disponible sur votre espace DabaDoc Pro
  </h4>
  <h4 class="kt-padding-t-10">
    <a href="{{ pageLink }}">Cliquez ici</a> pour y accéder
  </h4>
  <h4 class="kt-padding-t-10">
    Elle sera accessible prochainement sur votre espace DabaDoc Connect !
  </h4>
</div>
