import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CommentsService } from "src/app/services/comments.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Comments } from "src/app/models/comments";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { GlobalService } from "src/app/services/global.service";
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-doctor-comments",
  templateUrl: "./doctor-comments.component.html",
  styleUrls: ["./doctor-comments.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorCommentsComponent implements OnInit {
  doctorComments: Comments[] = [];
  nextDisable: boolean = false;
  previousDisable: boolean = false;
  listParams = {
    pageNumber: 1,
  };
  currentDoctor: Doctor;
  onDestroy$ = new Subject<void>();
  protected _onDestroy = new Subject<void>();
  currentDoctor$: Observable<Doctor>;

  constructor(
    private commentsService: CommentsService,
    private errorHandler: ErrorsHandler,
    private doctorService: DoctorService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private snackBar: SnackBarService
  ) {}

  getDoctorComments(params) {
    this.listParams.pageNumber == 1
      ? (this.previousDisable = true)
      : (this.previousDisable = false);
    this.globalService.showSpinner();
    const filter = "page=" + params;
    this.commentsService.get(filter).subscribe(
      (data: Comments[]) => {
        data.length < 10
          ? (this.nextDisable = true)
          : (this.nextDisable = false);
        this.globalService.hideSpinner();
        this.doctorComments = data;
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  getDoctorInfo() {
    this.currentDoctor$ = this.doctorService.currentDoctor$;
  }

  paginatorCommentRecords(key: string) {
    key == "previous"
      ? this.listParams.pageNumber--
      : this.listParams.pageNumber++;
    this.getDoctorComments(this.listParams.pageNumber);
  }

  ngOnInit() {
    this.getDoctorInfo();
    this.getDoctorComments((this.listParams.pageNumber = 1));
  }
}
