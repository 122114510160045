import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
@Component({
  selector: "kt-brand",
  templateUrl: "./brand.component.html",
  styleUrls: ["./brand.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BrandComponent implements OnInit {
  currentDoctor: Doctor;

  ngOnInit(): void {
    this.doctorInfo();
  }
  constructor(private doctorService: DoctorService) {}
  // headerLogo: string = '../../assets/images/dabadoc-logo.png'

  doctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
      }
    });
  }
}
