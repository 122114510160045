import { Component, ViewEncapsulation } from "@angular/core";
import { NavbarHelper } from "src/app/helpers/navbar_helper";

@Component({
  selector: "app-bottom-navbar",
  templateUrl: "./bottom-navbar.component.html",
  styleUrls: ["./bottom-navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BottomNavbarComponent {
  constructor(public navbarHelper: NavbarHelper) {}
}
