export enum StateType {
  NOT_CONFIRMED = "not_confirmed",
  CONFIRMED_USER = "confirmed_user",
  CONFIRMED_DOCTOR = "confirmed_doctor",
  CONFIRMED_ASSISTANT = "confirmed_assistant",
  CANCELED_USER = "canceled_user",
  CANCELED_DOCTOR = "canceled_doctor",
  CANCELED_AUTO = "canceled_auto",
  CANCELED_ASSISTANT = "canceled_assistant",
  AWAITING_PAYMENT = "awaiting_payment",
}
